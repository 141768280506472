import React from 'react';

const Blog = () => {
  return (
    <div className="blog">
      <div className="blog-content">
        <h1>Blog</h1>
        <p>Stay tuned for our latest updates and articles.</p>
        {/* Add your previous Blog page content here */}
      </div>
    </div>
  );
};

export default Blog; 