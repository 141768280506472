import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, isUserAuthenticated, getActiveUser } from '../firebase';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import './HeaderComponent.css';

const HeaderComponent = () => {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const isAuthenticated = isUserAuthenticated();
        const activeUser = getActiveUser();

        if (!isAuthenticated || !activeUser?.email) {
          console.log('No authenticated user with email - skipping user data fetch');
          setUserData(null);
          return;
        }

        const userDocRef = doc(db, 'users', activeUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        
        if (userDocSnap.exists()) {
          setUserData(userDocSnap.data());
        } else {
          // If no user document exists but we have a valid user, create basic userData
          setUserData({
            email: activeUser.email,
            displayName: activeUser.displayName || activeUser.email
          });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setUserData(null);
      }
    };

    fetchUserData();
  }, []);

  return (
    <div className="header-component">
      <div className="user-info">
        {userData ? (
          <h1 className="welcome-title">Welcome, {userData.fname || userData.email}!</h1>
        ) : (
          <h1 className="welcome-title">Please sign in to access all features</h1>
        )}
      </div>
    </div>
  );
};

export default HeaderComponent;
