// src/components/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <p>&copy; 2024 Vayu. All rights reserved.</p>
        </div>
        {/* Add more footer sections as needed */}
      </div>
    </footer>
  );
};

export default Footer;