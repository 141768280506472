import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Make sure to import your Firebase configuration

const ClientControls = () => {
  const [orgs, setOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [linkedControls, setLinkedControls] = useState([]);

  useEffect(() => {
    // Fetch organizations where type == 'client'
    const fetchOrgs = async () => {
      try {
        const q = query(collection(db, 'orgs'), where('type', '==', 'client'));
        const querySnapshot = await getDocs(q);
        const orgData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setOrgs(orgData);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchOrgs();
  }, []);

  const handleOrgSelect = async (e) => {
    const orgId = e.target.value;
    setSelectedOrg(orgId);
  
    if (orgId) {
      try {
        // Fetch linked controls for the selected organization
        const orgRef = doc(db, 'orgs', orgId);
        const orgDoc = await getDoc(orgRef);
        const orgData = orgDoc.data();
  
        // Assuming controls are stored in a 'controls' subcollection of the org document
        const controlsRef = collection(db, 'orgs', orgId, 'controls');
        const controlsSnapshot = await getDocs(controlsRef);
        const controlsData = controlsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setLinkedControls(controlsData);
      } catch (error) {
        console.error('Error fetching linked controls:', error);
      }
    } else {
      setLinkedControls([]);
    }
  };

  return (
    <div className="client-controls-container">
      <div className="form-group">
        <label>Select Client</label>
        <select value={selectedOrg} onChange={handleOrgSelect}>
          <option value="">-- Select Client --</option>
          {orgs.map((org) => (
            <option key={org.id} value={org.id}>
              {org.name}
            </option>
          ))}
        </select>
      </div>

      {linkedControls.length > 0 && (
        <div className="table-container">
          <table className="controls-table">
            <thead>
              <tr>
                <th>Control ID</th>
                <th>Linked At</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {linkedControls.map((control, index) => (
                <tr key={index}>
                  <td>{control.control_id}</td>
                  <td>{control.linked_at}</td>
                  <td>{control.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ClientControls;
