import React from 'react';
import { FaHome } from 'react-icons/fa';
import './ControlsHeader.css';

const ControlsHeader = ({ setView }) => {
  const handleHomeClick = () => {
    if (typeof setView === 'function') {
      setView('overview');
    } else {
      console.error('setView is not a function');
    }
  };

  return (
    <div className="controls-header">
      <div className="controls-title-container">
        <button className="home-button" onClick={handleHomeClick}>
          <FaHome />
        </button>
        <h1 className="controls-title">Controls</h1>
      </div>
      <div className="controls-actions">
        {/* <button className="more-button">More &#9662;</button> */} 
        {/* <button className="add-control-button">Add Control</button> */}
      </div>
    </div>
  );
};

export default ControlsHeader;
