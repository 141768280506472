console.log('Environment variables:', {
  DIFY_API_KEY_EXISTS: !!process.env.REACT_APP_DIFY_API_KEY,
  NODE_ENV: process.env.NODE_ENV
});

const DIFY_API_KEY = process.env.REACT_APP_DIFY_API_KEY;
if (!DIFY_API_KEY) {
  console.error('Dify API Key not found in environment variables');
}

const DIFY_API_URL = 'https://api.dify.ai/v1';

class DifyService {
  constructor() {
    if (!DIFY_API_KEY) {
      throw new Error('Dify API Key is required');
    }

    this.headers = {
      'Authorization': `Bearer ${DIFY_API_KEY}`,
      'Content-Type': 'application/json'
    };
    this.conversationId = null;
  }

  async processQueryWithUpdates(query, clientId, onStepComplete) {
    try {
      console.log('Making request to Dify API with:', {
        query,
        user: clientId,
        conversation_id: this.conversationId,
        response_mode: 'streaming',
        inputs: {
          now_viewing_org_id: clientId
        }
      });

      const response = await fetch(`${DIFY_API_URL}/chat-messages`, {
        method: 'POST',
        headers: this.headers,
        body: JSON.stringify({
          query,
          user: clientId,
          conversation_id: this.conversationId,
          response_mode: 'streaming',
          inputs: {
            now_viewing_org_id: clientId
          }
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Dify API Error Response:', {
          status: response.status,
          statusText: response.statusText,
          headers: Object.fromEntries(response.headers.entries()),
          body: errorText
        });
        throw new Error(`Failed to connect to Dify API: ${errorText}`);
      }

      const reader = response.body.getReader();
      let partialLine = '';
      let fullResponse = '';
      let currentTaskId = null;

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = new TextDecoder().decode(value);
        const lines = (partialLine + chunk).split('\n\n');
        partialLine = lines.pop() || '';

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const data = JSON.parse(line.slice(5));
              console.log('Received SSE data:', data);
              
              switch (data.event) {
                case 'agent_message':
                  fullResponse += data.answer || '';
                  currentTaskId = data.task_id;
                  if (data.conversation_id) {
                    this.conversationId = data.conversation_id;
                  }
                  onStepComplete({
                    type: 'llm_response',
                    title: 'AI Response',
                    description: data.answer,
                    metadata: {
                      task_id: data.task_id,
                      message_id: data.message_id,
                      conversation_id: data.conversation_id,
                      timestamp: new Date(data.created_at * 1000).toISOString(),
                      stage: 'generating'
                    }
                  });
                  break;

                case 'agent_thought':
                  onStepComplete({
                    type: 'agent_thought',
                    title: 'Agent Thought Process',
                    description: data.thought || '',
                    metadata: {
                      task_id: data.task_id,
                      message_id: data.message_id,
                      position: data.position,
                      observation: data.observation,
                      tool: data.tool,
                      tool_input: data.tool_input,
                      timestamp: new Date(data.created_at * 1000).toISOString(),
                      stage: 'thinking'
                    }
                  });
                  break;

                case 'message_end':
                  onStepComplete({
                    type: 'llm_response',
                    title: 'Final Response',
                    description: fullResponse,
                    metadata: {
                      task_id: data.task_id,
                      message_id: data.message_id,
                      conversation_id: data.conversation_id,
                      usage: data.metadata?.usage,
                      retriever_resources: data.metadata?.retriever_resources,
                      timestamp: new Date(data.created_at * 1000).toISOString(),
                      stage: 'completed'
                    }
                  });
                  break;

                case 'error':
                  throw new Error(`Dify Error: ${data.message} (${data.code})`);

                case 'ping':
                  console.log('Received ping event');
                  break;

                case 'message_replace':
                  fullResponse = data.answer;
                  onStepComplete({
                    type: 'llm_response',
                    title: 'Updated Response',
                    description: data.answer,
                    metadata: {
                      task_id: data.task_id,
                      message_id: data.message_id,
                      conversation_id: data.conversation_id,
                      timestamp: new Date(data.created_at * 1000).toISOString(),
                      stage: 'replaced'
                    }
                  });
                  break;
              }
            } catch (error) {
              console.error('Error parsing SSE data:', error, line);
              throw error;
            }
          }
        }
      }

      return [{
        type: 'llm_response',
        title: 'AI Response',
        description: fullResponse,
        metadata: {
          conversation_id: this.conversationId,
          timestamp: new Date().toISOString()
        }
      }];

    } catch (error) {
      console.error('Dify API Error:', {
        message: error.message,
        stack: error.stack
      });
      throw error;
    }
  }

  formatErrorMessage(errorData) {
    switch (errorData.code) {
      case 'app_unavailable':
        return 'The AI application is currently unavailable';
      case 'provider_not_initialize':
        return 'AI provider not properly initialized';
      case 'provider_quota_exceeded':
        return 'AI quota exceeded';
      case 'model_currently_not_support':
        return 'The requested AI model is not supported';
      case 'completion_request_error':
        return 'Error generating AI response';
      default:
        return errorData.message || 'Unknown error occurred';
    }
  }

  async getConversationHistory(clientId, conversationId = null) {
    try {
      const params = new URLSearchParams({
        user: clientId,
        ...(conversationId && { conversation_id: conversationId })
      });

      const response = await fetch(
        `${DIFY_API_URL}/messages?${params}`,
        {
          headers: this.headers
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(this.formatErrorMessage(errorData));
      }

      return await response.json();
    } catch (error) {
      console.error('Error fetching conversation history:', error);
      throw error;
    }
  }

  startNewConversation() {
    this.conversationId = null;
  }

  setConversation(conversationId) {
    this.conversationId = conversationId;
  }
}

export const difyService = new DifyService();