// firebase-config.js

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

// Original Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCwAsTYWsF--uwkRdx_UkjKWIJk28obGWM",
  authDomain: "vayu-208fb.firebaseapp.com",
  projectId: "vayu-208fb",
  storageBucket: "vayu-208fb.appspot.com",
  messagingSenderId: "123442842011",
  appId: "1:123442842011:web:e65240aae3ebf9a28f393e",
  measurementId: "G-137RFKESXP"
};

// SAML-specific Firebase configuration
const samlFirebaseConfig = {
  apiKey: "AIzaSyBMC2HlgCwX2Vin3QsdKoYps64yPomlpF4",
  authDomain: "eastern-rain-431114-t2.firebaseapp.com",
  projectId: "eastern-rain-431114-t2",
  storageBucket: "eastern-rain-431114-t2.appspot.com",
  messagingSenderId: "307699856776",
  appId: "1:307699856776:web:6345b9bf680641f1a1b63d",
  measurementId: "G-F1NFT1KFMJ"
};

// Initialize original Firebase app
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Initialize the SAML-specific Firebase app
const samlApp = initializeApp(samlFirebaseConfig, 'samlApp');
const samlAuth = getAuth(samlApp);
const analytics = getAnalytics(samlApp);

// Set persistence to LOCAL for both auth instances
Promise.all([
  setPersistence(auth, browserLocalPersistence),
  setPersistence(samlAuth, browserLocalPersistence)
])
.then(() => {
  console.log('Firebase auth persistence set to LOCAL for both auth instances');
})
.catch((error) => {
  console.error('Error setting auth persistence:', error);
});

const db = getFirestore(app);
const storage = getStorage(app);

// Helper function to check if user is authenticated in either auth instance
const isUserAuthenticated = () => {
  const regularUser = auth.currentUser;
  const samlUser = samlAuth.currentUser;
  
  // User is authenticated if they exist in either auth instance and are not anonymous
  return (regularUser && !regularUser.isAnonymous) || (samlUser && !samlUser.isAnonymous);
};

// Helper function to get the active user from either auth instance
const getActiveUser = () => {
  const regularUser = auth.currentUser;
  const samlUser = samlAuth.currentUser;
  
  // Prefer SAML user if available, otherwise use regular user
  return samlUser || regularUser;
};

// Export both Firebase app instances and helper functions
export { 
  auth, 
  samlAuth, 
  db, 
  storage, 
  firebaseConfig,
  isUserAuthenticated,
  getActiveUser
};
