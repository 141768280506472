import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './SuperSearch.css';
import { toolsService } from '../services/toolsService';
import { collection, query, where, orderBy, limit, getDocs, onSnapshot } from 'firebase/firestore';
import { FaHistory } from 'react-icons/fa';
import { auth, db, isUserAuthenticated, getActiveUser } from '../firebase';
import { useGlobalState } from '../context/GlobalStateContext';
import UpdatedResultsWindow from './UpdatedResultsWindow';
import IntegrationsView from './IntegrationsView';
import { difyService } from '../services/difyService';

const SuperSearch = () => {
  const location = useLocation();
  const { nowViewingOrgId } = useGlobalState();
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [connectedTools, setConnectedTools] = useState(new Set());
  const [isResultsVisible, setIsResultsVisible] = useState(false);
  const [clickedButton, setClickedButton] = useState('');
  const [searchResult, setSearchResult] = useState('');
  const [searchAction, setSearchAction] = useState('');
  const [processingStage, setProcessingStage] = useState('');
  const [stepResults, setStepResults] = useState([]);
  const [enabledToolsCount, setEnabledToolsCount] = useState(0);

  useEffect(() => {
    let isMounted = true;

    const loadIntegrations = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          return;
        }

        const tools = await toolsService.getAvailableTools();
        if (isMounted) {
          setConnectedTools(tools);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error loading integrations:', error);
        }
      }
    };

    loadIntegrations();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let unsubscribe = () => {};

    const setupToolsListener = async () => {
      if (!nowViewingOrgId) return;

      const toolsRef = collection(db, 'tools');
      const q = query(
        toolsRef,
        where('orgId', '==', nowViewingOrgId),
        where('integration_enabled', '==', true)
      );

      // Set up real-time listener
      unsubscribe = onSnapshot(q, (snapshot) => {
        setEnabledToolsCount(snapshot.size);
      }, (error) => {
        console.error('Error listening to tools:', error);
      });
    };

    setupToolsListener();

    // Cleanup listener on unmount or when org changes
    return () => unsubscribe();
  }, [nowViewingOrgId]);

  const handleSearch = async (query, action) => {
    if (!query.trim()) {
      setIsResultsVisible(true);
      setClickedButton('Search');
      setSearchAction(action);
      setSearchResult([{
        type: 'error',
        title: 'Empty Query',
        description: 'Please enter a search query'
      }]);
      return;
    }

    setIsLoading(true);
    setError(null);
    setIsResultsVisible(true);
    setClickedButton('Search');
    setSearchAction(action);
    setSearchResult(null);
    setStepResults([]);

    try {
      if (!isUserAuthenticated()) {
        throw new Error('No authentication available');
      }

      if (!nowViewingOrgId) {
        throw new Error('No organization selected');
      }

      const results = await difyService.processQueryWithUpdates(
        query, 
        nowViewingOrgId,
        (stepResult) => {
          setStepResults(current => [...current, stepResult]);
        }
      );
      
      setSearchResult(results);
      setProcessingStage('completed');
    } catch (err) {
      console.error('Search error:', err);
      setError('An error occurred while searching. Please try again.');
      setSearchResult([{
        type: 'error',
        title: 'Error Processing Search',
        description: err.message,
        metadata: {
          timestamp: new Date().toISOString(),
          error: err.toString()
        }
      }]);
    } finally {
      setIsLoading(false);
      setProcessingStage('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch(searchQuery, 'keyboard');
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleHistoryClick = () => {
    setIsResultsVisible(true);
    setClickedButton('History');
    // Will be implemented with new UI
    console.log('History clicked');
  };

  const handleIntegrationsClick = () => {
    setIsResultsVisible(true);
    setClickedButton('Integrations');
    setSearchResult(null);
    setSearchAction('');
  };

  const handleSearchIconClick = () => {
    if (searchQuery.trim()) {
      handleSearch(searchQuery, 'click');
    }
  };

  const handleAgentClick = () => {
    setIsResultsVisible(true);
    setClickedButton('agent');
    setSearchResult(null);
    setSearchAction('');
  };

  const handleSendMessage = async (message) => {
    try {
      const results = await difyService.processQueryWithUpdates(
        message, 
        nowViewingOrgId,
        (stepResult) => {
          setStepResults(current => [...current, stepResult]);
        }
      );
      
      setSearchResult(results);
      setProcessingStage('completed');
    } catch (err) {
      console.error('Message error:', err);
      setSearchResult([{
        type: 'error',
        title: 'Error Processing Message',
        description: err.message,
        metadata: {
          timestamp: new Date().toISOString(),
          error: err.toString()
        }
      }]);
    }
  };

  const handleSearchBarClick = () => {
    setIsResultsVisible(true);
    setClickedButton('');
    setSearchResult(null);
    setSearchAction('');
  };

  // Only render if we're on the dashboard and authenticated
  if (location.pathname !== '/dashboard' || !isUserAuthenticated()) {
    return null;
  }

  return (
    <>
      {/*
      <div className="super-search-wrapper">
        <div className="super-search-container">
          <div className="search-container">
            <div className={`search-input-wrapper ${isLoading ? 'loading' : ''}`}>
              <input
                type="text"
                className="super-search-search-input"
                placeholder="SuperSearch..."
                aria-label="Search"
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyPress={handleKeyPress}
                disabled={isLoading}
              />
            </div>
            <span 
              className="search-icon" 
              onClick={handleSearchIconClick}
              style={{ opacity: isLoading ? 0.5 : 1, cursor: isLoading ? 'not-allowed' : 'pointer' }}
            >
              🔍
            </span>
            <button 
              className="agent-button" 
              onClick={handleAgentClick} 
              title="AI Agent"
              disabled={isLoading}
            >
              🤖
            </button>
            <button 
              className="history-button" 
              onClick={handleHistoryClick} 
              title="Search History"
              disabled={isLoading}
            >
              <FaHistory />
            </button>
            <button 
              className="integrations-button" 
              onClick={handleIntegrationsClick} 
              title="Manage Integrations"
              disabled={isLoading}
            >
              ⚙️
              <span className="enabled-tools-count">
                {enabledToolsCount}
              </span>
            </button>
          </div>
        </div>
      </div>
      <UpdatedResultsWindow 
        isVisible={isResultsVisible} 
        onClose={() => {
          setIsResultsVisible(false);
          setClickedButton('');
          setSearchResult('');
          setSearchAction('');
          setProcessingStage('');
          setStepResults([]);
        }} 
        content={clickedButton} 
        searchAction={searchAction}
        isLoading={isLoading}
        processingStage={processingStage}
        searchResult={searchResult}
        stepResults={stepResults}
        onSendMessage={handleSendMessage}
        now_viewing_org_id={nowViewingOrgId}
      />
      */}
    </>
  );
};

export default SuperSearch;
