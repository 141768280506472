import React, { useState } from 'react';
import './Live.css';

const Live = () => {
  const [conversations, setConversations] = useState([{ id: 1, text: 'Agent: How can I assist you with AWS roles and permissions today?' }]);
  const [userInput, setUserInput] = useState('');
  const [showMoveUserPopup, setShowMoveUserPopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');

  const handleUserInput = (e) => {
    setUserInput(e.target.value);
  };

  const handleSend = async () => {
    if (userInput.trim() !== '') {
      const userMessage = { id: conversations.length + 1, text: `User: ${userInput}` };
      setConversations([...conversations, userMessage]);
      await handleCommand(userInput.trim().toLowerCase());
      setUserInput('');
    }
  };

  const handleCommand = async (command) => {
    let agentResponse = '';

    if (command === 'list groups') {
      // Table-style response for listing groups
      agentResponse = (
        <table className="result-table">
          <thead>
            <tr>
              <th>Group Name</th>
              <th>Policy</th>
              <th>Creation Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Developers</td>
              <td>FullAccess</td>
              <td>2023-01-12</td>
            </tr>
            <tr>
              <td>Auditors</td>
              <td>ReadOnly</td>
              <td>2022-11-22</td>
            </tr>
            <tr>
              <td>Admins</td>
              <td>AdministratorAccess</td>
              <td>2021-06-18</td>
            </tr>
          </tbody>
        </table>
      );
    } else if (command === 'list users') {
      // Table-style response for listing users
      agentResponse = (
        <table className="result-table">
          <thead>
            <tr>
              <th>User Name</th>
              <th>Roles</th>
              <th>Groups</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>test-user1</td>
              <td>Developer</td>
              <td>Developers, Admins</td>
            </tr>
            <tr>
              <td>auditor-user</td>
              <td>Auditor</td>
              <td>Auditors</td>
            </tr>
            <tr>
              <td>admin-user</td>
              <td>Admin</td>
              <td>Admins</td>
            </tr>
          </tbody>
        </table>
      );
    } else if (command === 'move user') {
      // Trigger the move user popup/modal
      setShowMoveUserPopup(true);
      agentResponse = 'Agent: Select a user and the group to move them to.';
    } else {
      agentResponse = 'Agent: I can help with commands like "list groups", "list users", or "move user".';
    }

    setConversations(prevConversations => [...prevConversations, { id: prevConversations.length + 1, text: agentResponse }]);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  const handleMoveUser = () => {
    if (selectedUser && selectedGroup) {
      const agentResponse = `Agent: Moved ${selectedUser} to group ${selectedGroup}.`;
      setConversations(prevConversations => [...prevConversations, { id: prevConversations.length + 1, text: agentResponse }]);
      setShowMoveUserPopup(false); // Close the modal
      setSelectedUser(''); // Reset selections
      setSelectedGroup('');
    }
  };

  return (
    <div className="live-container">
      <div className="live-header">
        <h1 className="live-title">AWS Roles & Permissions Chat</h1>
      </div>

      <div className="chat-section">
        <div className="chat-box">
          {conversations.map((message, index) => (
            <div key={index} className="chat-message">
              {typeof message.text === 'string' ? <p>{message.text}</p> : message.text}
            </div>
          ))}
        </div>
        <input
          type="text"
          value={userInput}
          onChange={handleUserInput}
          onKeyDown={handleKeyDown}  // Trigger action on 'Enter' key
          className="chat-input"
          placeholder="Type 'list groups', 'list users', or 'move user'"
        />
        <button onClick={handleSend} className="send-btn">Send</button>
      </div>

      {/* Modal/Popup for moving users */}
      {showMoveUserPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Move User to Group</h2>
            <label>
              Select User:
              <select value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                <option value="">--Select User--</option>
                <option value="test-user1">test-user1</option>
                <option value="auditor-user">auditor-user</option>
                <option value="admin-user">admin-user</option>
              </select>
            </label>

            <label>
              Select Group:
              <select value={selectedGroup} onChange={(e) => setSelectedGroup(e.target.value)}>
                <option value="">--Select Group--</option>
                <option value="Developers">Developers</option>
                <option value="Auditors">Auditors</option>
                <option value="Admins">Admins</option>
              </select>
            </label>

            <button onClick={handleMoveUser} className="send-btn">Move User</button>
            <button onClick={() => setShowMoveUserPopup(false)} className="send-btn">Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Live;