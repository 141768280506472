import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useGlobalState } from '../context/GlobalStateContext';
import './PersonnelComponent.css';

const PersonnelComponent = () => {
  const [personnel, setPersonnel] = useState([]);
  const [loading, setLoading] = useState(true);
  const { nowViewingOrgId } = useGlobalState();

  useEffect(() => {
    const fetchPersonnel = async () => {
      console.log('Current nowViewingOrgId:', nowViewingOrgId);

      if (!nowViewingOrgId) {
        console.log('No viewing org ID available');
        return;
      }

      try {
        setLoading(true);
        // Get team members from the currently viewed org's subcollection
        const teamMembersRef = collection(db, 'orgs', nowViewingOrgId, 'teamMembers');
        const teamMembersSnapshot = await getDocs(teamMembersRef);
        
        console.log('Team members snapshot size:', teamMembersSnapshot.size);
        console.log('Team members data:', teamMembersSnapshot.docs.map(doc => doc.data()));

        // Fetch full user data for each team member
        const personnelData = await Promise.all(
          teamMembersSnapshot.docs.map(async (teamMemberDoc) => {
            const userData = teamMemberDoc.data();
            console.log('Processing team member:', userData);
            
            // Fetch complete user data from users collection using uid
            const userDoc = await getDoc(doc(db, 'users', userData.uid));
            console.log('User document exists:', userDoc.exists(), 'for uid:', userData.uid);
            
            if (userDoc.exists()) {
              const fullUserData = {
                ...userDoc.data(),
                id: userDoc.id
              };
              console.log('Retrieved user data:', fullUserData);
              return fullUserData;
            }
            return null;
          })
        );

        // Filter out any null values and sort by name
        const filteredPersonnel = personnelData
          .filter(user => user !== null)
          .sort((a, b) => a.fname.localeCompare(b.fname));

        console.log('Final filtered personnel:', filteredPersonnel);
        setPersonnel(filteredPersonnel);
      } catch (error) {
        console.error('Error fetching personnel:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPersonnel();
  }, [nowViewingOrgId]);

  console.log('Current personnel state:', personnel);
  console.log('Current loading state:', loading);

  return (
    <div className="personnel-component">
      <h2 className="section-title">Personnel</h2>
      <div className="personnel-table-container">
        {loading ? (
          <div className="personnel-loading">Loading personnel data...</div>
        ) : personnel.length > 0 ? (
          <table className="personnel-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {personnel.map((person) => (
                <tr key={person.id}>
                  <td>{`${person.fname} ${person.lname}`}</td>
                  <td>{person.email}</td>
                  <td>{person.role}</td>
                  <td>
                    <span className={`status-indicator ${person.active ? 'active' : 'inactive'}`}>
                      {person.active ? 'Active' : 'Inactive'}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="no-personnel">No personnel found</div>
        )}
      </div>
    </div>
  );
};

export default PersonnelComponent; 