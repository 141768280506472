import React, { useState, useEffect, useCallback } from 'react';
import './RightSidebar.css'; 
import { format, addDays } from 'date-fns';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { FaBars, FaChevronRight } from 'react-icons/fa';
import { useGlobalState } from '../context/GlobalStateContext';

const RightSidebar = ({ onIssuesClick }) => {
  const [userInfo, setUserInfo] = useState({});
  const [userOrgInfo, setUserOrgInfo] = useState({});
  const [viewedOrgInfo, setViewedOrgInfo] = useState({});
  const [emailDomain, setEmailDomain] = useState('');
  const [isMinimized, setIsMinimized] = useState(false);
  const [loading, setLoading] = useState(true);
  const { nowViewingOrgId } = useGlobalState();
  const [completionStats, setCompletionStats] = useState({
    completed: 0,
    total: 0,
    percentage: 0
  });

  const fetchCompletionStats = useCallback(async (orgId) => {
    if (!orgId) return;

    try {
      const clientControlsRef = collection(db, 'client_controls');
      const q = query(clientControlsRef, where('client_id', '==', orgId));
      const querySnapshot = await getDocs(q);
      
      let completedCount = 0;
      const totalControls = querySnapshot.size;

      querySnapshot.forEach((doc) => {
        if (doc.data().completed_approved === true) {
          completedCount++;
        }
      });

      const completionPercentage = totalControls > 0 
        ? Math.round((completedCount / totalControls) * 100) 
        : 0;

      setCompletionStats({
        completed: completedCount,
        total: totalControls,
        percentage: completionPercentage
      });
    } catch (error) {
      console.error('Error fetching completion stats:', error);
    }
  }, []);

  useEffect(() => {
    const handleCompletionUpdate = async (event) => {
      const { orgId } = event.detail;
      if (orgId === nowViewingOrgId) {
        await fetchCompletionStats(orgId);
      }
    };

    window.addEventListener('completionStatsUpdated', handleCompletionUpdate);

    return () => {
      window.removeEventListener('completionStatsUpdated', handleCompletionUpdate);
    };
  }, [nowViewingOrgId, fetchCompletionStats]);

  useEffect(() => {
    const fetchViewedOrgInfo = async () => {
      if (nowViewingOrgId) {
        try {
          const orgDoc = await getDoc(doc(db, 'orgs', nowViewingOrgId));
          if (orgDoc.exists()) {
            setViewedOrgInfo(orgDoc.data());
          }
          // Fetch completion stats when org info is fetched
          await fetchCompletionStats(nowViewingOrgId);
        } catch (error) {
          console.error('Error fetching viewed org info:', error);
        }
      }
    };

    fetchViewedOrgInfo();
  }, [nowViewingOrgId, fetchCompletionStats]);

  useEffect(() => {
    const auth = getAuth();
    let unsubscribe;

    const fetchUserData = async (user) => {
      if (!user?.email) {
        setLoading(false);
        return;
      }

      try {
        setEmailDomain(user.email.split('@')[1]);
        
        // Query users collection
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('email', '==', user.email));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          setUserInfo(userData);
          
          // Fetch user's organization info
          const orgDoc = await getDoc(doc(db, 'orgs', userData.orgId));
          if (orgDoc.exists()) {
            setUserOrgInfo(orgDoc.data());
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData(user);
      } else {
        setLoading(false);
        setEmailDomain('');
        setUserInfo({});
        setUserOrgInfo({});
      }
    });

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  const toggleSidebar = () => {
    setIsMinimized(!isMinimized);
  };

  if (loading) {
    return (
      <aside className={`right-sidebar ${isMinimized ? 'minimized' : ''}`}>
        <button className="toggle-btn-right" onClick={toggleSidebar}>
          {isMinimized ? <FaBars /> : <FaChevronRight />}
        </button>
        <div className="sidebar-content">
          <div>Loading...</div>
        </div>
      </aside>
    );
  }

  return (
    <aside className={`right-sidebar ${isMinimized ? 'minimized' : ''}`}>
      <button className="toggle-btn-right" onClick={toggleSidebar}>
        {isMinimized ? <FaBars /> : <FaChevronRight />}
      </button>
      {!isMinimized && (
        <div className="sidebar-content">
          {/* User Profile Section */}
          <div className="user-profile-card">
            <img src={userOrgInfo.logo} alt="Organization Logo" className="user-logo" />
            <div className="user-info">
              <h3>{userInfo.fname} {userInfo.lname}</h3>
              <p>{userInfo.role} at {userOrgInfo.name}</p>
            </div>
          </div>

          {/* Metrics Section */}
          <div className="metrics-section">
            <div className="metric">
              <h4>Compliance Frameworks</h4>
              <p>{viewedOrgInfo.complianceFramework ? viewedOrgInfo.complianceFramework.join(', ') : 'N/A'}</p>
              <div className="completion-indicator">
                <div className="completion-bar">
                  <div 
                    className="completion-progress" 
                    style={{ width: `${completionStats.percentage}%` }}
                  />
                </div>
                <span className="completion-text">
                  {completionStats.percentage}% Complete
                  <span className="completion-details">
                    ({completionStats.completed}/{completionStats.total} Controls Satisfied)
                  </span>
                </span>
              </div>
            </div>
            <div className="metric">
              <h4>Current Issues</h4>
              <button 
                onClick={onIssuesClick}
                className="issues-button"
                style={{ background: 'none', border: 'none', cursor: 'pointer', padding: 0 }}
              >
                <p className="large-number orange-number">0</p>
              </button>
            </div>
          </div>

          {/* Upcoming Events */}
          <div className="events-section">
            <h4>Upcoming Events</h4>
            <div className="calendar-view">
              {Array.from({ length: 6 }).map((_, index) => {
                const today = new Date();
                const currentDay = today.getDay();
                const startDate = currentDay === 0 || currentDay === 6
                  ? addDays(today, 8 - currentDay)
                  : today;
                const dateToShow = addDays(startDate, index);
                const formattedDateDay = format(dateToShow, 'E');
                const formattedDateDate = format(dateToShow, 'MMM d');

                return (
                  <div key={index} className="calendar-time-slot">
                    <div className="time-label">{formattedDateDay}<br></br>{formattedDateDate}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </aside>
  );
};

export default RightSidebar;
