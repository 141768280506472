import React, { useState, useEffect } from 'react';
import { getDoc, doc, collection, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase';
import './Settings.css';

const Settings = () => {
  const [userData, setUserData] = useState(null);
  const [orgData, setOrgData] = useState(null);
  const [linkedOrgs, setLinkedOrgs] = useState([]);
  const [activeTab, setActiveTab] = useState('userInformation');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setError(null);
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (currentUser?.uid) {
        try {
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            setUserData(userDocSnap.data());
            const orgId = userDocSnap.data().orgId;
            if (orgId) {
              const orgDocRef = doc(db, 'orgs', orgId);
              const orgDocSnap = await getDoc(orgDocRef);
              if (orgDocSnap.exists()) {
                setOrgData(orgDocSnap.data());
                const linkedClientIds = orgDocSnap.data().linkedClients;
                if (linkedClientIds) {
                  setLinkedOrgs(await fetchLinkedOrganizations(linkedClientIds));
                }
              } else {
                setError("Organization data not found");
              }
            }
          } else {
            setError("User data not found");
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          setError("An error occurred while fetching data");
        }
      } else {
        setError("No authenticated user found");
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const fetchLinkedOrganizations = async (linkedClientIds) => {
    const orgs = [];
    for (const orgId of linkedClientIds) {
      try {
        const orgDocRef = doc(db, 'orgs', orgId);
        const orgDocSnap = await getDoc(orgDocRef);
        if (orgDocSnap.exists()) {
          orgs.push(orgDocSnap.data());
        }
      } catch (error) {
        console.error("Error fetching linked organization:", error);
      }
    }
    return orgs;
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'userInformation':
        return (
          <div className="user-info-section">
            <h3>User Information</h3>
            <p><strong>Name:</strong> {userData.fname} {userData.lname}</p>
            <p><strong>Email:</strong> {userData.email}</p>
            <p><strong>Role:</strong> {userData.role}</p>
            <p><strong>Status:</strong> {userData.active ? 'Active' : 'Inactive'}</p>
            <p><strong>Admin:</strong> {userData.admin ? 'Yes' : 'No'}</p>
          </div>
        );
      case 'organizationInformation':
        return (
          <div className="org-info-section">
            <h3>Organization Information</h3>
            <p><strong>Name:</strong> {orgData.name}</p>
            <p><strong>Type:</strong> {orgData.type}</p>
            <p><strong>Compliance Frameworks:</strong> {orgData.complianceFramework ? orgData.complianceFramework.join(', ') : 'N/A'}</p>
            <p><strong>Cloud Platforms:</strong> {orgData.cloudPlatform ? orgData.cloudPlatform.join(', ') : 'N/A'}</p>
          </div>
        );
      case 'linkedOrganizations':
        return (
          <div className="linked-orgs-section">
            <h3>Linked Organizations</h3>
            <ul>
              {linkedOrgs.map((org) => (
                <li key={org.orgId}>
                  <p><strong>Name:</strong> {org.name}</p>
                  <p><strong>Type:</strong> {org.type}</p>
                </li>
              ))}
            </ul>
          </div>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return <div className="settings-container"><p>Loading...</p></div>;
  }

  if (error) {
    return <div className="settings-container"><p>Error: {error}</p></div>;
  }

  if (!userData) {
    return <div className="settings-container"><p>No user data available</p></div>;
  }

  return (
    <div className="settings-container">
      <div className="tabs">
        <button className={`tab ${activeTab === 'userInformation' ? 'active' : ''}`} onClick={() => setActiveTab('userInformation')}>User Information</button>
        <button className={`tab ${activeTab === 'organizationInformation' ? 'active' : ''}`} onClick={() => setActiveTab('organizationInformation')}>Organization Information</button>
        <button className={`tab ${activeTab === 'linkedOrganizations' ? 'active' : ''}`} onClick={() => setActiveTab('linkedOrganizations')}>Linked Organizations</button>
      </div>
      {renderTabContent()}
    </div>
  );
};

export default Settings;
