import React, { useState } from 'react';
import ControlsTable from './ControlsTable';
import ControlDetailsSidebar from './ControlDetailsSidebar';
import ControlDetailView from './ControlDetailView';
import ControlsInfo from './ControlsInfo';
import ControlsHeader from './ControlsHeader';

const ParentComponent = ({ selectedControl, selectedClient, onOpenDetailView }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isDetailViewOpen, setDetailViewOpen] = useState(false);
  const [selectedControlDetails, setSelectedControlDetails] = useState(null);

  const openSidebar = (controlDetails) => {
    setSelectedControlDetails(controlDetails);
    setSidebarOpen(true);
    setDetailViewOpen(false); // Ensure detail view is closed when opening sidebar
  };
  
  const openDetailView = (controlDetails) => {
    setSelectedControlDetails(controlDetails);
    setDetailViewOpen(true);
    setSidebarOpen(false); // Ensure sidebar is closed when opening detail view
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  }; 

  const closeDetailView = () => {
    setDetailViewOpen(false);
  };

  return (
    <div className="parent-container">
      <ControlsInfo 
        controlType={selectedControl} 
        clientId={selectedClient} 
        onCloseSidebar={closeSidebar}  // Pass the closeSidebar function
      />
      <ControlsTable 
        controlType={selectedControl} 
        clientId={selectedClient} 
        onOpenDetailView={openSidebar} 
      />

      {isSidebarOpen && (
        <ControlDetailsSidebar
          isOpen={isSidebarOpen}
          onClose={closeSidebar}
          controlDetails={selectedControlDetails}
          onOpenDetailView={openDetailView} // Pass the method to open the detail view
        />
      )}

      {isDetailViewOpen && (
        <ControlDetailView
          isOpen={isDetailViewOpen}
          controlDetails={selectedControlDetails}
          onClose={closeDetailView}
        />
      )}
    </div>
  );
};

export default ParentComponent; 
