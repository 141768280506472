import React, { useState, useEffect } from 'react';
import './Provisioning.css';
import { auth, db } from '../firebase';
import { collection, query, where, getDocs, doc, getDoc, setDoc, updateDoc, deleteDoc, addDoc } from 'firebase/firestore';

const Provisioning = () => {
  const [activeTab, setActiveTab] = useState('vayu'); // Default to 'vayu' tab
  const [orgName, setOrgName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [customNotes, setCustomNotes] = useState('');
  const [teamMembers, setTeamMembers] = useState([{ fname: '', lname: '', email: '', role: 'User' }]);
  const [complianceFramework, setComplianceFramework] = useState([]);
  const [cloudPlatform, setCloudPlatform] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [saving, setSaving] = useState(false); // Loader state for saving
  const [deleting, setDeleting] = useState(false); // Loader state for deleting
  const [purging, setPurging] = useState(false); // Loader state for purging
  const [linkedClients, setLinkedClients] = useState([]); // State for linked clients
  const [selectedClientToLink, setSelectedClientToLink] = useState(''); // State for the client to link  
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupContent, setPopupContent] = useState(''); 
  const [frameworks, setFrameworks] = useState([]); // Add this line to store the frameworks  
  const [cloudPlatforms, setCloudPlatforms] = useState([]); // Add this line to store the cloud platforms  
const [editingSection, setEditingSection] = useState(''); // Tracks which section is being edited 
const [teamMemberPopupVisible, setTeamMemberPopupVisible] = useState(false); // State for the team member popup  
const [newItemName, setNewItemName] = useState(''); 

const handleInfoClick = (tempPw, userSetPw) => {
  setPopupContent(
    <>
      Temporary Password: <code className="code-block">{tempPw}</code>
      <br />
      User Set Password: {userSetPw ? 'Yes' : 'No'}
    </>
  );
  setTeamMemberPopupVisible(true);
}; 

const closePopup = () => {
  setPopupVisible(false); // For compliance framework/cloud platform popup
  setTeamMemberPopupVisible(false); // For team member popup
  setEditingSection(''); // Reset editing section
}; 

  useEffect(() => {
    if (activeTab === 'clients') {
      setSelectedOrg('');
      setOrgName('');
      setStartDate('');
      setCustomNotes('');
      setComplianceFramework([]);
      setCloudPlatform([]);
      setLinkedClients([]);
      setTeamMembers([]);
    } else if (activeTab === 'auditors') {
      setSelectedOrg('');
      setOrgName('');
      setTeamMembers([]);
      setCustomNotes('');
    }

    const fetchFrameworks = async () => {
      try {
        const frameworksSnapshot = await getDocs(collection(db, 'frameworks'));
        const frameworksData = frameworksSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log('Fetched Frameworks:', frameworksData);
        setFrameworks(frameworksData);
      } catch (error) {
        console.error('Error fetching frameworks:', error);
      }
    };

    const fetchCloudPlatforms = async () => {
      try {
        const cloudPlatformsSnapshot = await getDocs(collection(db, 'cloud_platforms'));
        const cloudPlatformsData = cloudPlatformsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log('Fetched Cloud Platforms:', cloudPlatformsData);
        setCloudPlatforms(cloudPlatformsData);
      } catch (error) {
        console.error('Error fetching cloud platforms:', error);
      }
    };

    const fetchOrgs = async () => {
      const type = activeTab === 'clients' ? 'client' : activeTab === 'auditors' ? 'auditor' : 'vayu';
      try {
        const orgsQuery = query(collection(db, 'orgs'), where('type', '==', type));
        const orgsSnapshot = await getDocs(orgsQuery);
        let data = orgsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log('Fetched Orgs:', data);
        data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        setOrgs(data);

        if (activeTab === 'auditors') {
          const clientOrgsQuery = query(collection(db, 'orgs'), where('type', '==', 'client'));
          const clientOrgsSnapshot = await getDocs(clientOrgsQuery);
          let clientData = clientOrgsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          data = [...data, ...clientData];
          setOrgs(data);
        }

        if (activeTab === 'vayu' && data.length > 0) {
          setSelectedOrg(data[0].id);
          setOrgName(data[0].name || '');
          const orgDoc = await getDoc(doc(db, 'orgs', data[0].id));
          const orgData = orgDoc.data();
          setTeamMembers(orgData.teamMembers || [{ fname: '', lname: '', email: '', role: 'User' }]);
        }
      } catch (error) {
        console.error(`Error fetching ${type}:`, error);
      }
    };

    console.log('Active Tab:', activeTab);
    fetchFrameworks();
    fetchCloudPlatforms();
    fetchOrgs();
  }, [activeTab]);

  const handleOrgSelect = async (e) => {
    const orgId = e.target.value;
    setSelectedOrg(orgId);

    if (orgId) {
      try {
        setSaving(true);
        const orgDoc = await getDoc(doc(db, 'orgs', orgId));
        if (orgDoc.exists()) {
          const orgData = orgDoc.data();
          setOrgName(orgData.name || '');
          
          let teamMembersWithPw = [];
          if (orgData.teamMembers && orgData.teamMembers.length > 0) {
            teamMembersWithPw = await Promise.all(orgData.teamMembers.map(async (member) => {
              try {
                const userDoc = await getDoc(doc(db, 'users', member.uid));
                if (userDoc.exists()) {
                  const userData = userDoc.data();
                  return {
                    ...member,
                    tempPw: userData.tempPw || '',
                    userSetPw: userData.userSetPw || false
                  };
                }
                return member;
              } catch (error) {
                console.error('Error fetching user data:', error);
                return member;
              }
            }));
          }

          setTeamMembers(teamMembersWithPw);

          if (activeTab !== 'vayu') {
            setStartDate(orgData.startDate || '');
            setCustomNotes(orgData.customNotes || '');
            setComplianceFramework(orgData.complianceFramework || []);
            setCloudPlatform(orgData.cloudPlatform || []);
            setLinkedClients(orgData.linkedClients || []);
          }
        }
      } catch (error) {
        console.error(`Error fetching ${activeTab} details:`, error);
      } finally {
        setSaving(false);
      }
    } else {
      setOrgName('');
      setStartDate('');
      setCustomNotes('');
      setTeamMembers([{ fname: '', lname: '', email: '', role: 'Admin', tempPw: '', userSetPw: false }]);
      setComplianceFramework([]);
      setCloudPlatform([]);
      setLinkedClients([]);
    }
  };

  const handleAddTeamMember = () => {
    const newMember = {
      tempId: `temp-${Date.now()}`, // Unique identifier for unsaved members
      fname: '',
      lname: '',
      email: '',
      role: 'Admin',
    };
    setTeamMembers([...teamMembers, newMember]);
  };   

      
      const handlePopupSubmit = async () => {
        try {
          const orgRef = doc(db, 'orgs', selectedOrg);
          
          if (editingSection === 'complianceFramework') {
            if (newItemName) {
              const newFrameworkRef = await addDoc(collection(db, 'frameworks'), {
                framework_name: newItemName
              });
              setFrameworks([...frameworks, { id: newFrameworkRef.id, framework_name: newItemName }]);
              setComplianceFramework([...complianceFramework, newFrameworkRef.id]);
              setNewItemName('');
            }
            await updateDoc(orgRef, {
              complianceFramework: complianceFramework
            });
          } else if (editingSection === 'cloudPlatform') {
            if (newItemName) {
              const newPlatformRef = await addDoc(collection(db, 'cloud_platforms'), {
                cloud_platform_name: newItemName
              });
              setCloudPlatforms([...cloudPlatforms, { id: newPlatformRef.id, cloud_platform_name: newItemName }]);
              setCloudPlatform([...cloudPlatform, newPlatformRef.id]);
              setNewItemName('');
            }
            await updateDoc(orgRef, {
              cloudPlatform: cloudPlatform
            });
          }
          
          console.log('Changes saved successfully');
        } catch (error) {
          console.error('Error during submission:', error);
          alert('There was an error saving your changes. Please try again.');
        } finally {
          closePopup();
        }
      }; 

      const handleUnlinkClient = async (clientId) => {
        try {
          const orgRef = doc(db, 'orgs', selectedOrg);
          await updateDoc(orgRef, {
            linkedClients: linkedClients.filter(id => id !== clientId)
          });

          // Update local state
          setLinkedClients(linkedClients.filter(id => id !== clientId));
        } catch (error) {
          console.error('Error unlinking client:', error);
        }
      }; 

  const handleLinkClient = async () => {
    if (selectedClientToLink && !linkedClients.includes(selectedClientToLink)) {
      try {
        const orgRef = doc(db, 'orgs', selectedOrg);
        await updateDoc(orgRef, {
          linkedClients: [...linkedClients, selectedClientToLink]
        });
        
        // Update local state
        setLinkedClients([...linkedClients, selectedClientToLink]);
        setSelectedClientToLink(''); // Clear the dropdown after linking
      } catch (error) {
        console.error('Error linking client:', error);
      }
    }
  }; 

  const handleDeleteTeamMember = async (index) => {
    const memberToDelete = teamMembers[index];
    
    if (memberToDelete.tempId) {
      // Remove the unsaved member from the list
      const updatedMembers = teamMembers.filter((_, i) => i !== index);
      setTeamMembers(updatedMembers);
      return;
    }
    
    if (!memberToDelete.uid) return; // Safeguard if no uid is present for saved members
    
    const confirmDelete = window.confirm(`Are you sure you want to delete ${memberToDelete.email}? This action cannot be undone.`);
    
    if (confirmDelete) {
      setDeleting(true);
      try {
        // Delete the user document from Firestore
        await deleteDoc(doc(db, 'users', memberToDelete.uid));

        // Remove the member from the org's teamMembers array
        const orgRef = doc(db, 'orgs', selectedOrg);
        await updateDoc(orgRef, {
          teamMembers: teamMembers.filter((_, i) => i !== index)
        });

        alert('Team member deleted successfully.');
        // Remove the member from the local state
        const updatedMembers = teamMembers.filter((_, i) => i !== index);
        setTeamMembers(updatedMembers);
      } catch (error) {
        console.error('Error deleting team member:', error);
        alert('Error deleting team member. Please try again.');
      } finally {
        setDeleting(false);
      }
    }
  }; 

  const handleTeamMemberChange = (index, field, value) => {
    const updatedMembers = teamMembers.map((member, i) =>
      i === index ? { ...member, [field]: value } : member
    );
    setTeamMembers(updatedMembers);
  }; 

  const handleFrameworkChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setComplianceFramework(prevState => [...prevState, value]);
    } else {
      setComplianceFramework(prevState => prevState.filter((framework) => framework !== value));
    }
  };
  
  const handlePlatformChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setCloudPlatform(prevState => [...prevState, value]);
    } else {
      setCloudPlatform(prevState => prevState.filter((platform) => platform !== value));
    }
  }; 

  const handleSave = async () => {
    setSaving(true);

    const formattedTeamMembers = teamMembers.map((member) => ({
      ...member,
      role: member.role.toLowerCase(),
      active: true,
      admin: member.role.toLowerCase() === 'admin',
      auditor: activeTab === 'auditors',
      client: activeTab === 'clients',
    }));

    let orgIdToSave = selectedOrg;

    if (!selectedOrg && (activeTab === 'clients' || activeTab === 'auditors')) {
      try {
        const orgType = activeTab === 'clients' ? 'client' : 'auditor';
        const newOrgRef = await addDoc(collection(db, 'orgs'), {
          name: orgName,
          type: orgType,
          createdAt: new Date().toISOString()
        });
        setSelectedOrg(newOrgRef.id);
        orgIdToSave = newOrgRef.id;
      } catch (error) {
        console.error(`Error creating ${activeTab} organization:`, error);
        alert(`Error creating ${activeTab} organization. Please try again.`);
        setSaving(false);
        return;
      }
    }

    try {
      const orgRef = doc(db, 'orgs', orgIdToSave);
      await updateDoc(orgRef, {
        name: orgName,
        startDate: activeTab === 'clients' ? startDate : undefined,
        customNotes: activeTab === 'clients' || activeTab === 'auditors' ? customNotes : undefined,
        complianceFramework: activeTab === 'clients' ? complianceFramework : undefined,
        cloudPlatform: activeTab === 'clients' ? cloudPlatform : undefined,
        linkedClients: activeTab === 'auditors' ? linkedClients : undefined,
        teamMembers: formattedTeamMembers
      });

      console.log('Provisioning saved successfully');
      alert('Provisioning saved successfully');

      // Fetch and update team members with actual uids
      const updatedOrgDoc = await getDoc(orgRef);
      if (updatedOrgDoc.exists()) {
        const updatedOrgData = updatedOrgDoc.data();
        setTeamMembers(updatedOrgData.teamMembers || [{ fname: '', lname: '', email: '', role: 'User' }]);
      }
    } catch (error) {
      console.error('Error saving provisioning:', error);
      alert('Error saving provisioning. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  const handlePurge = async () => {
    if (!selectedOrg) {
      alert(`Please select a ${activeTab} to purge.`);
      return;
    }

    const confirmPurge = window.confirm(`Are you sure you want to delete this ${activeTab} and all associated team members? This action cannot be undone.`);

    if (confirmPurge) {
      setPurging(true);
      try {
        // Delete the org document
        await deleteDoc(doc(db, 'orgs', selectedOrg));

        // Delete associated team members
        const usersQuery = query(collection(db, 'users'), where('orgId', '==', selectedOrg));
        const usersSnapshot = await getDocs(usersQuery);
        const userDeletePromises = usersSnapshot.docs.map(userDoc => deleteDoc(userDoc.ref));
        await Promise.all(userDeletePromises);

        alert(`${activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} and associated team members deleted successfully.`);

        setSelectedOrg('');
        setOrgName('');
        setStartDate('');
        setCustomNotes('');
        setTeamMembers([{ fname: '', lname: '', email: '', role: 'user' }]);
        setComplianceFramework([]);
        setCloudPlatform([]);
        setLinkedClients([]);

        // Fetch updated orgs
        const updatedOrgsQuery = query(collection(db, 'orgs'), where('type', '==', activeTab));
        const updatedOrgsSnapshot = await getDocs(updatedOrgsQuery);
        const updatedOrgs = updatedOrgsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setOrgs(updatedOrgs);
      } catch (error) {
        console.error(`Error purging ${activeTab}:`, error);
        alert(`Error purging ${activeTab}. Please try again.`);
      } finally {
        setPurging(false);
      }
    }
  };

  return (
    <div className="provisioning-container">
      <div className="tabs">
      <button
  className={`tab-button ${activeTab === 'clients' ? 'active' : ''}`}
  onClick={() => setActiveTab('clients')}
>
  Clients
</button>
        <button
          className={`tab-button ${activeTab === 'auditors' ? 'active' : ''}`}
          onClick={() => setActiveTab('auditors')}
        >
          Auditors
        </button>
        <button
          className={`tab-button ${activeTab === 'vayu' ? 'active' : ''}`}
          onClick={() => setActiveTab('vayu')}
        >
          Vayu
        </button>
      </div>
  
      {/* Moved this block above other form fields */}
  <div className="form-group">
    <label>{activeTab === 'clients' ? 'Select Client' : activeTab === 'auditors' ? 'Select Auditor' : 'Select Org'}</label>
    <select value={selectedOrg} onChange={handleOrgSelect} disabled={activeTab === 'vayu'}>
    <option value="">{`-- Create New ${activeTab === 'clients' ? 'Client' : activeTab === 'auditors' ? 'Auditor' : 'Org'} --`}</option>
       {orgs
        .filter(org => org.type === (activeTab === 'clients' ? 'client' : activeTab === 'auditors' ? 'auditor' : 'vayu'))
        .map(org => (
          <option key={org.id} value={org.id}>
            {org.name}
          </option>
        ))}
    </select>
  </div>
  
      <div className="form-group">
        <label>
          {activeTab === 'clients' ? 'Client Name' : activeTab === 'auditors' ? 'Auditor Name' : 'Org Name'}
        </label>
        <input
          type="text"
          value={orgName}
          readOnly={activeTab === 'vayu'}
          onChange={(e) => setOrgName(e.target.value)}
        />
      </div>
  
      {activeTab === 'clients' && (
        <>
          <div className="form-group">
            <label>Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
  
          
        </>
      )}
  
      {activeTab === 'auditors' && (
        <>
          <div className="form-group">
  <label>Linked Clients</label>
  <div className="linked-clients">
    {linkedClients.map((clientId, index) => {
      const client = orgs.find(org => org.id === clientId);
      return (
        <div key={index} className="linked-client-badge">
          <span>{client?.name}</span>
          <button onClick={() => handleUnlinkClient(clientId)} className="unlink-client-button">
            ✖
          </button>
        </div>
      );
    })}
  </div>
</div> 
  
          <div className="form-group">
            <label>Link a Client</label>
            <select
              value={selectedClientToLink}
              onChange={(e) => setSelectedClientToLink(e.target.value)}
            >
              <option value="">-- Select Client to Link --</option>
              {orgs
                .filter(org => org.type === 'client')
                .map(org => (
                  <option key={org.id} value={org.id}>
                    {org.name}
                  </option>
                ))}
            </select>
            <button onClick={handleLinkClient} className="link-client-button">Link Client</button>
          </div>
        </>
      )}
  
  <div className="form-group">
      <div className="provisioning-container">
      <div className="form-group">
        <label>Team Members</label>
        {teamMembers.map((member, index) => (
          <div key={index} className="team-member-row">
          <input
            type="text"
            placeholder="First Name"
            value={member.fname}
            onChange={(e) => handleTeamMemberChange(index, 'fname', e.target.value)}
          />
          <input
            type="text"
            placeholder="Last Name"
            value={member.lname}
            onChange={(e) => handleTeamMemberChange(index, 'lname', e.target.value)}
          />
          <input
            type="email"
            placeholder="Email"
            value={member.email}
            onChange={(e) => handleTeamMemberChange(index, 'email', e.target.value)}
          />
          <select
            value={member.role}
            onChange={(e) => handleTeamMemberChange(index, 'role', e.target.value)}
          >
            <option value="admin">Admin</option>
            <option value="user">User</option>
          </select>
          <span
  className="info-icon"
  onClick={() => handleInfoClick(member.tempPw, member.userSetPw)}
>
  ℹ️
</span> 
          <button
            onClick={() => handleDeleteTeamMember(index)}
            className="delete-team-member-button"
          >
            Delete
          </button>
        </div>
        ))}
        <button onClick={handleAddTeamMember} className="add-team-member-button">Add Team Member</button>
      </div>

      {teamMemberPopupVisible && (
  <div className="team-member-popup active">
    <div>{popupContent}</div>
    <button onClick={closePopup}>Close</button>
  </div>
)}

{popupVisible && (
  <div className="popup-overlay">
    <div className="popup-content">
      <h3>Edit {editingSection === 'complianceFramework' ? 'Compliance Framework' : 'Cloud Platform'}</h3>
      <ul className="list-group">
        {(editingSection === 'complianceFramework' ? frameworks : cloudPlatforms).map((item) => (
          <li key={item.id || item.cloud_platform_id} className="list-group-item">
            {item.framework_name || item.cloud_platform_name}
          </li>
        ))}
      </ul>
      <div className="form-group">
        {/*
        <label>New {editingSection === 'complianceFramework' ? 'Framework' : 'Cloud Platform'}</label> 
        */} 
        <input
          type="text"
          value={newItemName}
          onChange={(e) => setNewItemName(e.target.value)}
          placeholder={`Enter new ${editingSection === 'complianceFramework' ? 'framework' : 'cloud platform'}`}
        />
        <button
          onClick={handlePopupSubmit}
        >
          Save
        </button>
        <button onClick={closePopup}>Cancel</button>
      </div>
    </div>
  </div>
)} 
    </div>

</div>  
  
      {(activeTab === 'clients' || activeTab === 'auditors') && (
        <div className="form-group">
          <label>Custom Notes</label>
          <textarea
            value={customNotes}
            onChange={(e) => setCustomNotes(e.target.value)}
            rows="4"
          />
        </div>
      )}
  
      {activeTab === 'clients' && (
        <>
          <div className="form-group">
  <label>
    Compliance Framework 
    <button className="edit-button" onClick={() => {
      setEditingSection('complianceFramework');
      setPopupVisible(true);
    }}>
      (edit)
    </button>
  </label>
  <div className="checkbox-group">
    {frameworks.map((framework) => (
      <label key={framework.id}>
        <input
          type="checkbox"
          value={framework.id} // Use the framework ID
          checked={complianceFramework.includes(framework.id)}
          onChange={handleFrameworkChange}
        />
        {framework.framework_name} {/* Display the framework name */}
      </label>
    ))}
  </div>
</div> 
  
<div className="form-group">
  <label>
    Cloud Platform
    <button className="edit-button" onClick={() => {
      setEditingSection('cloudPlatform');
      setPopupVisible(true);
    }}>
      (edit)
    </button>
  </label>
  <div className="checkbox-group">
    {cloudPlatforms.map((platform) => (
      <label key={platform.cloud_platform_id}>
        <input
          type="checkbox"
          value={platform.cloud_platform_id} // Use the cloud platform ID
          checked={cloudPlatform.includes(platform.cloud_platform_id)}
          onChange={handlePlatformChange}
        />
        {platform.cloud_platform_name} {/* Display the cloud platform name */}
      </label>
    ))}
  </div>
</div> 
        </>
      )}
  
      <button onClick={handleSave} className="save-button">
        {saving ? <div className="button-loader">Loading...</div> : "Save"}
      </button>
      {activeTab !== 'vayu' && (
  <button onClick={handlePurge} className="purge-button">
    {purging ? <div className="button-loader">Loading...</div> : "Purge"}
  </button>
  
)} 
    </div>
    
  ); 
};

export default Provisioning;

