// ControlsTable.js - Version 2.0.3
// "In all your ways acknowledge Him, and He shall direct your paths." - Proverbs 3:6

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import './ControlsTable.css'; // Ensure the CSS is correct
import ControlDetailsSidebar from './ControlDetailsSidebar'; // Import ControlDetailsSidebar
import { auth, db } from '../firebase';
import Loader from './Loader'; // Import Loader
import { useGlobalState } from '../context/GlobalStateContext';
import UnlinkControlModal from './UnlinkControlModal'; // Import UnlinkControlModal
import RelinkControlModal from './RelinkControlModal'; // Import RelinkControlModal
import { collection, query, where, onSnapshot, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import EvidenceModal from './EvidenceModal'; // Import EvidenceModal 

// Define control IDs for each organization
const ORIGIN63_CONTROL_IDS = [
  'C011', 'C012', 'C013',
  'C035', 'C036', 'C037',
  'C074', 'C077', 'C078',
  'C082', 'C083', 'C084', 'C085', 'C086', 'C087', 'C088',
  'C089', 'C090', 'C091', 'C092', 'C093',
  'C095', 'C096', 'C097', 'C098', 'C099', 'C100', 'C101', 'C102', 'C103', 'C104', 'C105',
  'C107', 'C108', 'C109', 'C110', 'C111', 'C112', 'C113', 'C114',
  'C120', 'C121', 'C122',
  'C124', 'C125', 'C126', 'C127', 'C128', 'C129', 'C130', 'C131', 'C132',
  'C136', 'C137', 'C138', 'C139', 'C140', 'C141', 'C142',
  'C151', 'C152', 'C153', 'C154', 'C155'
];

const EMERSON_CONTROL_IDS = [
  'C073',
  'C086', 'C089',
  'C096', 'C097', 'C098',
  'C104', 'C105', 'C106', 'C107', 'C108',
  'C110', 'C111', 'C112', 'C113', 'C114',
  'C116', 'C177',

  'C011', 'C012', 'C013',
  'C035', 'C036',
  'C074',
  'C091', 'C092', 'C093',
  'C099', 'C100', 'C101', 'C102', 'C103',
  'C120', 'C121', 'C122',
  'C125',
  'C127', 'C128', 'C129', 'C130', 'C131',
  'C141',

  'C076',
  'C087', 'C088',
  'C090',
  'C139', 'C140', 'C141', 'C142',
  'C151', 'C152', 'C153', 'C154', 'C155',
  'C162', 'C163', 'C164', 'C165',
  'C168', 'C169', 'C170', 'C171', 'C172',

  'C163', 'C165', 'C169',
  'C171', 'C172',
  'C179'
];

// Define EMERSON_AUTOMATION_CATEGORIES first
const EMERSON_AUTOMATION_CATEGORIES = {
  // Identity & Access Management
  'C073': 'IT Controls for Unauthorized Access Prevention',
  'C086': 'Role-Based Access Control',
  'C089': 'Least Privilege Access Control',
  'C096': 'Access Control Software, MFA, VPN Authentication',
  'C097': 'Access Control Software, MFA, VPN Authentication',
  'C098': 'Access Control Software, MFA, VPN Authentication',
  // ... (rest of Emerson categories)
};

// Define ORIGIN63_AUTOMATION_CATEGORIES
const ORIGIN63_AUTOMATION_CATEGORIES = {
  // Security Policy & Monitoring
  'C011': 'Security policy monitoring and vulnerability scanning',
  'C012': 'Security policy monitoring and vulnerability scanning',
  'C013': 'Security policy monitoring and vulnerability scanning',
  
  // System Monitoring & Configuration
  'C035': 'System monitoring and configuration management',
  'C036': 'System monitoring and configuration management',
  'C037': 'System monitoring and configuration management',
  'C040': 'System change notifications',
  'C041': 'Security events monitoring',
  
  // Environment Monitoring
  'C074': 'Production environment monitoring',
  'C076': 'Environment monitoring',
  'C077': 'Environment monitoring and penetration testing',
  'C078': 'Environment monitoring and penetration testing',
  
  // Access & Incident Management
  'C082': 'System access and incident management',
  'C083': 'System access and incident management',
  'C084': 'System access and incident management',
  'C085': 'System access and incident management',
  'C086': 'System access and incident management',
  'C087': 'System access and incident management',
  'C088': 'System access and incident management',
  
  // Change Management & Encryption
  'C089': 'Change management and encryption controls',
  'C090': 'Change management and encryption controls',
  'C091': 'Change management and encryption controls',
  'C092': 'Change management and encryption controls',
  'C093': 'Change management and encryption controls',
  
  // Logical Access Controls
  'C095': 'Information asset inventory',
  'C096': 'Access control software',
  'C097': 'MFA implementation',
  'C098': 'VPN authentication',
  'C099': 'Access point management',
  'C100': 'Network segmentation',
  'C101': 'Logical access controls',
  'C102': 'Logical access controls',
  'C103': 'Logical access controls',
  'C104': 'Logical access controls',
  'C105': 'Logical access controls',
  
  // Authentication & Access Management
  'C107': 'Authentication and access management',
  'C108': 'Authentication and access management',
  'C109': 'Authentication and access management',
  'C110': 'Authentication and access management',
  'C111': 'Authentication and access management',
  'C112': 'Authentication and access management',
  'C113': 'Authentication and access management',
  'C114': 'Authentication and access management',
  
  // Security Configurations
  'C120': 'Data encryption and antivirus configurations',
  'C121': 'Data encryption and antivirus configurations',
  'C122': 'Data encryption and antivirus configurations',
  
  // Monitoring & Review
  'C124': 'Code review, IDS, logging, monitoring',
  'C125': 'Code review, IDS, logging, monitoring',
  'C126': 'Code review, IDS, logging, monitoring',
  'C127': 'Code review, IDS, logging, monitoring',
  'C128': 'Code review, IDS, logging, monitoring',
  'C129': 'Code review, IDS, logging, monitoring',
  'C130': 'Code review, IDS, logging, monitoring',
  'C131': 'Code review, IDS, logging, monitoring',
  'C132': 'Code review, IDS, logging, monitoring',
  
  // Change Management
  'C136': 'System changes and emergency management',
  'C137': 'System changes and emergency management',
  'C138': 'System changes and emergency management',
  'C139': 'System changes and emergency management',
  'C140': 'System changes and emergency management',
  'C141': 'System changes and emergency management',
  'C142': 'System changes and emergency management',
  'C143': 'System changes and emergency management',
  'C144': 'System changes and emergency management',
  'C145': 'System changes and emergency management',
  
  // System Management
  'C151': 'Capacity management and backups',
  'C152': 'Capacity management and backups',
  'C153': 'Capacity management and backups',
  'C154': 'Capacity management and backups',
  'C155': 'Capacity management and backups',
  'C156': 'Data backup and testing',
  'C157': 'Data backup and testing',
  'C158': 'Data backup and testing',
  
  // System Processing & Storage
  'C162': 'System processing and data storage controls',
  'C163': 'System processing and data storage controls',
  'C164': 'System processing and data storage controls',
  'C165': 'System processing and data storage controls',
  'C166': 'System processing and data storage controls',
  'C167': 'System processing and data storage controls',
  'C168': 'System processing and data storage controls',
  'C169': 'System processing and data storage controls',
  'C170': 'System processing and data storage controls',
  'C171': 'System processing and data storage controls',
  'C172': 'System processing and data storage controls'
};

// Then combine them into AUTOMATION_CATEGORIES
const AUTOMATION_CATEGORIES = {
  ...ORIGIN63_AUTOMATION_CATEGORIES,
  ...EMERSON_AUTOMATION_CATEGORIES
};

// Update isAutomatedControl function
const isAutomatedControl = (controlId, nowViewingOrgId) => {
  if (nowViewingOrgId === "ItQoFJrUCkOG5NURViN2") {
    return ORIGIN63_CONTROL_IDS.includes(controlId);
  } else if (nowViewingOrgId === "Zjg7OBngxNp35Y4bEMSh") {
    return EMERSON_CONTROL_IDS.includes(controlId);
  }
  return false;
};

const ControlsTable = ({ onOpenDetailView }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedControl, setSelectedControl] = useState(null);
  const [controls, setControls] = useState([]);
  const [filteredControls, setFilteredControls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [unlinkModalOpen, setUnlinkModalOpen] = useState(false);
  const [unlinkReason, setUnlinkReason] = useState('');
  const [controlToUnlink, setControlToUnlink] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [activeTab, setActiveTab] = useState('Linked');
  const [searchTerm, setSearchTerm] = useState(''); 
  const [isEvidenceModalOpen, setEvidenceModalOpen] = useState(false); // Add state for EvidenceModal 
  const [relinkModalOpen, setRelinkModalOpen] = useState(false);
  const [relinkReason, setRelinkReason] = useState('');
  const [controlToRelink, setControlToRelink] = useState(null);

  const { clientId, orgId, nowViewingOrgId, setOrgId } = useGlobalState();

  const baseUrl = process.env.NODE_ENV === 'production'
    ? 'https://godfirst-vayu-app-qravzvgjba-uc.a.run.app'
    : 'http://localhost:8080';

  const fetchControls = useCallback(() => {
    setLoading(true);
    const resolvedClientId = clientId || "Zjg7OBngxNp35Y4bEMSh";

    if (resolvedClientId) {
      try {
        const q = query(
          collection(db, 'client_controls'),
          where('client_id', '==', resolvedClientId)
        );

        const unsubscribe = onSnapshot(q, async (snapshot) => {
          try {
            const clientControls = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));

            const controlDetailsPromises = clientControls.map(async (clientControl) => {
              try {
                const controlId = clientControl.control_id;

                const controlDoc = await getDocs(query(collection(db, 'controls'), where('control_id', '==', controlId)));
                const controlData = controlDoc.empty ? null : controlDoc.docs[0].data();

                if (controlData) {
                  let ownerName = 'Unassigned';
                  let ownerId = clientControl.owner;

                  // Fetch owner's name if owner exists and isn't 'unassigned' or 'needs reassignment'
                  if (clientControl.owner && 
                      clientControl.owner !== 'unassigned' && 
                      clientControl.owner !== 'needs reassignment') {
                    try {
                      const ownerDoc = await getDoc(doc(db, 'users', clientControl.owner));
                      if (ownerDoc.exists()) {
                        const ownerData = ownerDoc.data();
                        ownerName = `${ownerData.fname} ${ownerData.lname}`;
                      }
                    } catch (error) {
                      console.error('Error fetching owner details:', error);
                      // Continue with default owner name
                    }
                  }

                  try {
                    const mappingsSnapshot = await getDocs(query(collection(db, 'control_mappings'), where('control_id', '==', controlId)));
                    const mappings = mappingsSnapshot.docs.map(doc => doc.data());

                    const frameworkIds = [...new Set(mappings.map(mapping => mapping.framework_id))];
                    const domains = [...new Set(
                      mappings
                        .map(mapping => mapping.tsc_domain_mapping)
                        .filter(Boolean)
                        .map(domain => domain.trim())
                        .map(domain => domain.toLowerCase())
                    )]
                    .map(domain => 
                      domain
                        .split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ')
                    );

                    return {
                      ...controlData,
                      control_id: controlId,
                      owner: ownerName,
                      ownerId: ownerId,
                      source: clientControl.source || 'vayu',
                      frameworks: frameworkIds.join(', '),
                      clientId: resolvedClientId,
                      status: clientControl.linked ? 'active' : 'unlinked',
                      completed_approved: clientControl.completed_approved,
                      linked: clientControl.linked,
                      domains: domains.join(', ') || 'N/A',
                    };
                  } catch (error) {
                    console.error('Error fetching control mappings:', error);
                    // Return control data without mappings
                    return {
                      ...controlData,
                      control_id: controlId,
                      owner: ownerName,
                      ownerId: ownerId,
                      source: clientControl.source || 'vayu',
                      frameworks: 'N/A',
                      clientId: resolvedClientId,
                      status: clientControl.linked ? 'active' : 'unlinked',
                      completed_approved: clientControl.completed_approved,
                      linked: clientControl.linked,
                      domains: 'N/A',
                    };
                  }
                }
                return null;
              } catch (error) {
                console.error('Error processing control:', error);
                return null;
              }
            });

            const controlsWithDetails = await Promise.all(controlDetailsPromises);
            const validControls = controlsWithDetails.filter(control => control !== null);
            setControls(validControls);
            setFilteredControls(validControls);
          } catch (error) {
            console.error('Error processing controls:', error);
            setControls([]);
            setFilteredControls([]);
          } finally {
            setLoading(false);
          }
        }, (error) => {
          console.error('Error in snapshot listener:', error);
          setLoading(false);
          setControls([]);
          setFilteredControls([]);
        });

        return () => unsubscribe();
      } catch (error) {
        console.error('Error setting up controls listener:', error);
        setLoading(false);
        setControls([]);
        setFilteredControls([]);
      }
    }
  }, [clientId, orgId]);

  useEffect(() => {
    fetchControls();
  }, [fetchControls]);

  const handleRowClick = async (control) => {
    if (!control.control_id) {
      console.error('Control ID is missing:', control);
      return;
    }

    // If the selected control is already open in the sidebar, close it
    if (selectedControl && selectedControl.control_id === control.control_id && sidebarOpen) {
      setSidebarOpen(false);
      setSelectedControl(null);
      return;
    }

    try {
      const mappingsSnapshot = await getDocs(query(collection(db, 'control_mappings'), where('control_id', '==', control.control_id)));
      const mappings = mappingsSnapshot.docs.map(doc => doc.data());

      const updatedControl = {
        ...control,
        controlMappings: mappings,
        completed_approved: control.completed_approved,
        evidence_satisfied: control.evidence_satisfied,
      };

      setSelectedControl(updatedControl);
      setSidebarOpen(true);
      setEvidenceModalOpen(true);
    } catch (error) {
      console.error('Error fetching control mappings:', error);
    }
  };

  const handleControlUpdate = (updatedControl) => {
    setControls(prevControls =>
      prevControls.map(control =>
        control.control_id === updatedControl.control_id ? {
          ...control,
          owner: updatedControl.owner,
          ownerId: updatedControl.ownerId,
          completed_approved: updatedControl.completed_approved
        } : control
      )
    );
    if (selectedControl && selectedControl.control_id === updatedControl.control_id) {
      setSelectedControl(updatedControl);
    }
  }; 

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
    setSelectedControl(null);
  };

  const handleUnlink = (control) => {
    setControlToUnlink(control);
    setUnlinkModalOpen(true);
  };

  const confirmUnlink = async () => {
    if (controlToUnlink && unlinkReason) {
      try {
        // Get the reference to the client_controls document
        const clientControlsRef = collection(db, 'client_controls');
        const q = query(
          clientControlsRef,
          where('client_id', '==', controlToUnlink.clientId),
          where('control_id', '==', controlToUnlink.control_id)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const docRef = doc(db, 'client_controls', querySnapshot.docs[0].id);
          await updateDoc(docRef, {
            linked: false,
            unlink_reason: unlinkReason
          });

          setFilteredControls(prevControls =>
            prevControls.map(control =>
              control.control_id === controlToUnlink.control_id ? {
                ...control,
                status: 'unlinked'
              } : control
            )
          );
          setUnlinkModalOpen(false);
          setUnlinkReason('');
          setControlToUnlink(null);
          setDropdownOpen(null);
        } else {
          console.error('No matching client_controls document found');
        }
      } catch (error) {
        console.error('Error unlinking control:', error);
      }
    }
  };

  const handleRelink = (control) => {
    setControlToRelink(control);
    setRelinkModalOpen(true);
  };

  const confirmRelink = async () => {
    if (controlToRelink && relinkReason) {
      try {
        // Get the reference to the client_controls document
        const clientControlsRef = collection(db, 'client_controls');
        const q = query(
          clientControlsRef,
          where('client_id', '==', controlToRelink.clientId),
          where('control_id', '==', controlToRelink.control_id)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const docRef = doc(db, 'client_controls', querySnapshot.docs[0].id);
          await updateDoc(docRef, {
            linked: true,
            relink_reason: relinkReason
          });

          // Update the local state
          setControls(prevControls =>
            prevControls.map(control =>
              control.control_id === controlToRelink.control_id
                ? { ...control, linked: true, status: 'active' }
                : control
            )
          );
          setFilteredControls(prevControls =>
            prevControls.map(control =>
              control.control_id === controlToRelink.control_id
                ? { ...control, linked: true, status: 'active' }
                : control
            )
          );

          setRelinkModalOpen(false);
          setRelinkReason('');
          setControlToRelink(null);
        } else {
          console.error('No matching client_controls document found');
        }
      } catch (error) {
        console.error('Error relinking control:', error);
      }
    }
  };

  const toggleDropdown = (index) => {
    if (dropdownOpen === index) {
      setDropdownOpen(null);
    } else {
      setDropdownOpen(index);
    }
  };

  const linkedControls = useMemo(() =>
    filteredControls.filter(control => control.linked === true),
    [filteredControls]
  );

  const unlinkedControls = useMemo(() =>
    filteredControls.filter(control => control.linked === false),
    [filteredControls]
  );

  const handleSearch = useCallback((e) => {
    setSearchTerm(e.target.value);
    const lowerCaseSearchTerm = e.target.value.toLowerCase();
    const filtered = controls.filter(control =>
      control.friendly_control_name.toLowerCase().includes(lowerCaseSearchTerm) ||
      control.owner.toLowerCase().includes(lowerCaseSearchTerm) ||
      control.source.toLowerCase().includes(lowerCaseSearchTerm) ||
      (control.frameworks && control.frameworks.toLowerCase().includes(lowerCaseSearchTerm))
    );
    setFilteredControls(filtered);
  }, [controls]);

  return (
    <div className="controls-table-container">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="tabs">
            <button
              className={`tab-button ${activeTab === 'Linked' ? 'active' : ''}`}
              onClick={() => setActiveTab('Linked')}
            >
              Linked
            </button>
            <button
              className={`tab-button ${activeTab === 'Unlinked' ? 'active' : ''}`}
              onClick={() => setActiveTab('Unlinked')}
            >
              Unlinked
            </button>
          </div>

          <div className="search-bar">
            <input
              type="text"
              placeholder="Search controls..."
              value={searchTerm}
              onChange={handleSearch}
              className="search-input"
            />
          </div>

          {activeTab === 'Linked' && (
            <table className="controls-table">
              <thead>
                <tr>
                  <th>ACTIONS</th>
                  <th>ID</th>
                  <th>CONTROL</th>
                  <th>OWNER</th>
                  <th>SOURCE</th>
                  <th>FRAMEWORK</th>
                  <th>DOMAIN(S)</th>
                  <th>MONITORS</th>
                  <th>EVIDENCE APPROVED</th>
                </tr>
              </thead>
              <tbody>
                {linkedControls.map((control, index) => (
                  <tr key={index}>
                    <td style={{ backgroundColor: "#1a1e2e" }}>
                      <div className="actions-menu" style={{ textAlign: "center" }}>
                        <button onClick={() => toggleDropdown(index)} style={{
                          textAlign: "center",
                          background: "transparent",
                          border: "none",
                          color: "#00ffff",
                          fontSize: "20px",
                          fontWeight: "bold",
                          cursor: "pointer",
                          transition: "transform 0.2s ease-in-out",
                          textShadow: "0px 0px 8px rgba(0, 255, 255, 1)",
                        }}
                          onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.2)"}
                          onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1.0)"}>
                          {dropdownOpen === index ? '🔓' : '🔒'}
                        </button>
                        {dropdownOpen === index && (
                          <div className="dropdown-menu">
                            <button onClick={() => handleUnlink(control)} style={{
                              textAlign: "center",
                              background: "linear-gradient(45deg, #0f0c29, #302b63, #24243e)",
                              borderRadius: "8px",
                              padding: "8px",
                              boxShadow: "0px 0px 10px rgba(0, 255, 255, 0.5)",
                              color: "#00ffff",
                              transition: "transform 0.2s ease-in-out",
                              cursor: "pointer"
                            }}
                              onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.1)"}
                              onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1.0)"}>Unlink</button>
                          </div>
                        )}
                      </div>
                    </td>
                    <td>{control.control_id}</td>
                    <td onClick={() => handleRowClick(control)}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {control.friendly_control_name}
                        {console.log('Rendering control:', {
                          controlId: control.control_id,
                          nowViewingOrgId,
                          hasAutomation: isAutomatedControl(control.control_id, nowViewingOrgId)
                        })}
                        {isAutomatedControl(control.control_id, nowViewingOrgId) && (
                          <div 
                            className="automation-indicator"
                            style={{ 
                              marginLeft: '8px',
                              cursor: 'pointer',
                              position: 'relative',
                              display: 'inline-block'
                            }}
                          >
                            <span 
                              role="img" 
                              aria-label="robot"
                              style={{
                                fontSize: '16px',
                                color: '#00ffff',
                                textShadow: '0 0 5px rgba(0, 255, 255, 0.5)'
                              }}
                            >
                              🤖
                            </span>
                            <div 
                              className="automation-tooltip"
                              style={{
                                display: 'none',
                                position: 'absolute',
                                background: 'rgba(0, 0, 0, 0.9)',
                                color: '#00ffff',
                                padding: '10px',
                                borderRadius: '5px',
                                width: '250px',
                                top: '100%',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                zIndex: 1000,
                                border: '1px solid #00ffff',
                                boxShadow: '0 0 10px rgba(0, 255, 255, 0.3)'
                              }}
                            >
                              {AUTOMATION_CATEGORIES[control.control_id]}
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                    <td>{control.owner}</td>
                    <td>{control.source}</td>
                    <td>
                      {control.frameworks ? (
                        control.frameworks.split(', ').map((framework, i) => (
                          <span key={i} className="framework-badge">{framework}</span>
                        ))
                      ) : (
                        <span className="framework-badge">N/A</span>
                      )}
                    </td>
                    <td>
                      {control.domains ? (
                        control.domains.split(', ').map((domain, i) => (
                          <span key={i} className="framework-badge">{domain}</span>
                        ))
                      ) : (
                        <span className="framework-badge">N/A</span>
                      )}
                    </td>
                    <td><span className="monitor-status">Pending Integration Setup</span></td>
                    <td style={{ backgroundColor: control.completed_approved ? '#d4edda' : 'transparent', textAlign: 'center' }}>
                      {control.completed_approved ? 'Yes' : 'No'}
                    </td>                  
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {activeTab === 'Unlinked' && (
            <table className="controls-table">
              <thead>
                <tr>
                  <th>ACTIONS</th>
                  <th>ID</th>
                  <th>CONTROL</th>
                  <th>OWNER</th>
                  <th>SOURCE</th>
                  <th>FRAMEWORK</th>
                  <th>DOMAIN(S)</th>
                  <th>MONITORS</th>
                </tr>
              </thead>
              <tbody>
                {unlinkedControls.map((control, index) => (
                  <tr key={index}>
                    <td>
                      <div className="actions-menu" style={{ textAlign: "center" }}>
                        <button onClick={() => handleRelink(control)} style={{
                          textAlign: "center",
                          background: "linear-gradient(45deg, #0f0c29, #302b63, #24243e)",
                          borderRadius: "8px",
                          padding: "8px",
                          boxShadow: "0px 0px 10px rgba(0, 255, 255, 0.5)",
                          color: "#00ffff",
                          transition: "transform 0.2s ease-in-out",
                          cursor: "pointer"
                        }}
                          onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.1)"}
                          onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1.0)"}>Relink</button>
                      </div>
                    </td>
                    <td>{control.control_id}</td>
                    <td onClick={() => handleRowClick(control)}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {control.friendly_control_name}
                        {isAutomatedControl(control.control_id, nowViewingOrgId) && (
                          <div 
                            className="automation-indicator"
                            style={{ 
                              marginLeft: '8px',
                              cursor: 'pointer',
                              position: 'relative',
                              display: 'inline-block'
                            }}
                          >
                            <span 
                              role="img" 
                              aria-label="robot"
                              style={{
                                fontSize: '16px',
                                color: '#00ffff',
                                textShadow: '0 0 5px rgba(0, 255, 255, 0.5)'
                              }}
                            >
                              🤖
                            </span>
                            <div 
                              className="automation-tooltip"
                              style={{
                                display: 'none',
                                position: 'absolute',
                                background: 'rgba(0, 0, 0, 0.9)',
                                color: '#00ffff',
                                padding: '10px',
                                borderRadius: '5px',
                                width: '250px',
                                top: '100%',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                zIndex: 1000,
                                border: '1px solid #00ffff',
                                boxShadow: '0 0 10px rgba(0, 255, 255, 0.3)'
                              }}
                            >
                              {AUTOMATION_CATEGORIES[control.control_id]}
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                    <td>{control.owner}</td>
                    <td>{control.source}</td>
                    <td>
                      {control.frameworks ? (
                        control.frameworks.split(', ').map((framework, i) => (
                          <span key={i} className="framework-badge">{framework}</span>
                        ))
                      ) : (
                        <span className="framework-badge">N/A</span>
                      )}
                    </td>
                    <td>
                      {control.domains ? (
                        control.domains.split(', ').map((domain, i) => (
                          <span key={i} className="framework-badge">{domain}</span>
                        ))
                      ) : (
                        <span className="framework-badge">N/A</span>
                      )}
                    </td>
                    <td><span className="monitor-status">Pending Integration Setup</span></td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <UnlinkControlModal
            isOpen={unlinkModalOpen}
            onClose={() => setUnlinkModalOpen(false)}
            onUnlink={confirmUnlink}
            unlinkReason={unlinkReason}
            setUnlinkReason={setUnlinkReason}
            setDropdownOpen={setDropdownOpen}
          />
          <RelinkControlModal
            isOpen={relinkModalOpen}
            onClose={() => setRelinkModalOpen(false)}
            onRelink={confirmRelink}
            relinkReason={relinkReason}
            setRelinkReason={setRelinkReason}
          />
        </>
      )} 

      <ControlDetailsSidebar
        isOpen={sidebarOpen}
        onClose={handleCloseSidebar}
        controlDetails={selectedControl}
        onControlUpdate={handleControlUpdate}
        onOpenDetailView={onOpenDetailView}
        fetchControls={fetchControls}
      />
    </div>
  );
};

export default ControlsTable; 
