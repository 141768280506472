import { auth, db } from '../firebase';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, query, where } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// Add functions to manage frameworks
export const fetchFrameworks = async () => {
  try {
    const frameworksSnapshot = await getDocs(collection(db, 'v2_frameworks'));
    const frameworks = {};
    frameworksSnapshot.docs.forEach(doc => {
      frameworks[doc.id] = {
        ...doc.data(),
        firebaseId: doc.id // Store Firebase doc ID for updates/deletes
      };
    });
    return frameworks;
  } catch (error) {
    console.error('Error fetching frameworks:', error);
    throw error;
  }
};

export const addFramework = async (framework) => {
  try {
    const docRef = await addDoc(collection(db, 'v2_frameworks'), {
      name: framework.name,
      description: framework.description,
      issuer: framework.issuer,
      version: framework.version,
      createdAt: new Date(),
      updatedAt: new Date()
    });
    return docRef.id;
  } catch (error) {
    console.error('Error adding framework:', error);
    throw error;
  }
};

export const updateFramework = async (frameworkId, updates) => {
  try {
    const frameworkRef = doc(db, 'v2_frameworks', frameworkId);
    await updateDoc(frameworkRef, {
      ...updates,
      updatedAt: new Date()
    });
  } catch (error) {
    console.error('Error updating framework:', error);
    throw error;
  }
};

export const deleteFramework = async (frameworkId) => {
  try {
    await deleteDoc(doc(db, 'v2_frameworks', frameworkId));
  } catch (error) {
    console.error('Error deleting framework:', error);
    throw error;
  }
};

// Add functions to save to respective collections
export const saveFrameworkMapping = async (mapping) => {
  const { framework, universal } = mapping;
  
  // Save framework-specific data
  await Promise.all([
    saveToCollection('v2_framework_controls', framework.controls),
    saveToCollection('v2_framework_categories', framework.categories),
    saveToCollection('v2_framework_criteria', framework.criteria)
  ]);
  
  // Save or update universal mappings
  await Promise.all([
    updateCollection('v2_foundational_controls', universal.controls),
    updateCollection('v2_foundational_categories', universal.categories),
    updateCollection('v2_foundational_criteria', universal.criteria)
  ]);
};

const saveToCollection = async (collection, data) => {
  const batch = db.batch();
  data.forEach(item => {
    const ref = db.collection(collection).doc();
    batch.set(ref, item);
  });
  await batch.commit();
};

const updateCollection = async (collection, data) => {
  const batch = db.batch();
  data.forEach(item => {
    const ref = db.collection(collection).doc(item.id);
    batch.set(ref, item, { merge: true });
  });
  await batch.commit();
};

// Add function to fetch framework-specific data
export const fetchFrameworkControls = async (frameworkSlug) => {
  try {
    // Get all controls for this framework
    const controlsSnapshot = await getDocs(collection(db, `v2_framework_controls`));
    const controls = [];
    
    for (const controlDoc of controlsSnapshot.docs) {
      const control = { id: controlDoc.id, ...controlDoc.data() };
      
      // Get categories for this control
      const categoriesSnapshot = await getDocs(
        query(
          collection(db, 'v2_framework_categories'),
          where('control_id', '==', control.id),
          where('framework_slug', '==', frameworkSlug)
        )
      );
      
      control.categories = await Promise.all(
        categoriesSnapshot.docs.map(async (categoryDoc) => {
          const category = { id: categoryDoc.id, ...categoryDoc.data() };
          
          // Get criteria for this category
          const criteriaSnapshot = await getDocs(
            query(
              collection(db, 'v2_framework_criteria'),
              where('category_id', '==', category.id),
              where('framework_slug', '==', frameworkSlug)
            )
          );
          
          category.criteria = criteriaSnapshot.docs.map(criteriaDoc => ({
            id: criteriaDoc.id,
            ...criteriaDoc.data()
          }));
          
          return category;
        })
      );
      
      controls.push(control);
    }
    
    return controls;
  } catch (error) {
    console.error('Error fetching framework controls:', error);
    throw error;
  }
};

// Add this function to handle PDF uploads
export const uploadPDFToStorage = async (file) => {
  const storage = getStorage();
  const pdfRef = ref(storage, `framework-pdfs/${Date.now()}-${file.name}`);
  
  try {
    const snapshot = await uploadBytes(pdfRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  } catch (error) {
    console.error('Error uploading PDF:', error);
    throw new Error('Failed to upload PDF');
  }
};