import React from 'react';

const About = () => {
  return (
    <div className="about">
      <div className="about-content">
        <h1>About Vayu</h1>
        <p>
          Vayu is a powerful platform designed to streamline and enhance your workflow management experience.
        </p>
      </div>
    </div>
  );
};

export default About; 