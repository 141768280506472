import React, { useState } from 'react';
import './HeroSection.css';
import ScheduleDemoModal from './ScheduleDemoModal';

const HeroSection = () => {
  const handleClick = () => {
    alert('Modal opened!'); // Placeholder for modal logic
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <section className="hero">
      <div className="hero-title-container">
        <h1 className="hero-title">
          Meet your AI Security Workforce
        </h1>
      </div>
      <div className="hero-subtitle-container">
        <p className="hero-subtitle">
        Stay ahead of regulatory changes and accelerate compliance with Vayu's integrated intelligence platform.
        </p>
      </div>
      <button className="btn-hero" onClick={toggleModal}>
        Schedule a demo
      </button>
      <ScheduleDemoModal isOpen={isModalOpen} onClose={toggleModal} />
    </section>
  );
};

export default HeroSection;
