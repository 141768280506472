import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Login from './components/Login';
import SignUp from './components/SignUp';
import AboutUs from './components/AboutUs';
import Blog from './components/Blog';
import SuperSearch from './components/SuperSearch';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const MainLayout = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authInitialized, setAuthInitialized] = useState(false);
  const location = useLocation();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log('Auth state changed:', user ? 'User logged in' : 'User logged out');
      setUser(user);
      setLoading(false);
      setAuthInitialized(true);
    });

    return () => unsubscribe();
  }, [auth]);

  // Helper function to check if user is authenticated and non-anonymous
  const isNonAnonymousUser = user?.uid != null && !user?.isAnonymous;

  // Show loading state only during initial auth check
  if (!authInitialized) {
    return <div>Loading...</div>;
  }

  const renderWithLayout = (Component) => {
    return (
      <>
        <Header isLoggedIn={isNonAnonymousUser} />
        <main className="main-content">
          <Component />
        </main>
        <Footer />
      </>
    );
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={renderWithLayout(Home)} />
        <Route path="/about" element={renderWithLayout(AboutUs)} />
        <Route path="/blog" element={renderWithLayout(Blog)} />
        <Route 
          path="/login" 
          element={
            loading ? (
              <div>Loading...</div>
            ) : isNonAnonymousUser ? (
              <Navigate to="/dashboard" replace />
            ) : (
              renderWithLayout(Login)
            )
          } 
        />
        <Route 
          path="/signup" 
          element={
            loading ? (
              <div>Loading...</div>
            ) : isNonAnonymousUser ? (
              <Navigate to="/dashboard" replace />
            ) : (
              renderWithLayout(SignUp)
            )
          } 
        />
        {/* Catch all route - redirect to login if not authenticated */}
        <Route 
          path="*" 
          element={
            loading ? (
              <div>Loading...</div>
            ) : isNonAnonymousUser ? (
              <Navigate to="/dashboard" replace />
            ) : (
              <Navigate to="/login" replace state={{ from: location }} />
            )
          }
        />
      </Routes>
      <SuperSearch />
    </div>
  );
};

export default MainLayout;
