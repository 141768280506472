import React from 'react';
import MainContent from './MainContent';

const Home = () => {
  return (
    <div className="home">
      <MainContent />
    </div>
  );
};

export default Home;