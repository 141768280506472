import React, { useEffect, useRef, useState } from 'react';
import './UpdatedResultsWindow.css';
import IntegrationsView from './IntegrationsView';

const ResultItem = ({ result, isInitiallyExpanded = false }) => {
  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);
  const contentRef = useRef(null);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const formatDescription = (description) => {
    if (typeof description === 'string') {
      const points = description.split(/(\d+\.\s)/);
      if (points.length > 1) {
        return (
          <div className="formatted-description">
            {points.map((point, index) => {
              if (/^\d+\.\s/.test(point)) {
                return <strong key={index} className="point-number">{point}</strong>;
              }
              return <span key={index}>{point}</span>;
            })}
          </div>
        );
      }
      return description;
    }
    
    try {
      return JSON.stringify(description, null, 2);
    } catch {
      return String(description);
    }
  };

  const renderMetadata = (metadata) => {
    if (!metadata) return null;
    
    return (
      <div className="result-metadata">
        {metadata.timestamp && (
          <span className="metadata-timestamp">
            {new Date(metadata.timestamp).toLocaleString()}
          </span>
        )}
        {metadata.tools && metadata.tools.length > 0 && (
          <div className="metadata-tools">
            Tools: {metadata.tools.join(', ')}
          </div>
        )}
        {metadata.query && (
          <div className="metadata-query">
            Query: "{metadata.query}"
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`result-item ${result.type}`}>
      <div className="result-header" onClick={toggleExpand}>
        <div className="result-header-content">
          <div className="result-type">{result.type === 'llm_response' ? 'AI Response' : result.type}</div>
          <h4 className="result-title">{result.title}</h4>
        </div>
        <span className={`result-collapse-icon ${isExpanded ? 'expanded' : ''}`}>
          ▼
        </span>
      </div>
      <div 
        className={`result-content ${isExpanded ? 'expanded' : ''}`}
        ref={contentRef}
      >
        <div className={`result-description ${result.type}-description`}>
          {formatDescription(result.description)}
        </div>
        {renderMetadata(result.metadata)}
      </div>
    </div>
  );
};

const DifyEmbed = () => (
  <div className="dify-embed-container">
    <iframe
      src="https://udify.app/chatbot/0TNhPDHjGVbUMtyy"
      style={{ width: '100%', height: '100%', minHeight: '700px' }}
      frameBorder="0"
      allow="microphone"
      title="Dify Chat"
    />
  </div>
);

const UpdatedResultsWindow = ({ 
  isVisible, 
  onClose, 
  content, 
  searchAction,
  isLoading,
  processingStage,
  searchResult,
  stepResults,
  onSendMessage,
  now_viewing_org_id
}) => {
  const [streamedContent, setStreamedContent] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const messagesEndRef = useRef(null);
  const [newMessage, setNewMessage] = useState('');
  const inputRef = useRef(null);

  // Scroll to bottom when new messages arrive
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory, streamedContent]);

  // Handle streaming updates
  useEffect(() => {
    if (stepResults && stepResults.length > 0) {
      const lastStep = stepResults[stepResults.length - 1];
      
      if (lastStep.type === 'llm_response') {
        if (lastStep.metadata?.stage === 'generating') {
          setStreamedContent(prev => prev + lastStep.description);
        } else if (lastStep.metadata?.stage === 'completed') {
          setStreamedContent(lastStep.description);
          // Add completed message to chat history
          setChatHistory(prev => [...prev, {
            type: 'assistant',
            content: lastStep.description,
            metadata: lastStep.metadata
          }]);
          // Clear streamed content for next message
          setStreamedContent('');
        }
      }
    }
  }, [stepResults]);

  // Focus input when chat opens
  useEffect(() => {
    if (isVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isVisible]);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (newMessage.trim() && !isLoading) {
      // Add user message to chat history
      setChatHistory(prev => [...prev, {
        type: 'user',
        content: newMessage,
        metadata: {
          timestamp: new Date().toISOString()
        }
      }]);
      
      // Send message
      onSendMessage(newMessage);
      setNewMessage('');
    }
  };

  const renderContent = () => {
    if (typeof content === 'object') {
      return content;
    }

    // Show Dify embed for agent button
    if (content === 'agent') {
      return <DifyEmbed />;
    }

    // Show Integrations view
    if (content === 'Integrations') {
      return <IntegrationsView 
        onClose={onClose}
        now_viewing_org_id={now_viewing_org_id}
      />;
    }

    if (content === 'Search') {
      return (
        <div className="results-content">
          {/* Chat History */}
          <div className="chat-history">
            {chatHistory.map((message, index) => (
              <div key={index} className={`chat-message ${message.type}`}>
                <div className="message-content">
                  {message.content}
                </div>
                {message.metadata && (
                  <div className="message-metadata">
                    <small>
                      {new Date(message.metadata.timestamp).toLocaleString()}
                    </small>
                  </div>
                )}
              </div>
            ))}
            
            {/* Streaming Content */}
            {streamedContent && (
              <div className="chat-message assistant streaming">
                <div className="message-content">
                  {streamedContent}
                </div>
              </div>
            )}
            
            {/* Loading Indicator */}
            {isLoading && (
              <div className="loading-state">
                <div className="loading-spinner"></div>
                <p>Processing your query...</p>
              </div>
            )}
            
            <div ref={messagesEndRef} />
          </div>

          {/* Chat Input */}
          <form onSubmit={handleSendMessage} className="chat-input-container">
            <input
              ref={inputRef}
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Type your message..."
              disabled={isLoading}
              className="chat-input"
            />
            <button 
              type="submit" 
              disabled={isLoading || !newMessage.trim()}
              className="chat-send-button"
            >
              Send
            </button>
          </form>

          {/* Only show error results */}
          {searchResult?.filter(result => result.type === 'error').map((result, index) => (
            <div key={index} className={`result-item ${result.type}`}>
              <h3>{result.title}</h3>
              <div className="result-content">
                {result.description}
              </div>
              {result.metadata && (
                <div className="result-metadata">
                  <small>
                    {new Date(result.metadata.timestamp).toLocaleString()}
                    {result.metadata.error && (
                      <span className="error-details">
                        {' • '}
                        {result.metadata.error}
                      </span>
                    )}
                  </small>
                </div>
              )}
            </div>
          ))}
        </div>
      );
    }

    return content;
  };

  const windowRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (windowRef.current && !windowRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible, onClose]);

  const renderLoadingState = () => (
    <div className="loader">
      <div className="spinner"></div>
      <p>
        {processingStage === 'checking_tools' && 'Checking available tools...'}
        {processingStage === 'processing_query' && 'Processing your query...'}
        {!processingStage && 'Processing your request...'}
      </p>
    </div>
  );

  const renderSearchResult = () => {
    if (!searchResult) return null;
    
    return (
      <div className="search-result">
        {searchAction && (
          <div className="search-action-indicator">
            Search triggered by: {searchAction === 'keyboard' ? 'Enter key' : 'Search button'}
          </div>
        )}
        
        {Array.isArray(searchResult) ? (
          <div className="results-list">
            {searchResult.map((result, index) => (
              <ResultItem 
                key={index} 
                result={result}
                isInitiallyExpanded={index === 0} // First item expanded by default
              />
            ))}
          </div>
        ) : (
          <ResultItem result={searchResult} isInitiallyExpanded={true} />
        )}
      </div>
    );
  };

  const renderProgressResults = () => {
    if (!stepResults?.length) return null;

    return (
      <div className="step-results">
        {stepResults.map((result, index) => (
          <ResultItem 
            key={index} 
            result={result}
            isInitiallyExpanded={index === stepResults.length - 1} // Latest result expanded
          />
        ))}
      </div>
    );
  };

  if (!isVisible) return null;

  return (
    <div className="updated-results-window">
      <div 
        className={`updated-results-content ${
          content?.type?.includes?.('MicrosoftAzureConfiguration') ? 'showing-config' : ''
        }`}
        data-view={content === 'agent' ? 'agent' : ''}
        ref={windowRef}
      >
        <h3>
          {content === 'Search' 
            ? `Search Results ${searchAction ? `(${searchAction === 'keyboard' ? 'Enter key' : 'Search button'})` : ''}`
            : typeof content === 'object' && React.isValidElement(content) 
              ? 'Integrations'
              : content === 'agent' 
                ? <span className="secure-chat-title">🔒 Secure Chat</span> 
                : `${content}`}
        </h3>
        {renderContent()}
      </div>
    </div>
  );
};

export default UpdatedResultsWindow;
