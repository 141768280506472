import React, { useState, useEffect, useCallback } from 'react';
import { AiFillDelete, AiFillTags, AiFillEye } from 'react-icons/ai';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Modal, Box } from '@mui/material';
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, collection, query, where, getDocs, addDoc, updateDoc, deleteDoc, getDoc, setDoc } from "firebase/firestore";
import { useGlobalState } from '../context/GlobalStateContext';
import './DocumentTable.css'; 
import AuditMaterialComponent from './AuditMaterialComponent';
import { db } from '../firebase';

const storage = getStorage();

const CONTROL_GROUPS = {
  'Access Control & Authentication': [
    'C086', 'C089', 'C096', 'C097', 'C104', 'C105', 'C107', 'C108'
  ],
  'Encryption Policies': [
    'C093', 'C100', 'C102', 'C120', 'C123'
  ],
  'Incident Management': [
    'C087', 'C132', 'C133', 'C134', 'C135', 'C136'
  ],
  'System Monitoring': [
    'C074', 'C091', 'C127', 'C130'
  ],
  'Change Management': [
    'C090', 'C139', 'C140', 'C141', 'C142', 'C145'
  ],
  'Backup & Recovery': [
    'C088', 'C153', 'C154', 'C155', 'C156', 'C157'
  ],
  'Malware Protection': [
    'C121', 'C122', 'C125'
  ],
  'Asset Management': [
    'C095', 'C103', 'C112'
  ]
};

const DocumentTable = () => {
  const { clientId, orgId, setOrgId, setClientId, nowViewingOrgId } = useGlobalState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [controlOptions, setControlOptions] = useState([]);
  const [frameworkOptions, setFrameworkOptions] = useState([]);
  const [badgeMetadata, setBadgeMetadata] = useState({});
  const [descriptionModalOpen, setDescriptionModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [fileToUpload, setFileToUpload] = useState(null); 
  const [categoryFilter, setCategoryFilter] = useState('All'); // New state for filter 
  const [activeTab, setActiveTab] = useState('Client Documents'); // New state for active tab  
  const [isEditMetadataModalOpen, setIsEditMetadataModalOpen] = useState(false); // New state for Edit Metadata modal  
  const [isEditFileNameModalOpen, setIsEditFileNameModalOpen] = useState(false); 
  const [emailDomain, setEmailDomain] = useState('');
  const [selectedControlGroup, setSelectedControlGroup] = useState(null);
  const [satisfyAllControls, setSatisfyAllControls] = useState(false);
  const [selectedControls, setSelectedControls] = useState([]);
  const [existingEvidence, setExistingEvidence] = useState({});
  const [controlDetails, setControlDetails] = useState({});
  const [completedControls, setCompletedControls] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [quickUploadControl, setQuickUploadControl] = useState(null);
  const [quickUploadGroup, setQuickUploadGroup] = useState(null);
  const [evidenceDescription, setEvidenceDescription] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const fetchUserOrgId = async (email) => {
    console.log('fetchUserOrgId called');
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('email', '==', email));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        return querySnapshot.docs[0].data().orgId;
      }
    } catch (error) {
      console.error('Error fetching user orgId:', error);
    }
    return null;
  };

  const fetchOwnerName = async (userId, scope) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        return `${userData.fname} ${userData.lname}`;
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
    return scope === 'client' ? 'AssetTrack' : 'BD Emerson';
  };

  const filteredFiles = Object.entries(uploadedFiles).reduce((acc, [groupName, controls]) => {
    // Filter controls based on the search query
    const filteredControls = controls.filter(control => {
      const query = searchQuery.toLowerCase();
      const nameMatch = (control.control_name?.toLowerCase() || '').includes(query);
      const idMatch = (control.control_id?.toLowerCase() || '').includes(query);
      const descriptionMatch = (control.control_description?.toLowerCase() || '').includes(query);
      const evidenceMatch = control.evidence?.some(evidence => 
        (evidence.evidence_description?.toLowerCase() || '').includes(query)
      );

      return nameMatch || idMatch || descriptionMatch || evidenceMatch;
    });

    // Only include groups that have matching controls
    if (filteredControls.length > 0) {
      acc[groupName] = filteredControls;
    }

    return acc;
  }, {});

  const fetchDocuments = useCallback(async () => {
    if (!nowViewingOrgId) return;
    setIsLoading(true);

    try {
      // Batch all initial queries in parallel
      const [evidenceSnapshot, controlsSnapshot, clientControlsSnapshot] = await Promise.all([
        // Fetch all evidence for this org in one query
        getDocs(query(
          collection(db, 'evidence'),
          where('org_id', '==', nowViewingOrgId)
        )),

        // Fetch all controls in one query
        getDocs(collection(db, 'controls')),

        // Fetch all client controls for this org in one query
        getDocs(query(
          collection(db, 'client_controls'),
          where('client_id', '==', nowViewingOrgId)
        ))
      ]);

      // Create lookup maps for faster access
      const evidenceMap = {};
      evidenceSnapshot.docs.forEach(doc => {
        const evidence = { id: doc.id, ...doc.data() };
        if (!evidenceMap[evidence.control_id]) {
          evidenceMap[evidence.control_id] = [];
        }
        evidenceMap[evidence.control_id].push(evidence);
      });

      const controlDetailsMap = {};
      controlsSnapshot.docs.forEach(doc => {
        controlDetailsMap[doc.id] = doc.data();
      });

      const completedStatusMap = {};
      clientControlsSnapshot.docs.forEach(doc => {
        const controlId = doc.id.split('_')[1]; // Extract control ID from document ID
        completedStatusMap[controlId] = doc.data().completed_approved || false;
      });

      // Process all groups and controls
      const evidenceByGroup = Object.entries(CONTROL_GROUPS).reduce((acc, [groupName, controls]) => {
        acc[groupName] = controls.map(controlId => {
          const hasEvidence = evidenceMap[controlId]?.length > 0;
          const isMarkedComplete = completedStatusMap[controlId];
          const isCompleted = hasEvidence && isMarkedComplete;

          return {
            control_id: controlId,
            control_name: controlDetailsMap[controlId]?.friendly_control_name || controlId,
            control_description: controlDetailsMap[controlId]?.control_description || '',
            isCompleted,
            evidence: evidenceMap[controlId] || []
          };
        });
        return acc;
      }, {});

      setUploadedFiles(evidenceByGroup);
    } catch (error) {
      console.error('Error fetching evidence:', error);
    } finally {
      setIsLoading(false);
    }
  }, [nowViewingOrgId]);

  useEffect(() => {
    if (nowViewingOrgId) {
      console.log('Fetching documents for org:', nowViewingOrgId); // Debug log
      fetchDocuments();
    }
  }, [nowViewingOrgId, fetchDocuments]);

  const fetchControlAndFrameworkOptions = async () => {
    try {
      const controlsRef = collection(db, 'controls');
      const controlsSnapshot = await getDocs(controlsRef);
      const controlOptions = controlsSnapshot.docs.map(doc => ({
        control_id: doc.id,
        friendly_control_name: doc.data().friendly_control_name,
      })).sort((a, b) => a.friendly_control_name.localeCompare(b.friendly_control_name));

      const frameworksRef = collection(db, 'frameworks');
      const frameworksSnapshot = await getDocs(frameworksRef);
      const frameworkOptions = frameworksSnapshot.docs.map(doc => doc.data().framework_name || doc.id);

      setControlOptions(controlOptions);
      setFrameworkOptions(frameworkOptions);
    } catch (error) {
      console.error('Error fetching Control and Framework options:', error);
    }
  };

  const fetchAllBadgeMetadata = async () => {
    try {
      const controlsRef = collection(db, 'controls');
      const controlsSnapshot = await getDocs(controlsRef);
      const metadataMap = controlsSnapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data();
        return acc;
      }, {});

      setBadgeMetadata(metadataMap);
    } catch (error) {
      console.error('Error fetching badge metadata:', error);
    }
  };

  const handleFileUpload = async (file) => {
    if (!file || !nowViewingOrgId) return;
    setIsUploading(true); // Start loading

    try {
      // Create a clean filename (remove special characters and spaces)
      const cleanFileName = file.name.replace(/[^a-zA-Z0-9.]/g, '_');
      const timestamp = Date.now();
      const uniqueFileName = `${timestamp}_${cleanFileName}`;
      
      const fileRef = storageRef(storage, `evidence/${nowViewingOrgId}/${uniqueFileName}`);
      const uploadTask = uploadBytesResumable(fileRef, file);
      
      uploadTask.on('state_changed', 
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          console.error('Error uploading file:', error);
          setIsUploading(false); // Stop loading on error
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          
          // Handle selected controls
          const controlsToUpdate = satisfyAllControls ? 
            CONTROL_GROUPS[selectedControlGroup] : 
            selectedControls;

          // Create evidence entries for each selected control
          for (const controlId of controlsToUpdate) {
            const evidenceData = {
              control_id: controlId,
              org_id: nowViewingOrgId,
              evidence_description: evidenceDescription || `Evidence for ${controlId}`,
              evidence_id: uniqueFileName,
              evidence_type: file.type,
              evidence_url: downloadURL,
              uploaded_at: new Date().toISOString(),
              uploaded_by: getAuth().currentUser.uid,
            };
            
            // Add evidence document
            await addDoc(collection(db, 'evidence'), evidenceData);
            
            // Create or update client_controls document
            const clientControlRef = doc(db, 'client_controls', `${nowViewingOrgId}_${controlId}`);
            const clientControlDoc = await getDoc(clientControlRef);
            
            if (!clientControlDoc.exists()) {
              // Create new client_controls document if it doesn't exist
              await setDoc(clientControlRef, {
                client_id: nowViewingOrgId,
                control_id: controlId,
                completed_approved: true,
                updated_at: new Date().toISOString(),
                linked: true,
                owner: "unassigned",
                source: "Vayu",
                status: "active"
              });
            } else {
              // Update existing document
              await updateDoc(clientControlRef, {
                completed_approved: true,
                updated_at: new Date().toISOString()
              });
            }
          }
          
          // Emit event to update completion stats
          window.dispatchEvent(new CustomEvent('completionStatsUpdated', {
            detail: { orgId: nowViewingOrgId }
          }));
          
          await fetchDocuments(); // Refresh the view
          setProgress(0);
          setFileToUpload(null);
          setEvidenceDescription('');
          setIsModalOpen(false);
          setIsUploading(false); // Stop loading after success
        }
      );
    } catch (error) {
      console.error('File upload error:', error);
      setIsUploading(false); // Stop loading on error
    }
  };

  const handleDeleteClick = (evidence) => {
    setFileToDelete({
      id: evidence.id,
      evidence_url: evidence.evidence_url,
      evidence_id: evidence.evidence_id,
      control_id: evidence.control_id
    });
    setIsDeleteModalOpen(true);
  };

  const handleSaveFileName = async () => {
    try {
      const docRef = doc(db, 'documents', selectedFile.id);
      await updateDoc(docRef, {
        friendly_name: selectedFile.friendlyName
      });
      fetchDocuments();
      setIsEditFileNameModalOpen(false);
    } catch (error) {
      console.error('Error updating file name:', error);
    }
  };

  const confirmDelete = async () => {
    if (!fileToDelete) return;

    try {
      // Delete the evidence document from Firestore first
      await deleteDoc(doc(db, 'evidence', fileToDelete.id));
      
      // Delete the file from Storage if URL exists
      if (fileToDelete.evidence_url) {
        try {
          // Construct the correct storage path
          const storagePath = `evidence/${nowViewingOrgId}/${fileToDelete.evidence_id}`;
          const fileRef = storageRef(storage, storagePath);
          await deleteObject(fileRef);
        } catch (storageError) {
          console.warn('Storage file not found or already deleted:', storageError);
        }
      }

      // Check remaining evidence for this control AFTER deleting the current evidence
      const evidenceQuery = query(
        collection(db, 'evidence'),
        where('org_id', '==', nowViewingOrgId),
        where('control_id', '==', fileToDelete.control_id)
      );
      
      const remainingEvidenceSnapshot = await getDocs(evidenceQuery);
      const remainingEvidenceCount = remainingEvidenceSnapshot.docs.length;

      // Update control status if no evidence remains
      if (remainingEvidenceCount === 0) {
        const clientControlRef = doc(db, 'client_controls', `${nowViewingOrgId}_${fileToDelete.control_id}`);
        await updateDoc(clientControlRef, {
          completed_approved: false,
          updated_at: new Date().toISOString()
        });

        // Update local state to reflect the change
        setCompletedControls(prev => ({
          ...prev,
          [fileToDelete.control_id]: false
        }));
      }

      // Emit event to update completion stats
      window.dispatchEvent(new CustomEvent('completionStatsUpdated', {
        detail: { orgId: nowViewingOrgId }
      }));

      // Refresh the documents view
      await fetchDocuments();
      setIsDeleteModalOpen(false);
      setFileToDelete(null);
    } catch (error) {
      console.error('Error deleting evidence:', error);
    }
  };

  const handleEditMetadata = (file) => {
    setSelectedFile({
      ...file,
      friendlyName: file.friendly_name || file.file_name,
      docId: file.id || file.docId,  // Ensure docId is set properly, defaulting to 'id' if 'docId' is missing
    });
    setIsEditMetadataModalOpen(true);
  }; 
   
  const handleSaveMetadata = async () => {
    if (!selectedFile?.id) {
      console.error('No document ID available for metadata update.');
      return;
    }
  
    try {
      const docRef = doc(db, 'documents', selectedFile.id);
      await updateDoc(docRef, {
        friendly_name: selectedFile.friendlyName,
        control_ids: selectedFile.control_ids,
        frameworks: selectedFile.frameworks,
      });
  
      fetchDocuments();
      setIsEditMetadataModalOpen(false);
    } catch (error) {
      console.error('Error updating metadata:', error);
    }
  }; 

  const fetchExistingEvidence = async (controlGroup) => {
    if (!nowViewingOrgId || !controlGroup) return;

    try {
      const evidenceRef = collection(db, 'evidence');
      const controlIds = CONTROL_GROUPS[controlGroup];
      
      const evidenceData = {};
      
      for (const controlId of controlIds) {
        const q = query(
          evidenceRef,
          where('org_id', '==', nowViewingOrgId),
          where('control_id', '==', controlId)
        );
        
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          evidenceData[controlId] = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
        }
      }
      
      setExistingEvidence(evidenceData);
    } catch (error) {
      console.error('Error fetching existing evidence:', error);
    }
  };

  const handleControlGroupChange = (e) => {
    const group = e.target.value;
    setSelectedControlGroup(group);
    if (group) {
      fetchExistingEvidence(group);
      fetchCompletedControls(group);
    }
  };

  const fetchControlDetails = async (controlIds) => {
    try {
      const controlsRef = collection(db, 'controls');
      const controlDetails = {};
      
      for (const controlId of controlIds) {
        const controlDoc = await getDoc(doc(controlsRef, controlId));
        if (controlDoc.exists()) {
          controlDetails[controlId] = controlDoc.data();
        }
      }
      
      return controlDetails;
    } catch (error) {
      console.error('Error fetching control details:', error);
      return {};
    }
  };

  useEffect(() => {
    if (selectedControlGroup) {
      fetchControlDetails(CONTROL_GROUPS[selectedControlGroup])
        .then(details => setControlDetails(details));
    }
  }, [selectedControlGroup]);

  const fetchCompletedControls = async (controlGroup) => {
    if (!nowViewingOrgId || !controlGroup) return;
    
    try {
      const completedStatuses = {};
      const controlIds = CONTROL_GROUPS[controlGroup];
      
      for (const controlId of controlIds) {
        const clientControlRef = doc(db, 'client_controls', `${nowViewingOrgId}_${controlId}`);
        const controlDoc = await getDoc(clientControlRef);
        if (controlDoc.exists()) {
          completedStatuses[controlId] = controlDoc.data().completed_approved || false;
        }
      }
      
      setCompletedControls(completedStatuses);
      
      // Pre-select completed controls
      setSelectedControls(
        Object.entries(completedStatuses)
          .filter(([_, isCompleted]) => isCompleted)
          .map(([controlId]) => controlId)
      );
    } catch (error) {
      console.error('Error fetching completed controls:', error);
    }
  };

  const handleQuickUploadForControl = (controlId, groupName) => {
    setQuickUploadControl({ controlId, groupName });
    setIsModalOpen(true);
  };

  const handleQuickUploadForGroup = (groupName) => {
    setQuickUploadGroup(groupName);
    setIsModalOpen(true);
  };

  const renderUploadModal = () => {
    return (
      <div className="modal-content">
        <h3>Upload Document</h3>
        
        <div className="control-group-selection">
          <h4>Select Control Group</h4>
          <select 
            value={selectedControlGroup || ''} 
            onChange={handleControlGroupChange}
            className="control-group-select"
          >
            <option value="">Select a group...</option>
            {Object.keys(CONTROL_GROUPS).map(group => (
              <option key={group} value={group}>{group}</option>
            ))}
          </select>
        </div>

        {selectedControlGroup && (
          <div className="satisfy-controls">
            <div className="satisfy-all-checkbox">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={satisfyAllControls}
                  onChange={(e) => setSatisfyAllControls(e.target.checked)}
                />
                <span>Satisfy all controls in {selectedControlGroup}</span>
              </label>
            </div>
            
            <div className="control-list">
              {CONTROL_GROUPS[selectedControlGroup].map(controlId => {
                const controlDetail = controlDetails[controlId] || {};
                const isCompleted = completedControls[controlId];
                const hasEvidence = existingEvidence[controlId]?.length > 0;

                return (
                  <div key={controlId} className="control-item">
                    <div className="control-header">
                      <div className="control-checkbox">
                        <input
                          type="checkbox"
                          checked={satisfyAllControls || selectedControls.includes(controlId) || isCompleted}
                          onChange={(e) => {
                            if (!satisfyAllControls && !isCompleted) {
                              setSelectedControls(prev => 
                                e.target.checked 
                                  ? [...prev, controlId]
                                  : prev.filter(id => id !== controlId)
                              );
                            }
                          }}
                          disabled={satisfyAllControls || isCompleted}
                        />
                      </div>
                      <div className="control-info">
                        <div className="control-header-row">
                          <span className="control-id">{controlId}</span>
                          <span className="control-name">{controlDetail.friendly_control_name}</span>
                          {isCompleted && <span className="status-badge completed">Satisfied</span>}
                        </div>
                        <div className="control-description">
                          {controlDetail.control_description}
                        </div>
                      </div>
                    </div>
                    
                    {hasEvidence && (
                      <div className="existing-evidence">
                        <p className="evidence-header">Existing Evidence:</p>
                        {existingEvidence[controlId].map((evidence, idx) => (
                          <div key={idx} className="evidence-item">
                            <span>{evidence.evidence_description}</span>
                            <a href={evidence.evidence_url} target="_blank" rel="noopener noreferrer">
                              View
                            </a>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <div className="evidence-description">
          <h4>Evidence Description</h4>
          <textarea
            value={evidenceDescription}
            onChange={(e) => setEvidenceDescription(e.target.value)}
            placeholder="Describe the evidence..."
            className="evidence-textarea"
            rows={4}
          />
        </div>

        <div className="file-upload-section">
          <input
            type="file"
            onChange={(e) => setFileToUpload(e.target.files[0])}
            accept="*"
            className="file-input"
            disabled={isUploading}
          />
          
          <div className="modal-buttons">
            {fileToUpload && (
              <button 
                className={`upload-button ${isUploading ? 'loading' : ''}`}
                onClick={() => handleFileUpload(fileToUpload)}
                disabled={isUploading}
              >
                {isUploading ? (
                  <>
                    <div className="button-loader"></div>
                    Uploading...
                  </>
                ) : (
                  'Upload'
                )}
              </button>
            )}
            <button 
              className="cancel-button" 
              onClick={() => setIsModalOpen(false)}
              disabled={isUploading}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderDocumentsTable = () => (
    <>
      {isLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
          <p>Loading evidence and controls...</p>
        </div>
      ) : (
        <table className="document-table">
          <thead>
            <tr>
              <th>Control Group</th>
              <th>Control</th>
              <th>Evidence</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(filteredFiles).map(([groupName, controls]) => (
              <React.Fragment key={groupName}>
                <tr className="group-header">
                  <td colSpan="4">{groupName}</td>
                  <td>
                    <button 
                      className="quick-upload-button"
                      onClick={() => handleQuickUploadForGroup(groupName)}
                    >
                      Upload Evidence for Group
                    </button>
                  </td>
                </tr>
                {controls.map((control, idx) => (
                  <tr key={`${control.control_id}-${idx}`}>
                    <td></td>
                    <td>
                      <div className="control-info">
                        <div className="control-header-row">
                          <strong>{control.control_id}</strong>
                          <span>{control.control_name}</span>
                        </div>
                        <div className="control-description">
                          {control.control_description}
                        </div>
                      </div>
                    </td>
                    <td>
                      {control.evidence.length > 0 ? (
                        control.evidence.map((evidence, evidenceIdx) => (
                          <div key={evidenceIdx} className="evidence-item">
                            <span>{evidence.evidence_description}</span>
                            <a href={evidence.evidence_url} target="_blank" rel="noopener noreferrer">
                              View
                            </a>
                          </div>
                        ))
                      ) : (
                        <div className="no-evidence">
                          <span>No evidence uploaded</span>
                          <button 
                            className="quick-upload-button small"
                            onClick={() => handleQuickUploadForControl(control.control_id, groupName)}
                          >
                            Upload Evidence
                          </button>
                        </div>
                      )}
                    </td>
                    <td>
                      <span className={`status-badge ${control.isCompleted ? 'completed' : 'pending'}`}>
                        {control.isCompleted ? 'Satisfied' : 'Pending'}
                      </span>
                    </td>
                    <td>
                      <div className="file-actions">
                        {control.evidence.map((evidence, evidenceIdx) => (
                          <React.Fragment key={evidenceIdx}>
                            <AiFillEye 
                              size={20} 
                              onClick={() => window.open(evidence.evidence_url, "_blank")} 
                              style={{ cursor: 'pointer', marginRight: '8px' }} 
                            />
                            <AiFillDelete 
                              size={20} 
                              onClick={() => handleDeleteClick(evidence)} 
                              style={{ cursor: 'pointer' }} 
                            />
                          </React.Fragment>
                        ))}
                      </div>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}
    </>
  );

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    maxHeight: '85vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  };

  useEffect(() => {
    if (isModalOpen) {
      if (quickUploadControl) {
        setSelectedControlGroup(quickUploadControl.groupName);
        setSelectedControls([quickUploadControl.controlId]);
      } else if (quickUploadGroup) {
        setSelectedControlGroup(quickUploadGroup);
        setSatisfyAllControls(true);
      }
    } else {
      // Reset quick upload states when modal closes
      setQuickUploadControl(null);
      setQuickUploadGroup(null);
      setSelectedControlGroup(null);
      setSatisfyAllControls(false);
      setSelectedControls([]);
    }
  }, [isModalOpen]);

  return (
    <div className="document-table-container">
      <div className="table-header">
        <div className="table-tabs">
          <span className="active-tab">Client Documents</span>
        </div>

        <div className="button-wrapper" style={{ marginLeft: 'auto' }}>
          <button onClick={() => setIsModalOpen(true)} className="add-document-button">
            Add Document
          </button>
        </div>
      </div>

      {/* Add the documents table here */}
      {renderDocumentsTable()}

      <Modal 
        open={isModalOpen} 
        onClose={() => setIsModalOpen(false)}
      >
        <Box sx={modalStyle}>
          {renderUploadModal()}
        </Box>
      </Modal>

      <Modal 
        open={isDeleteModalOpen} 
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <Box sx={modalStyle}>
          <div className="modal-content">
            <h3>Are you sure you want to delete this document?</h3>
            <button className="delete-confirm-button" onClick={confirmDelete}>
              Yes, Delete
            </button>
            &nbsp;
            <button className="cancel-button" onClick={() => setIsDeleteModalOpen(false)}>
              Cancel
            </button>
          </div>
        </Box>
      </Modal>

      <Modal 
        open={isEditMetadataModalOpen} 
        onClose={() => setIsEditMetadataModalOpen(false)}
      >
        <Box sx={modalStyle}>
          {/* ... existing edit metadata modal content ... */}
        </Box>
      </Modal>
    </div>
  );
};

export default DocumentTable; 
