class MicrosoftGraphService {
  constructor(config) {
    this.config = config;
    this.testServer = 'http://localhost:3456'; // Test server endpoint
  }

  async testPermission(permission) {
    let endpoint;
    
    // Map permissions to their corresponding endpoints
    switch (permission) {
      case 'DeviceManagementManagedDevices.Read.All':
        endpoint = '/deviceManagement/managedDevices';
        break;
      case 'DeviceManagementConfiguration.Read.All':
        endpoint = '/deviceManagement/deviceConfigurations';
        break;
      case 'DeviceManagementRBAC.Read.All':
        endpoint = '/deviceManagement/roleAssignments';
        break;
      case 'DeviceManagementApps.Read.All':
        endpoint = '/deviceAppManagement/mobileApps';
        break;
      case 'DeviceManagementServiceConfig.Read.All':
        endpoint = '/deviceManagement/deviceCompliancePolicies';
        break;
      default:
        throw new Error('Unsupported permission');
    }

    try {
      const response = await fetch(`${this.testServer}/test-permission`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientId: this.config.clientId,
          clientSecret: this.config.clientSecret,
          tenantId: this.config.tenantId,
          endpoint,
          permission
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || `Request failed with status ${response.status}`);
      }

      const data = await response.json();
      return {
        endpoint,
        data
      };
    } catch (error) {
      if (error.message.includes('Failed to fetch')) {
        throw new Error('Unable to connect to test server. Please ensure the test server is running on port 3456.');
      }
      throw new Error(`Failed to test permission: ${error.message}`);
    }
  }
}

export default MicrosoftGraphService;
