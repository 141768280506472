import React, { createContext, useContext, useState } from 'react';

const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [clientId, setClientId] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [nowViewingOrgId, setNowViewingOrgId] = useState(null);
  const [availableOrgs, setAvailableOrgs] = useState([]); // For dropdown options

  return (
    <GlobalStateContext.Provider value={{
      clientId,
      setClientId,
      orgId,
      setOrgId,
      nowViewingOrgId,
      setNowViewingOrgId,
      availableOrgs,
      setAvailableOrgs
    }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => useContext(GlobalStateContext);