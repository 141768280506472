import React, { useState, useEffect } from 'react';
import { signOut } from 'firebase/auth';
import { auth, db } from '../firebase';
import './LeftSidebar.css';
import { useGlobalState } from '../context/GlobalStateContext';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { FaBars, FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem } from '@mui/material';
import UpdatedResultsWindow from './UpdatedResultsWindow';

const LeftSidebar = ({
  onHomeClick,
  onDocumentsClick,
  onControlsClick,
  onToDoListClick,
  onVirtualTeamClick,
  onRealTimeRiskProtectionClick,
  onControlLibraryClick,
  onSettingsClick,
  onAccessReviewClick,
  onIncidentReportClick,
  onSystemsHealthClick,
  onMobileDeviceManagementClick,
  onEndpointDetectionAndResponseClick,
  onPatchingClick,
  onAssetManagementClick,
  onClientsClick,
  onAuditorsClick,
  onVayuMissionControlClick,
  onScopingClick,
  onIssuesClick,
  onDataWarehouseClick,
}) => {
  const navigate = useNavigate();
  const [openSections, setOpenSections] = useState({
    Overview: true,
    Report: true,
    Manage: true,
    ITSecurity: true,
    Admin: true,
  });

  const [orgType, setOrgType] = useState(null);
  const [userEmailDomain, setUserEmailDomain] = useState('');
  const [isMinimized, setIsMinimized] = useState(false);
  const [version, setVersion] = useState('2.0.5');
  const [orgDomain, setOrgDomain] = useState('');

  const { 
    setClientId, 
    setOrgId, 
    nowViewingOrgId, 
    setNowViewingOrgId, 
    availableOrgs, 
    setAvailableOrgs 
  } = useGlobalState();

  const [isResultsVisible, setIsResultsVisible] = useState(false);
  const [clickedButton, setClickedButton] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [searchAction, setSearchAction] = useState('');
  const [processingStage, setProcessingStage] = useState('');
  const [stepResults, setStepResults] = useState([]);

  useEffect(() => {
    const fetchOrgTypeAndSetup = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        try {
          const userQuery = query(collection(db, 'users'), where('email', '==', currentUser.email));
          const userSnapshot = await getDocs(userQuery);
          
          if (!userSnapshot.empty) {
            const userData = userSnapshot.docs[0].data();
            const userOrgId = userData.orgId;

            if (userOrgId) {
              const orgDoc = await getDoc(doc(db, 'orgs', userOrgId));
              if (orgDoc.exists()) {
                const orgData = orgDoc.data();
                setOrgType(orgData.type);
                setOrgDomain(orgData.domain || '');

                const savedViewingOrgId = localStorage.getItem('nowViewingOrgId');

                switch(orgData.type) {
                  case 'client':
                    setNowViewingOrgId(userOrgId);
                    setAvailableOrgs([{ id: userOrgId, name: orgData.name }]);
                    break;

                  case 'auditor':
                    if (orgData.linkedClients && orgData.linkedClients.length > 0) {
                      const linkedOrgsData = await Promise.all(
                        orgData.linkedClients.map(async (clientId) => {
                          const clientDoc = await getDoc(doc(db, 'orgs', clientId));
                          return { id: clientId, name: clientDoc.data().name };
                        })
                      );
                      setAvailableOrgs(linkedOrgsData);
                      const validSavedId = savedViewingOrgId && linkedOrgsData.some(org => org.id === savedViewingOrgId);
                      setNowViewingOrgId(validSavedId ? savedViewingOrgId : linkedOrgsData[0].id);
                    }
                    break;

                  case 'vayu':
                    const clientOrgsQuery = query(collection(db, 'orgs'), where('type', '==', 'client'));
                    const clientOrgsSnapshot = await getDocs(clientOrgsQuery);
                    const clientOrgsData = clientOrgsSnapshot.docs.map(doc => ({
                      id: doc.id,
                      name: doc.data().name
                    }));
                    setAvailableOrgs(clientOrgsData);
                    const validSavedId = savedViewingOrgId && clientOrgsData.some(org => org.id === savedViewingOrgId);
                    setNowViewingOrgId(validSavedId ? savedViewingOrgId : clientOrgsData[0].id);
                    break;
                }
              }
            }

            const emailDomain = currentUser.email.split('@')[0];
            setUserEmailDomain(emailDomain);
          }
        } catch (error) {
          console.error('Error fetching organization data:', error);
        }
      }
    };

    fetchOrgTypeAndSetup();
    setVersion('2.0.8');
  }, [setNowViewingOrgId, setAvailableOrgs]);

  const handleOrgChange = (event) => {
    const newOrgId = event.target.value;
    setNowViewingOrgId(newOrgId);
    localStorage.setItem('nowViewingOrgId', newOrgId);
    window.location.reload();
  };

  const toggleSection = (section) => {
    setOpenSections({
      ...openSections,
      [section]: !openSections[section],
    });
  };

  const handleLogout = async () => {
    try {
      // Clear global state first
      setClientId(null);
      setOrgId(null);
      
      // Sign out from Firebase
      await signOut(auth);
      
      // Navigate to login page
      navigate('/login');
      
    } catch (error) {
      console.error('Error signing out:', error);
      // If error occurs, still try to navigate to login
      navigate('/login');
    }
  };

  const handleControlsClick = async () => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      try {
        const userQuery = query(collection(db, 'users'), where('email', '==', currentUser.email));
        const userSnapshot = await getDocs(userQuery);
        
        if (!userSnapshot.empty) {
          const userData = userSnapshot.docs[0].data();
          const userOrgId = userData.orgId;
          if (userOrgId) {
            setOrgId(userOrgId);
            onControlsClick(userOrgId);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  };

  const toggleSidebar = () => {
    setIsMinimized(!isMinimized);
  };

  const handleIntegrationsClick = (e) => {
    e.preventDefault();
    setIsResultsVisible(true);
    setClickedButton('Integrations');
    setSearchResult(null);
    setSearchAction('');
    setProcessingStage('');
    setStepResults([]);
  };

  const handleSendMessage = async (message) => {
    console.log('Message sending not implemented in sidebar');
  };

  return (
    <>
      <aside className={`left-sidebar-custom ${isMinimized ? 'minimized' : ''}`}>
        <button className="toggle-btn" onClick={toggleSidebar}>
          {isMinimized ? <FaBars /> : <FaChevronLeft />}
        </button>
        {!isMinimized && (
          <nav className="left-sidebar-custom-nav">
            <div className="logo-container">
              <img
                onClick={onHomeClick}
                src="/logo.svg"
                alt="Vayu Logo"
                className="logosidebar"
                width={250}
              />
              <div style={{ fontSize: '8pt', textAlign: 'center', marginTop: '5px' }}>
                v. {version}
              </div>
              
              {(orgType === 'auditor' || orgType === 'vayu') && availableOrgs.length > 0 && (
                <Select
                  value={nowViewingOrgId || ''}
                  onChange={(event) => {
                    const newOrgId = event.target.value;
                    setNowViewingOrgId(newOrgId);
                    localStorage.setItem('nowViewingOrgId', newOrgId);
                    window.location.reload();
                  }}
                  className="org-selector"
                  style={{ 
                    width: '90%', 
                    margin: '10px auto',
                    backgroundColor: 'white',
                    fontSize: '14px'
                  }}
                >
                  {availableOrgs.map((org) => (
                    <MenuItem key={org.id} value={org.id}>
                      {org.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </div>

            <div className="left-sidebar-custom-accordion-section">
              <div
                className="left-sidebar-custom-accordion-title"
                onClick={() => toggleSection('Overview')}
              >
                Overview{' '}
                <span
                  className={`chevron ${openSections.Overview ? 'open' : ''}`}
                >
                  &#9650;
                </span>
              </div>
              {openSections.Overview && (
                <ul className="left-sidebar-custom-accordion-content">
                  <li onClick={onHomeClick}>Home</li>
                  <li onClick={handleIntegrationsClick}>Integrations</li>
                  <li onClick={onDocumentsClick}>Documents</li>
                  
                  {userEmailDomain.includes('admin') && (
                    <li onClick={onRealTimeRiskProtectionClick}>Roles & Permissions Investigator</li>
                  )}
                  
                  {orgDomain === 'joinvayu.com' && (
                    <li 
                      onClick={onDataWarehouseClick}
                      className="admin-tab"
                    >
                      Data Warehouse
                    </li>
                  )}
                  
                  <li onClick={onIssuesClick}>Issues</li>
                  <li onClick={onToDoListClick}>To-Do List</li>
                  <li onClick={onSettingsClick}>Settings</li>
                  <li onClick={handleLogout}>Log Out</li>
                </ul>
              )}
            </div>
          </nav>
        )}
      </aside>
      
      <UpdatedResultsWindow 
        isVisible={isResultsVisible} 
        onClose={() => {
          setIsResultsVisible(false);
          setClickedButton('');
          setSearchResult('');
          setSearchAction('');
          setProcessingStage('');
          setStepResults([]);
        }} 
        content={clickedButton} 
        searchAction={searchAction}
        isLoading={false}
        processingStage={processingStage}
        searchResult={searchResult}
        stepResults={stepResults}
        onSendMessage={handleSendMessage}
        now_viewing_org_id={nowViewingOrgId}
      />
    </>
  );
};

export default LeftSidebar;
