import React, { useState, useEffect } from 'react';
import './VirtualTeamChat.css';
import { auth, db } from '../firebase';
import { collection, addDoc, query, orderBy, limit, onSnapshot, getDocs, where } from 'firebase/firestore';

const VirtualTeamChat = () => {
  const [userName, setUserName] = useState('');
  const [timeOfDay, setTimeOfDay] = useState('Hello');
  const [userEmailDomain, setUserEmailDomain] = useState('');
  const [messages, setMessages] = useState([]);

  const baseUrl =
    process.env.NODE_ENV === 'production'
      ? 'https://godfirst-vayu-app-qravzvgjba-uc.a.run.app'
      : 'http://localhost:8080';

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const currentUser = auth.currentUser;
        if (currentUser) {
          const userQuery = query(collection(db, 'users'), where('email', '==', currentUser.email));
          const userSnapshot = await getDocs(userQuery);
          
          if (!userSnapshot.empty) {
            const userData = userSnapshot.docs[0].data();
            setUserName(userData.fname);

            // Extract and set the email domain
            const emailDomain = currentUser.email.split('@')[1];
            setUserEmailDomain(emailDomain);
          }
        } else {
          console.error('No user is currently signed in.');
        }
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };

    fetchUserName();

    // Determine time of day
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      setTimeOfDay('Good morning');
    } else if (currentHour < 18) {
      setTimeOfDay('Good afternoon');
    } else {
      setTimeOfDay('Good evening');
    }
  }, []);

  const teamMembers = [
    { name: 'CAM', avatar: '/Godfirst-then-cam.avif' },
    // Other team members can be added here
  ];

  // Determine which chatbot URL to use based on the email domain
  const chatbotUrl = userEmailDomain.includes('joinvayu.com')
    ? 'https://udify.app/chatbot/e1ZO8byW0nAqD8oj'
    : userEmailDomain.includes('bdemerson')
    ? 'https://udify.app/chatbot/7JIsNzq5e0FabT8u'
    : '';

  useEffect(() => {
    const q = query(collection(db, 'messages'), orderBy('timestamp', 'desc'), limit(50));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messagesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMessages(messagesData);
    });

    return () => unsubscribe();
  }, []);

  const sendMessage = async (messageContent) => {
    await addDoc(collection(db, 'messages'), {
      content: messageContent,
      timestamp: new Date(),
      // Add other necessary fields
    });
  };

  return (
    <div className="virtual-team-chat">
      <div className="virtual-team-sidebar"> 
        <h1 className="virtual-team-title">Hi {userName}, how can we help?</h1>
        <ul className="virtual-team-list">
          {teamMembers.map((member, index) => (
            <li key={index} className="virtual-team-item">
              <div className="virtual-team-avatar-wrapper">
                <img src={member.avatar} alt={`${member.name} Avatar`} className="virtual-team-avatar" />
              </div>
              <span className="virtual-team-name">{member.name}</span>
              <span className="virtual-team-arrow">&#8250;</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="virtual-team-chat-window">
        {chatbotUrl && (
          <iframe
            src={chatbotUrl}
            style={{ width: '100%', height: '100%', minHeight: '700px' }}
            frameBorder="0"
            allow="microphone"
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default VirtualTeamChat;
