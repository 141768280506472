import React, { useState } from 'react';
import './JSONViewer.css';

const JSONViewer = ({ data }) => {
  const [expandedPaths, setExpandedPaths] = useState(new Set(['root']));

  const togglePath = (path) => {
    const newExpandedPaths = new Set(expandedPaths);
    if (newExpandedPaths.has(path)) {
      newExpandedPaths.delete(path);
    } else {
      newExpandedPaths.add(path);
    }
    setExpandedPaths(newExpandedPaths);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const renderValue = (value, path = 'root', level = 0) => {
    const indent = '  '.repeat(level);
    const isExpanded = expandedPaths.has(path);

    if (Array.isArray(value)) {
      if (value.length === 0) return <span className="json-empty-array">[]</span>;

      return (
        <div>
          <span 
            className="json-toggle" 
            onClick={() => togglePath(path)}
          >
            {isExpanded ? '▼' : '▶'} [
          </span>
          {isExpanded && (
            <>
              {value.map((item, index) => (
                <div key={index} style={{ marginLeft: '20px' }}>
                  {renderValue(item, `${path}.${index}`, level + 1)}
                  {index < value.length - 1 && <span className="json-comma">,</span>}
                </div>
              ))}
              <div>{indent}]</div>
            </>
          )}
          {!isExpanded && <span className="json-preview">...</span>}
          {!isExpanded && <span>]</span>}
        </div>
      );
    }

    if (value === null) {
      return <span className="json-null">null</span>;
    }

    if (typeof value === 'object') {
      const entries = Object.entries(value);
      if (entries.length === 0) return <span className="json-empty-object">{'{}'}</span>;

      return (
        <div>
          <span 
            className="json-toggle" 
            onClick={() => togglePath(path)}
          >
            {isExpanded ? '▼' : '▶'} {'{'}
          </span>
          {isExpanded && (
            <>
              {entries.map(([key, val], index) => (
                <div key={key} style={{ marginLeft: '20px' }}>
                  <span className="json-key">{key}</span>
                  <span className="json-colon">: </span>
                  {renderValue(val, `${path}.${key}`, level + 1)}
                  {index < entries.length - 1 && <span className="json-comma">,</span>}
                </div>
              ))}
              <div>{indent}{'}'}</div>
            </>
          )}
          {!isExpanded && <span className="json-preview">...</span>}
          {!isExpanded && <span>{'}'}</span>}
        </div>
      );
    }

    if (typeof value === 'string') {
      return <span className="json-string">"{value}"</span>;
    }

    if (typeof value === 'number') {
      return <span className="json-number">{value}</span>;
    }

    if (typeof value === 'boolean') {
      return <span className="json-boolean">{value.toString()}</span>;
    }

    return <span>{String(value)}</span>;
  };

  return (
    <div className="json-viewer-container">
      <div className="json-viewer-toolbar">
        <button 
          className="json-viewer-button"
          onClick={() => copyToClipboard(JSON.stringify(data, null, 2))}
        >
          Copy JSON
        </button>
        <button 
          className="json-viewer-button"
          onClick={() => setExpandedPaths(new Set(['root']))}
        >
          Collapse All
        </button>
        <button 
          className="json-viewer-button"
          onClick={() => {
            const allPaths = new Set(['root']);
            const addPaths = (obj, path = 'root') => {
              if (Array.isArray(obj)) {
                obj.forEach((_, i) => {
                  allPaths.add(`${path}.${i}`);
                  if (typeof obj[i] === 'object' && obj[i] !== null) {
                    addPaths(obj[i], `${path}.${i}`);
                  }
                });
              } else if (typeof obj === 'object' && obj !== null) {
                Object.keys(obj).forEach(key => {
                  allPaths.add(`${path}.${key}`);
                  if (typeof obj[key] === 'object' && obj[key] !== null) {
                    addPaths(obj[key], `${path}.${key}`);
                  }
                });
              }
            };
            addPaths(data);
            setExpandedPaths(allPaths);
          }}
        >
          Expand All
        </button>
      </div>
      <div className="json-viewer-content">
        {renderValue(data)}
      </div>
    </div>
  );
};

export default JSONViewer;
