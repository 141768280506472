import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import './App.css';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import MainLayout from './MainLayout';
import { GlobalStateProvider } from './context/GlobalStateContext';
import { auth } from './firebase';
import AdminDataWarehouse from './components/AdminDataWarehouse';
import Home from './components/Home';
import Header from './components/Header';
import About from './components/About';
import Blog from './components/Blog';
import Footer from './components/Footer';

// Create a wrapper component to handle conditional header rendering
const AppContent = () => {
  const location = useLocation();
  const showHeader = location.pathname !== '/dashboard';
  const showFooter = location.pathname !== '/dashboard';

  return (
    <div className="app-container">
      {showHeader && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route
          path="/login"
          element={auth.currentUser ? <Navigate to="/dashboard" /> : <Login />}
        />
        <Route
          path="/dashboard"
          element={
            auth.currentUser ? (
              <Dashboard user={auth.currentUser} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/data-warehouse"
          element={
            auth.currentUser ? (
              <AdminDataWarehouse />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/*"
          element={auth.currentUser ? <MainLayout /> : <Navigate to="/login" />}
        />
      </Routes>
      {showFooter && <Footer />}
    </div>
  );
};

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <GlobalStateProvider>
      <Router>
        <AppContent />
      </Router>
    </GlobalStateProvider>
  );
}

export default App;
