import React, { useState, useEffect } from 'react';
import './ComplianceTile.css';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { useGlobalState } from '../context/GlobalStateContext';

const ComplianceTile = ({ title, backgroundColor, progressColor, clientNames, onClick }) => {
  const [totalControls, setTotalControls] = useState(0);
  const [completedControls, setCompletedControls] = useState(0);
  const [progress, setProgress] = useState(0);
  const { nowViewingOrgId } = useGlobalState();

  useEffect(() => {
    let unsubscribe;

    const fetchControlData = async () => {
      if (!nowViewingOrgId) {
        console.log('No viewing org ID available');
        return;
      }

      try {
        // Query client_controls for the currently viewed org
        const clientControlsQuery = query(
          collection(db, 'client_controls'), 
          where('client_id', '==', nowViewingOrgId)
        );

        unsubscribe = onSnapshot(clientControlsQuery, (snapshot) => {
          const controls = snapshot.docs.map(doc => doc.data());
          const linkedControls = controls.filter(control => control.linked);
          const total = linkedControls.length;
          const completed = linkedControls.filter(control => control.completed_approved === true).length;
          
          setTotalControls(total);
          setCompletedControls(completed);
          
          // Calculate percentage
          const percentage = total > 0 ? (completed / total) * 100 : 0;
          setProgress(percentage);
        });
      } catch (error) {
        console.error('Error fetching control data:', error);
        setTotalControls(0);
        setCompletedControls(0);
        setProgress(0);
      }
    };

    fetchControlData();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [nowViewingOrgId]); // Re-fetch when nowViewingOrgId changes

  return (
    <div
      className="compliance-tile"
      style={{ backgroundColor, cursor: onClick ? 'pointer' : 'default' }}
      onClick={onClick || (() => {})}
    >
      <div className="tile-content">
        <div className="tile-header">
          {title === "unknown" ? "Framework: Unknown" : title}
        </div>
        <div className="tile-body">
          <div className="progress-bar">
            <div className="progress" style={{ width: `${progress}%`, backgroundColor: progressColor }}></div>
          </div>
          <div className="tile-footer">
            <span>{progress.toFixed(0)}% Controls Complete</span>
            <span>{totalControls} Total</span>
          </div>
          <div className="tile-footer client-badges">
            {clientNames && clientNames.map((client, index) => (
              <span key={index} className="client-badge">Tenant: {client}</span>
            ))}
          </div>
        </div>
      </div>
      {onClick && <div className="tile-chevron">›</div>}
    </div>
  );
};

export default ComplianceTile;
