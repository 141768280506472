import React, { useState } from 'react';
import './Integrations.css';
import MicrosoftGraphService from '../services/microsoftGraphService';
import JSONViewer from './JSONViewer';

const AVAILABLE_INTEGRATIONS = [
  {
    id: 'microsoft',
    name: 'Microsoft Azure',
    logo: '/public/azurelogo.svg',
    description: 'Connect to Microsoft Azure services including InTune for device management.',
    services: ['InTune', 'Azure AD', 'Microsoft Graph API']
  }
];

const PERMISSIONS = [
  'DeviceManagementApps.Read.All',
  'DeviceManagementConfiguration.Read.All',
  'DeviceManagementManagedDevices.Read.All',
  'DeviceManagementRBAC.Read.All',
  'DeviceManagementServiceConfig.Read.All'
];

const ConfigurationModal = ({ integration, onClose }) => {
  const [formData, setFormData] = useState({
    clientId: '',
    clientSecret: '',
    tenantId: '',
  });
  const [selectedPermission, setSelectedPermission] = useState(null);
  const [testResult, setTestResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [graphService, setGraphService] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError(null);
    setGraphService(null);
  };

  const handleTestPermission = async (permission) => {
    setIsLoading(true);
    setSelectedPermission(permission);
    setError(null);
    setTestResult(null);

    try {
      const service = graphService || new MicrosoftGraphService(formData);
      if (!graphService) {
        setGraphService(service);
      }

      const result = await service.testPermission(permission);
      setTestResult({
        success: true,
        timestamp: new Date().toISOString(),
        endpoint: result.endpoint,
        data: result.data
      });
    } catch (error) {
      const errorMessage = error.message;
      setError(errorMessage);
      setTestResult({
        success: false,
        timestamp: new Date().toISOString(),
        error: errorMessage
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="config-modal" onClick={(e) => e.target.className === 'config-modal' && onClose()}>
      <div className="config-modal-content">
        <div className="config-modal-header">
          <h2>{integration.name} Configuration</h2>
          <button className="config-modal-close" onClick={onClose}>&times;</button>
        </div>

        <div className="config-form">
          <div className="form-group">
            <label>Application (Client) ID</label>
            <input
              type="text"
              name="clientId"
              value={formData.clientId}
              onChange={handleInputChange}
              placeholder="Enter your client ID"
            />
          </div>

          <div className="form-group">
            <label>Client Secret</label>
            <input
              type="password"
              name="clientSecret"
              value={formData.clientSecret}
              onChange={handleInputChange}
              placeholder="Enter your client secret"
            />
          </div>

          <div className="form-group">
            <label>Directory (Tenant) ID</label>
            <input
              type="text"
              name="tenantId"
              value={formData.tenantId}
              onChange={handleInputChange}
              placeholder="Enter your tenant ID"
            />
          </div>

          <div className="test-section">
            <h3>Test Permissions</h3>
            <p>Click on a permission to test the API call:</p>
            
            <div className="test-permissions">
              {PERMISSIONS.map(permission => (
                <div
                  key={permission}
                  className={`permission-tag ${selectedPermission === permission ? 'selected' : ''}`}
                  onClick={() => handleTestPermission(permission)}
                >
                  {permission}
                </div>
              ))}
            </div>

            {error && (
              <div className="error-message">
                {error}
              </div>
            )}

            {isLoading && (
              <div className="loading">
                <div className="loading-spinner"></div>
              </div>
            )}

            {testResult && (
              <div className="test-result">
                <div className="test-result-header">
                  <span className={`status-indicator ${testResult.success ? 'success' : 'error'}`}>
                    {testResult.success ? 'Success' : 'Error'}
                  </span>
                  <span className="timestamp">{new Date(testResult.timestamp).toLocaleString()}</span>
                </div>
                {testResult.endpoint && (
                  <div className="endpoint-info">
                    Endpoint: {testResult.endpoint}
                  </div>
                )}
                <div className="json-viewer">
                  {testResult.data ? (
                    <JSONViewer data={testResult.data} />
                  ) : (
                    <pre>{JSON.stringify(testResult.error, null, 2)}</pre>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="button-group">
            <button className="secondary-button" onClick={onClose}>Cancel</button>
            <button className="primary-button">Save Configuration</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Integrations = () => {
  const [activeTab, setActiveTab] = useState('available');
  const [selectedIntegration, setSelectedIntegration] = useState(null);

  const handleIntegrationClick = (integration) => {
    setSelectedIntegration(integration);
  };

  return (
    <div className="integrations-container">
      <div className="integrations-tabs">
        <button 
          className={`integrations-tab ${activeTab === 'available' ? 'active' : ''}`}
          onClick={() => setActiveTab('available')}
        >
          Available Integrations
        </button>
        <button 
          className={`integrations-tab ${activeTab === 'configured' ? 'active' : ''}`}
          onClick={() => setActiveTab('configured')}
        >
          Configured Integrations
        </button>
      </div>

      <div className="integration-cards">
        {AVAILABLE_INTEGRATIONS.map(integration => (
          <div
            key={integration.id}
            className="integration-card"
            onClick={() => handleIntegrationClick(integration)}
          >
            <div className="integration-card-header">
              <img src={integration.logo} alt={integration.name} className="integration-logo" />
              <h3 className="integration-name">{integration.name}</h3>
            </div>
            <p>{integration.description}</p>
            <div className="integration-tags">
              {integration.services.map((service, index) => (
                <span key={index} className="integration-tag">{service}</span>
              ))}
            </div>
          </div>
        ))}
      </div>

      {selectedIntegration && (
        <ConfigurationModal
          integration={selectedIntegration}
          onClose={() => setSelectedIntegration(null)}
        />
      )}
    </div>
  );
};

export default Integrations;
