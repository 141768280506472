import { db } from '../firebase';
import { collection, getDocs, query, where, doc, getDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';

// Tool types enum
export const TOOL_TYPES = {
  SCRIPT: 'script',
  API: 'api',
  QUERY: 'query'
};

// Validation schemas for different tool types
const VALIDATION_SCHEMAS = {
  [TOOL_TYPES.SCRIPT]: {
    required: ['script_id', 'script_name', 'script_url', 'type'],
    optional: ['description', 'keywords', 'priority', 'timeout', 'icon']
  },
  [TOOL_TYPES.API]: {
    required: ['script_id', 'script_name', 'api_url', 'type', 'method'],
    optional: ['description', 'keywords', 'priority', 'headers', 'timeout', 'icon']
  },
  [TOOL_TYPES.QUERY]: {
    required: ['script_id', 'script_name', 'collection', 'type'],
    optional: ['description', 'keywords', 'priority', 'queryConstraints', 'icon']
  }
};

class ToolsService {
  constructor() {
    this.toolCache = new Map();
    this.CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
  }

  // Validate tool definition against schema
  validateTool(tool) {
    const schema = VALIDATION_SCHEMAS[tool.type];
    if (!schema) {
      throw new Error(`Invalid tool type: ${tool.type}`);
    }

    // Check required fields
    const missingFields = schema.required.filter(field => !tool[field]);
    if (missingFields.length > 0) {
      throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
    }

    // Remove any fields not in schema
    const validFields = [...schema.required, ...schema.optional];
    const invalidFields = Object.keys(tool).filter(field => !validFields.includes(field));
    if (invalidFields.length > 0) {
      console.warn(`Tool contains invalid fields: ${invalidFields.join(', ')}`);
    }

    return true;
  }

  // Get cached tool if available
  getCachedTool(toolId) {
    const cached = this.toolCache.get(toolId);
    if (cached && Date.now() - cached.timestamp < this.CACHE_DURATION) {
      return cached.tool;
    }
    return null;
  }

  // Cache tool definition
  cacheTool(toolId, tool) {
    this.toolCache.set(toolId, {
      tool,
      timestamp: Date.now()
    });
  }

  // Connect a tool for a client
  async connectTool(toolId, clientId) {
    try {
      const clientRef = doc(db, 'clients', clientId);
      const clientDoc = await getDoc(clientRef);
      
      if (!clientDoc.exists()) {
        // Create new client document if it doesn't exist
        await updateDoc(clientRef, {
          allowedTools: [toolId],
          createdAt: new Date(),
          updatedAt: new Date()
        });
      } else {
        // Add tool to existing client's allowedTools
        await updateDoc(clientRef, {
          allowedTools: arrayUnion(toolId),
          updatedAt: new Date()
        });
      }

      // Clear cache to ensure fresh data on next fetch
      this.clearCache();
      
      return true;
    } catch (error) {
      console.error('Error connecting tool:', error);
      throw new Error('Failed to connect tool');
    }
  }

  // Disconnect a tool for a client
  async disconnectTool(toolId, clientId) {
    try {
      const clientRef = doc(db, 'clients', clientId);
      const clientDoc = await getDoc(clientRef);
      
      if (!clientDoc.exists()) {
        throw new Error('Client not found');
      }

      await updateDoc(clientRef, {
        allowedTools: arrayRemove(toolId),
        updatedAt: new Date()
      });

      // Clear cache to ensure fresh data on next fetch
      this.clearCache();
      
      return true;
    } catch (error) {
      console.error('Error disconnecting tool:', error);
      throw new Error('Failed to disconnect tool');
    }
  }

  // Fetch available tools for a client
  async getAvailableTools(clientId) {
    try {
      // First check client permissions
      const clientRef = doc(db, 'clients', clientId);
      const clientDoc = await getDoc(clientRef);
      
      let allowedTools = [];
      if (clientDoc.exists()) {
        allowedTools = clientDoc.data().allowedTools || [];
      }

      // Fetch all tools
      const toolsRef = collection(db, 'scripts');
      const toolsSnapshot = await getDocs(toolsRef);
      
      const tools = await Promise.all(toolsSnapshot.docs.map(async doc => {
        const toolId = doc.id;
        const cachedTool = this.getCachedTool(toolId);
        
        if (cachedTool) {
          return {
            ...cachedTool,
            connected: allowedTools.includes(toolId)
          };
        }

        const toolData = {
          id: toolId,
          ...doc.data(),
          connected: allowedTools.includes(toolId)
        };

        // Validate tool before returning
        if (this.validateTool(toolData)) {
          this.cacheTool(toolId, toolData);
          return toolData;
        }
      }));

      return tools.filter(Boolean); // Remove any null values from failed validations
    } catch (error) {
      console.error('Error fetching tools:', error);
      throw new Error('Failed to fetch available tools');
    }
  }

  // Execute a tool and get results
  async executeTool(toolId, params, clientId) {
    try {
      // Get tool definition from cache or Firestore
      let tool = this.getCachedTool(toolId);
      
      if (!tool) {
        const toolsRef = collection(db, 'scripts');
        const q = query(toolsRef, where('script_id', '==', toolId));
        const toolSnapshot = await getDocs(q);
        
        if (toolSnapshot.empty) {
          throw new Error('Tool not found');
        }

        tool = toolSnapshot.docs[0].data();
        
        // Validate and cache tool
        if (this.validateTool(tool)) {
          this.cacheTool(toolId, tool);
        }
      }

      // Check execution permissions
      const clientRef = doc(db, 'clients', clientId);
      const clientDoc = await getDoc(clientRef);
      
      if (!clientDoc.exists()) {
        throw new Error('Client not found');
      }

      const clientData = clientDoc.data();
      if (!clientData.allowedTools?.includes(toolId)) {
        throw new Error('Tool execution not permitted for this client');
      }

      // Execute tool based on type with timeout
      const timeout = tool.timeout || 30000; // Default 30 second timeout
      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Tool execution timed out')), timeout)
      );

      const executionPromise = (async () => {
        switch (tool.type) {
          case TOOL_TYPES.SCRIPT:
            return await this.executeScript(tool, params, clientId);
          case TOOL_TYPES.API:
            return await this.executeAPI(tool, params, clientId);
          case TOOL_TYPES.QUERY:
            return await this.executeQuery(tool, params, clientId);
          default:
            throw new Error('Unsupported tool type');
        }
      })();

      return await Promise.race([executionPromise, timeoutPromise]);
    } catch (error) {
      console.error('Error executing tool:', error);
      throw error;
    }
  }

  // Execute a script-type tool
  async executeScript(tool, params, clientId) {
    try {
      const response = await fetch(tool.script_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Client-ID': clientId
        },
        body: JSON.stringify(params)
      });

      if (!response.ok) {
        throw new Error(`Script execution failed: ${response.statusText}`);
      }

      const result = await response.json();
      return {
        type: 'script_result',
        data: result
      };
    } catch (error) {
      console.error('Error executing script:', error);
      throw error;
    }
  }

  // Execute an API-type tool
  async executeAPI(tool, params, clientId) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-Client-ID': clientId,
        ...tool.headers
      };

      const response = await fetch(tool.api_url, {
        method: tool.method || 'GET',
        headers,
        body: tool.method !== 'GET' ? JSON.stringify(params) : undefined
      });

      if (!response.ok) {
        throw new Error(`API request failed: ${response.statusText}`);
      }

      const result = await response.json();
      return {
        type: 'api_result',
        data: result
      };
    } catch (error) {
      console.error('Error executing API:', error);
      throw error;
    }
  }

  // Execute a query-type tool
  async executeQuery(tool, params, clientId) {
    try {
      const queryRef = collection(db, tool.collection);
      const constraints = typeof tool.queryConstraints === 'function' 
        ? tool.queryConstraints(params)
        : [];
      
      const q = query(queryRef, ...constraints);
      const querySnapshot = await getDocs(q);
      
      const results = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      return {
        type: 'query_result',
        data: results
      };
    } catch (error) {
      console.error('Error executing query:', error);
      throw error;
    }
  }

  // Clear tool cache (useful for testing/debugging)
  clearCache() {
    this.toolCache.clear();
  }
}

export const toolsService = new ToolsService();
