import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { auth } from '../firebase';

const Header = ({ isLoggedIn }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isNonAnonymousUser = auth.currentUser && !auth.currentUser.isAnonymous;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <Link to="/">
        <img 
          src={process.env.PUBLIC_URL + '/logo.svg'} 
          alt="Vayu Logo" 
          className="logo"
        />
      </Link>
      
      <button className={`hamburger ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
        <span className="hamburger-line"></span>
        <span className="hamburger-line"></span>
        <span className="hamburger-line"></span>
      </button>

      <nav className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
        <ul>
          <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
          <li>
            {isNonAnonymousUser ? (
              <Link to="/dashboard" onClick={toggleMenu}>Dashboard</Link>
            ) : (
              <Link to="/login" onClick={toggleMenu}>Log In</Link>
            )}
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
