import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, getRedirectResult, SAMLAuthProvider, OAuthProvider } from 'firebase/auth'; 
import { Link, useNavigate } from 'react-router-dom'; 
import { FaLink, FaMicrosoft } from 'react-icons/fa'; 
import './Login.css'; 

const Login = () => {
  const navigate = useNavigate(); 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailForSAML, setEmailForSAML] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false); 
  const [isLoading, setIsLoading] = useState(false);
  const [samlResult, setSamlResult] = useState(null);

  const auth = getAuth(); 

  // Check auth state on mount
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user?.uid && !user.isAnonymous) {
        try {
          // Force token refresh
          await user.getIdToken(true);
          // Only redirect if user is non-anonymous
          navigate('/dashboard');
        } catch (error) {
          console.error('Error verifying token:', error);
        }
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  // SAML and OIDC domain configurations
  const samlProviders = {
    'bdemerson.com': 'saml.bd-emerson',
    'joinvayu.com': 'saml.vayu-entra-test',
    'amitracks.com': 'oidc.assettrack',
    'origin63.com': 'saml.origin'
  };

  // Handle Email/Password login
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
    
    try {
      const cleanEmail = email.trim();
      const cleanPassword = password.trim();

      const userCredential = await signInWithEmailAndPassword(auth, cleanEmail, cleanPassword);
      const user = userCredential.user;
      
      if (user?.uid) {
        // Force a token refresh to ensure fresh auth state
        await user.getIdToken(true);
        navigate('/dashboard');
      } else {
        setErrorMessage('Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Login failed:', error);
      let errorMessage = 'Login failed. ';
      switch (error.code) {
        case 'auth/user-not-found':
          errorMessage += 'No user found with this email.';
          break;
        case 'auth/wrong-password':
          errorMessage += 'Incorrect password.';
          break;
        case 'auth/invalid-email':
          errorMessage += 'Invalid email format.';
          break;
        case 'auth/user-disabled':
          errorMessage += 'This account has been disabled.';
          break;
        default:
          errorMessage += 'Please check your credentials and try again.';
      }
      setErrorMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle SAML or OIDC Sign-in using Popup
  const handleSAMLSignIn = () => {
    const emailDomain = emailForSAML.split('@')[1];
    console.log('Email for SAML or OIDC:', emailForSAML);
    console.log('Email domain:', emailDomain);

    if (samlProviders[emailDomain]) {
      if (samlProviders[emailDomain].startsWith('saml')) {
        // SAML sign-in
        const provider = new SAMLAuthProvider(samlProviders[emailDomain]);
        signInWithPopup(auth, provider)
          .then(async (result) => {
            console.log('SAML Popup login successful:', result);
            if (result.user?.uid) {
              // Force a token refresh to ensure fresh auth state
              await result.user.getIdToken(true);
              navigate('/dashboard');
            } else {
              setErrorMessage('SAML sign-in failed.');
            }
          })
          .catch((error) => {
            console.error('SAML login failed:', error);
            setErrorMessage('SAML sign-in failed.');
          });
      } else if (samlProviders[emailDomain] === 'oidc.assettrack') {
        // OIDC sign-in for AssetTrack
        const provider = new OAuthProvider('oidc.assettrack');
        signInWithPopup(auth, provider)
          .then(async (result) => {
            console.log('OIDC Popup login successful:', result);
            if (result.user?.uid) {
              // Force a token refresh to ensure fresh auth state
              await result.user.getIdToken(true);
              navigate('/dashboard');
            } else {
              setErrorMessage('OIDC sign-in failed.');
            }
          })
          .catch((error) => {
            console.error('OIDC login failed:', error);
            setErrorMessage('OIDC sign-in failed.');
          });
      }
    } else {
      console.error('No SAML or OIDC provider found for the domain:', emailDomain);
      setErrorMessage('Your email domain is not recognized. Please contact an administrator.');
    }
  };

  // Fetch the redirect result when returning from SAML/OIDC sign-in
  useEffect(() => {
    getRedirectResult(auth)
      .then(async (result) => {
        if (result?.user?.uid) {
          // Force a token refresh to ensure fresh auth state
          await result.user.getIdToken(true);
          navigate('/dashboard');
        }
      })
      .catch((error) => {
        console.error('Error fetching redirect result:', error);
        setErrorMessage('Sign-in failed.');
      });
  }, [auth, navigate]);

  // Function to show popup for Microsoft/AssetTrack login
  const handleMicrosoftLoginClick = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setErrorMessage('');
  };

  // Only redirect if user is non-anonymous
  if (auth.currentUser?.uid && !auth.currentUser?.isAnonymous) {
    return null;
  }

  return (
    <div className="login-container">
      <div className="login-form">
        <Link to="/">
          <img src="/logo.svg" alt="Placeholder" width={250} />
        </Link>
        <h2>Welcome Back!</h2>
        <p>Let's get back to it</p>

        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="loginfields"
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="loginfields"
            required
          />
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          <button type="submit" className="submit-btn" disabled={isLoading}>
            {isLoading ? 'Logging in...' : 'Log in'}
          </button>
        </form>

        {/* Social login buttons */}
        <div className="social-logins">
          <button className="social-btn microsoft" onClick={handleMicrosoftLoginClick}>
            <FaMicrosoft /> Log in with Microsoft
          </button>
          <button className="social-btn microsoft" onClick={handleMicrosoftLoginClick}>
            <FaLink /> Log in with SAML
          </button>
        </div>

        {/* Display the SAML/OIDC result */}
        {samlResult && (
          <div className="saml-result">
            <h3>Authentication Payload</h3>
            <pre>{JSON.stringify(samlResult, null, 2)}</pre>
          </div>
        )}
      </div>
      <div className="login-image"></div>

      {/* Popup for SAML/OIDC email input */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h3>Enter Your Email for Login</h3>
            <input
              type="email"
              placeholder="Enter your email"
              value={emailForSAML}
              onChange={(e) => setEmailForSAML(e.target.value)}
              className="sso"
            />
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <button className="submit-btn" onClick={handleSAMLSignIn}>
              Proceed with Login
            </button>
            <button className="close-btn" onClick={closePopup}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
