import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import './EvidenceModal.css';
import { useGlobalState } from '../context/GlobalStateContext';
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { doc, updateDoc, collection, query, where, getDocs, addDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';

const EvidenceModal = ({ isOpen, onClose, controlDetails, refreshTable, closeSidebar, onControlUpdate }) => { 
  const { orgId, clientId } = useGlobalState();
  const [evidenceDescription, setEvidenceDescription] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [existingEvidence, setExistingEvidence] = useState([]);
  const [completedApproved, setCompletedApproved] = useState(false);

  const clientToUpdate = 'Zjg7OBngxNp35Y4bEMSh'; // Hardcoded client_id for updates

  const onDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'application/pdf, image/*',
  });

  useEffect(() => {
    if (isOpen) {
      fetchExistingEvidence();
      if (controlDetails) {
        setCompletedApproved(controlDetails.completed_approved || false);
      }
    }
  }, [isOpen, controlDetails.control_id]);

  const fetchExistingEvidence = async () => {
    const selectedOrgId = clientId || orgId;
    try {
      const evidenceQuery = query(
        collection(db, 'evidence'),
        where('control_id', '==', controlDetails.control_id),
        where('org_id', '==', selectedOrgId)
      );
      const evidenceSnapshot = await getDocs(evidenceQuery);
      const evidenceList = evidenceSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setExistingEvidence(evidenceList);
    } catch (error) {
      console.error('Error fetching existing evidence:', error);
    }
  };

  const handleSave = async () => {
    const selectedOrgId = clientId || orgId;
    const storage = getStorage();
    let evidenceEntries = [];

    if (uploadedFiles.length > 0 || evidenceDescription) {
      for (const file of uploadedFiles) {
        const fileRef = storageRef(storage, `documents/${selectedOrgId}/${file.file.name}`);
        const uploadTask = uploadBytesResumable(fileRef, file.file);

        await new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              // Optionally, you can track upload progress here
            },
            (error) => {
              console.error('Upload failed:', error);
              reject(error);
            },
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

              const newEvidence = {
                control_id: controlDetails.control_id,
                org_id: selectedOrgId,
                evidence_description: evidenceDescription,
                evidence_id: `${Date.now()}_${file.file.name}`,
                evidence_type: file.file.type,
                evidence_url: downloadURL,
                uploaded_at: new Date().toISOString(),
                uploaded_by: 'current_user_id',
              };

              evidenceEntries.push(newEvidence);
              resolve();
            }
          );
        });
      }

      if (uploadedFiles.length === 0 && evidenceDescription) {
        evidenceEntries.push({
          control_id: controlDetails.control_id,
          org_id: selectedOrgId,
          evidence_description: evidenceDescription,
          evidence_id: `${Date.now()}_text_only`,
          evidence_type: 'text',
          evidence_url: '',
          uploaded_at: new Date().toISOString(),
          uploaded_by: 'current_user_id',
        });
      }

      // Add evidence entries to Firestore
      for (const entry of evidenceEntries) {
        await addDoc(collection(db, 'evidence'), entry);
      }
    }

    // Update client_controls status
    await updateClientControlStatus(controlDetails.control_id, selectedOrgId, completedApproved);

    onControlUpdate({
      ...controlDetails,
      completed_approved: completedApproved
    });

    if (refreshTable) {
      refreshTable();
    }

    if (closeSidebar) {
      closeSidebar();
    }

    handleClose();
  };

  const updateClientControlStatus = async (controlId, orgId, completedApproved) => {
    try {
      const clientControlRef = doc(db, 'client_controls', `${orgId}_${controlId}`);
      await updateDoc(clientControlRef, {
        completed_approved: completedApproved,
        updated_at: new Date().toISOString()
      });
      console.log(`Control updated with completed_approved: ${completedApproved}`);
      return true;
    } catch (error) {
      console.error('Error updating control status:', error);
      throw error;
    }
  };

  const handleDeleteExistingEvidence = async (evidenceId, evidenceUrl) => {
    const storage = getStorage();

    try {
      // Delete the evidence document from Firestore
      await deleteDoc(doc(db, 'evidence', evidenceId));

      // Delete the file from Firebase Storage if evidence_url exists
      if (evidenceUrl) {
        const fileRef = storageRef(storage, evidenceUrl);
        await deleteObject(fileRef);
        console.log(`File ${evidenceUrl} deleted from Firebase Storage`);
      }

      // Update the UI
      setExistingEvidence((prevEvidence) => prevEvidence.filter(evidence => evidence.id !== evidenceId));
      console.log(`Evidence ${evidenceId} deleted successfully`);
    } catch (error) {
      console.error('Error deleting evidence:', error);
    }
  };

  const handleDeleteUploadedFile = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleClose = () => {
    setEvidenceDescription('');
    setUploadedFiles([]);
    setExistingEvidence([]);
    setCompletedApproved(false);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="evidence-modal-overlay">
      <div className="evidence-modal">
        <h3>Provide Evidence for {controlDetails.friendly_control_name}</h3>
        <textarea
          value={evidenceDescription}
          onChange={(e) => setEvidenceDescription(e.target.value)}
          placeholder="Describe the evidence..."
          rows={8}
        />
        <div
          {...getRootProps({ className: `dropzone ${isDragActive ? 'active' : ''}` })}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag & drop some files here, or click to select files</p>
          )}
        </div>
        <div className="uploaded-files">
          <h4>Uploaded Files:</h4>
          <ul>
            {uploadedFiles.map((file, index) => (
              <li key={index} className="file-preview">
                <img src={file.preview} alt={file.file.name} />
                <p>{file.file.name}</p>
                <button onClick={() => handleDeleteUploadedFile(index)}>Delete</button>
              </li>
            ))}
          </ul>
        </div>
        <div className="existing-evidence">
          <h4>Existing Evidence:</h4>
          {existingEvidence.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Preview</th>
                  <th>Description</th>
                  <th>Type</th>
                  <th>Link</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {existingEvidence.map((evidence, index) => (
                  <tr key={index}>
                    <td>
                      {evidence.evidence_type.startsWith('image/') ? (
                        <img src={evidence.evidence_url} alt={evidence.evidence_description} />
                      ) : (
                        <span>No preview</span>
                      )}
                    </td>
                    <td>{evidence.evidence_description}</td>
                    <td>{evidence.evidence_type}</td>
                    <td>
                      <a href={evidence.evidence_url} target="_blank" rel="noopener noreferrer">
                        View
                      </a>
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          handleDeleteExistingEvidence(evidence.id, evidence.evidence_url)
                        }
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No evidence uploaded yet.</p>
          )}
        </div>
        <div className="checkbox-container" style={{ display: 'inline-flex', justifyContent: 'flex-end', alignItems: 'center', padding: '10px', width: '100%' }}>
        <label htmlFor="completedApproved" style={{ margin: 0 }}>Evidence Satisfied?</label>
  <input
    type="checkbox"
    id="completedApproved"
    checked={completedApproved}
    onChange={(e) => setCompletedApproved(e.target.checked)}
    style={{ marginRight: '10px', width: 'auto', height: 'auto', marginTop: '15px', height: '15px', width: '15px' }}  // Added more space between checkbox and label
  />
  
</div> 
        <div className="modal-buttons" style={{padding: '10px'}}>
          <button onClick={handleSave}>Save</button>
          <button onClick={handleClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default EvidenceModal;
