import React, { useState, useEffect } from 'react';
import { 
  CircularProgress, 
  Alert,
  AlertTitle,
  Select,
  MenuItem,
  IconButton,
  Button,
  TextField,
  Box,
  Typography,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Autocomplete,
  Collapse,
  Divider,
  FormControl,
  InputLabel,
  ToggleButtonGroup,
  ToggleButton,
  Grid,
  List,
  ListItem,
  ListItemText,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SettingsIcon from '@mui/icons-material/Settings';
import UploadIcon from '@mui/icons-material/Upload';
import RefreshIcon from '@mui/icons-material/Refresh';
import * as pdfjsLib from 'pdfjs-dist';
import { getDocument } from 'pdfjs-dist';
import { saveFrameworkMapping as saveToFirebase, fetchFrameworks, addFramework, updateFramework, deleteFramework, fetchFrameworkControls, uploadPDFToStorage } from '../services/firebase';
import './AdminDataWarehouse.css';

// Configure PDF.js worker
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

// Framework-specific colors
const frameworkColors = {
  'COSO': '#2196f3',
  'SOC 2': '#4caf50',
  'ISO 27001': '#ff9800'
};

// Mock existing criteria for autocomplete
const existingCriteria = [
  {
    keyValues: {
      'TSC_ref_#': 'CC1.1',
      'criteria': 'COSO Principle 1: The entity demonstrates a commitment to integrity and ethical values.'
    },
    domains: ['Security']
  },
  {
    keyValues: {
      'TSC_ref_#': 'CC1.4',
      'criteria': 'COSO Principle 4: The entity demonstrates a commitment to attract, develop, and retain competent individuals in alignment with objectives.'
    },
    domains: ['Security', 'Privacy']
  }
];

// Mock controls data based on screenshot
const mockControls = {
  'SOC 2': [
    {
      id: 'C001',
      friendly_name: 'Background Checks',
      control_description: '[COMPANY] performs background checks on employees prior to the employment start date. Any issues noted in the background checks require management approval.',
      categories: [
        {
          id: 'CC1.0',
          name: 'Control Environment',
          criteria: [
            {
              keyValues: {
                'TSC_ref_#': 'CC1.1',
                'criteria': 'COSO Principle 1: The entity demonstrates a commitment to integrity and ethical values.'
              },
              domains: ['Security']
            },
            {
              keyValues: {
                'TSC_ref_#': 'CC1.4',
                'criteria': 'COSO Principle 4: The entity demonstrates a commitment to attract, develop, and retain competent individuals in alignment with objectives.'
              },
              domains: ['Security']
            }
          ]
        }
      ]
    },
    {
      id: 'C002',
      friendly_name: 'Confidentiality Agreement',
      control_description: 'Prior to the employment start date, new hires and new contractors are required to sign an agreement that defines the commitment to protect [COMPANY]\'s confidential information, including customer data.',
      categories: [
        {
          id: 'CC1.0',
          name: 'Control Environment',
          criteria: [
            {
              keyValues: {
                'TSC_ref_#': 'CC1.1',
                'criteria': 'COSO Principle 1: The entity demonstrates a commitment to integrity and ethical values.'
              },
              domains: ['Confidentiality', 'Privacy']
            }
          ]
        }
      ]
    }
  ]
};

const existingCategories = [
  {
    id: 'CC1.0',
    name: 'Control Environment'
  },
  {
    id: 'CC2.0',
    name: 'Communication and Information'
  }
];

const colors = {
  control: '#4caf50',    // Green for controls
  category: '#2196f3',   // Blue for categories
  criteria: '#ff4081'    // Rose for criteria
};

// Add this helper function near the top of the file
const createSearchOption = (control, matchType, matchText) => {
  return {
    control,
    matchType,
    matchText,
    label: `${control.id} - ${control.friendly_name}`,
  };
};

// Update the searchControls function
const searchControls = (controls, searchText) => {
  if (!searchText) return [];
  const lowercaseSearch = searchText.toLowerCase().trim();
  const results = [];

  controls.forEach(control => {
    let hasMatch = false;

    // Search in control
    const controlText = `${control.id} ${control.friendly_name} ${control.control_description}`.toLowerCase();
    if (controlText.includes(lowercaseSearch)) {
      results.push(createSearchOption(
        control, 
        'control', 
        control.control_description
      ));
      hasMatch = true;
    }

    // Search in categories and their criteria
    control.categories?.forEach(category => {
      // Search in category
      const categoryText = `${category.id} ${category.name}`.toLowerCase();
      if (categoryText.includes(lowercaseSearch)) {
        results.push(createSearchOption(
          control, 
          'category', 
          `${category.id}: ${category.name}`
        ));
        hasMatch = true;
      }

      // Search in criteria
      category.criteria?.forEach(criteria => {
        // Search in all criteria text content
        const criteriaTexts = [];
        for (const [key, value] of Object.entries(criteria.keyValues)) {
          criteriaTexts.push(key.toLowerCase(), value.toLowerCase());
        }
        const criteriaText = criteriaTexts.join(' ');
        const domainsText = criteria.domains.join(' ').toLowerCase();

        if (criteriaText.includes(lowercaseSearch) || domainsText.includes(lowercaseSearch)) {
          results.push(createSearchOption(
            control,
            'criteria',
            `${criteria.keyValues['TSC_ref_#']}: ${criteria.keyValues['criteria']}`
          ));
          hasMatch = true;
        }
      });
    });
  });

  return results;
};

// Add new data structures to represent the collections
const frameworksData = {
  'soc2': {
    name: 'SOC 2',
    description: 'Service Organization Control 2',
    issuer: 'AICPA',
    version: '2017'
  },
  'iso27001': {
    name: 'ISO 27001',
    description: 'Information Security Management System',
    issuer: 'ISO',
    version: '2013'
  }
};

// Framework-specific metadata (v2_framework_<slug>)
const frameworkMetadata = {
  'soc2': {
    categories: [
      {
        id: 'CC1.0',
        name: 'Control Environment',
        source: 'AICPA TSC 2017'
      }
    ],
    criteria: [
      {
        id: 'CC1.1',
        description: 'COSO Principle 1...',
        category: 'CC1.0',
        domains: ['Security']
      }
    ]
  }
};

// Universal/foundational metadata (v2_foundational_<slug>)
const foundationalMetadata = {
  categories: [
    {
      id: 'ACCESS-1',
      name: 'Access Control',
      description: 'Universal access control principles'
    }
  ],
  controls: [
    {
      id: 'AC-1',
      name: 'Access Control Policy',
      category: 'ACCESS-1'
    }
  ],
  criteria: [
    {
      id: 'AC-1.1',
      description: 'Access control policies are documented...',
      control: 'AC-1',
      mappings: {
        'soc2': ['CC1.1', 'CC1.2'],
        'iso27001': ['A.9.1.1']
      }
    }
  ]
};

const extractTextFromPDF = async (file) => {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const pdf = await getDocument({ data: arrayBuffer }).promise;
    let text = '';
    
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const content = await page.getTextContent();
      const pageText = content.items.map(item => item.str).join(' ');
      text += pageText + '\n';
    }
    
    return text;
  } catch (error) {
    console.error('Error extracting text from PDF:', error);
    throw error;
  }
};

const AdminDataWarehouse = () => {
  const [selectedFramework, setSelectedFramework] = useState('');
  const [filteredControls, setFilteredControls] = useState([]);
  const [expandedControls, setExpandedControls] = useState({});
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [addCriteriaDialogOpen, setAddCriteriaDialogOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [newCustomCriteria, setNewCustomCriteria] = useState({
    keyValues: {},
    domains: []
  });
  const [isCustomCriteria, setIsCustomCriteria] = useState(false);
  const [addCategoryDialogOpen, setAddCategoryDialogOpen] = useState(false);
  const [selectedControl, setSelectedControl] = useState(null);
  const [newCustomCategory, setNewCustomCategory] = useState({
    id: '',
    name: ''
  });
  const [expandedCategories, setExpandedCategories] = useState({});
  const [criteriaError, setCriteriaError] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [displayedControlId, setDisplayedControlId] = useState(null);
  const [viewMode, setViewMode] = useState('framework'); // 'framework' or 'foundational'
  const [selectedFrameworkSlug, setSelectedFrameworkSlug] = useState(null);
  const [mappingMode, setMappingMode] = useState(false);
  const [frameworkManagementOpen, setFrameworkManagementOpen] = useState(false);
  const [foundationalMetadataState, setFoundationalMetadata] = useState(foundationalMetadata);
  const [newFramework, setNewFramework] = useState({
    slug: '',
    name: '',
    description: '',
    issuer: '',
    version: ''
  });
  const [frameworksDataState, setFrameworksData] = useState(frameworksData);
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [importStatus, setImportStatus] = useState('idle'); // idle, loading, success, error
  const [mappingDialogOpen, setMappingDialogOpen] = useState(false);
  const [currentMapping, setCurrentMapping] = useState(null);
  const [mappings, setMappings] = useState([]);
  const [validationStatus, setValidationStatus] = useState('idle'); // idle, validating, success, error
  const [criteriaKeyValuePairs, setCriteriaKeyValuePairs] = useState([]);

  useEffect(() => {
    const loadFrameworks = async () => {
      try {
        const frameworks = await fetchFrameworks();
        setFrameworksData(frameworks);
        // Update the framework selector options
        if (Object.keys(frameworks).length > 0) {
          setSelectedFramework(Object.keys(frameworks)[0]); // Select first framework by default
        }
      } catch (error) {
        console.error('Error loading frameworks:', error);
      }
    };
    loadFrameworks();
  }, []);

  useEffect(() => {
    if (selectedFramework) {
      const controls = mockControls[selectedFramework] || [];
      setFilteredControls(controls);
      
      const newExpandedState = {};
      controls.forEach(control => {
        newExpandedState[control.id] = true;
      });
      setExpandedControls(newExpandedState);
    }
  }, [selectedFramework]);

  const handleFrameworkChange = (event) => {
    const frameworkSlug = event.target.value;
    setSelectedFramework(frameworkSlug);
    
    // Load framework-specific data
    const loadFrameworkData = async () => {
      try {
        // Fetch controls, categories, and criteria for this framework
        const controls = await fetchFrameworkControls(frameworkSlug);
        setFilteredControls(controls);
        
        const newExpandedState = {};
        controls.forEach(control => {
          newExpandedState[control.id] = true;
        });
        setExpandedControls(newExpandedState);
      } catch (error) {
        console.error('Error loading framework data:', error);
      }
    };
    
    loadFrameworkData();
  };

  const handleEdit = (item, type) => {
    setSelectedItem({ ...item, type });
    setEditDialogOpen(true);
  };

  const handleDeleteCriteria = (controlId, categoryId, criteriaIndex) => {
    setFilteredControls(prevControls => 
      prevControls.map(control => {
        if (control.id === controlId) {
          return {
            ...control,
            categories: control.categories.map(cat => {
              if (cat.id === categoryId) {
                return {
                  ...cat,
                  criteria: cat.criteria.filter((_, index) => index !== criteriaIndex)
                };
              }
              return cat;
            })
          };
        }
        return control;
      })
    );
  };

  const handleAddCriteria = (category) => {
    setSelectedCategory(category);
    setAddCriteriaDialogOpen(true);
    setIsCustomCriteria(false);
    setNewCustomCriteria({
      keyValues: {},
      domains: []
    });
    setCriteriaError('');
  };

  const handleSaveNewCriteria = () => {
    const criteriaToAdd = isCustomCriteria ? newCustomCriteria : selectedItem;
    
    // Check for duplicates when saving custom criteria
    const isDuplicate = selectedCategory.criteria?.some(
      existingCriteria => 
        existingCriteria.keyValues['TSC_ref_#'] === criteriaToAdd.keyValues['TSC_ref_#']
    );

    if (!isDuplicate) {
      setFilteredControls(prevControls => 
        prevControls.map(control => ({
          ...control,
          categories: control.categories.map(cat => {
            if (cat.id === selectedCategory.id) {
              return {
                ...cat,
                criteria: [...cat.criteria, criteriaToAdd]
              };
            }
            return cat;
          })
        }))
      );
      setAddCriteriaDialogOpen(false);
    }
  };

  const handleAddCustomKeyValue = () => {
    setNewCustomCriteria(prev => ({
      ...prev,
      keyValues: {
        ...prev.keyValues,
        '': ''
      }
    }));
  };

  const handleCustomKeyValueChange = (oldKey, newKey, value) => {
    setNewCustomCriteria(prev => {
      const newKeyValues = { ...prev.keyValues };
      if (oldKey in newKeyValues) {
        delete newKeyValues[oldKey];
      }
      newKeyValues[newKey] = value;
      return {
        ...prev,
        keyValues: newKeyValues
      };
    });
  };

  const handleAddCustomDomain = (domain) => {
    if (isCustomCriteria && domain) {
      setNewCustomCriteria(prev => {
        // Check if domain already exists
        if (!prev.domains.includes(domain)) {
          return {
            ...prev,
            domains: [...prev.domains, domain]
          };
        }
        return prev;
      });
    }
  };

  const handleRemoveCustomDomain = (domainToRemove) => {
    if (isCustomCriteria) {
      setNewCustomCriteria(prev => ({
        ...prev,
        domains: prev.domains.filter(domain => domain !== domainToRemove)
      }));
    }
  };

  const toggleExpand = (controlId) => {
    setExpandedControls(prev => ({
      ...prev,
      [controlId]: !prev[controlId]
    }));
  };

  const handleAddCategory = (control) => {
    setSelectedControl(control);
    setAddCategoryDialogOpen(true);
    setNewCustomCategory({
      id: '',
      name: ''
    });
  };

  const handleSaveNewCategory = () => {
    setFilteredControls(prevControls =>
      prevControls.map(control => {
        if (control.id === selectedControl.id) {
          return {
            ...control,
            categories: [...control.categories, { 
              ...newCustomCategory,
              criteria: []
            }]
          };
        }
        return control;
      })
    );
    setAddCategoryDialogOpen(false);
  };

  const toggleExpandCategory = (categoryId) => {
    setExpandedCategories(prev => ({
      ...prev,
      [categoryId]: !prev[categoryId]
    }));
  };

  const handleDeleteCategory = (controlId, categoryId) => {
    setFilteredControls(prevControls => 
      prevControls.map(control => {
        if (control.id === controlId) {
          return {
            ...control,
            categories: control.categories.filter(cat => cat.id !== categoryId)
          };
        }
        return control;
      })
    );
  };

  const handleEditCriteria = (control, category, criteria, criteriaIndex) => {
    setSelectedItem({ 
      ...criteria, 
      type: 'criteria',
      controlId: control.id,
      categoryId: category.id,
      criteriaIndex 
    });
    setEditDialogOpen(true);
    setIsCustomCriteria(true);
    setNewCustomCriteria(criteria);
  };

  const handleSaveEdit = () => {
    if (selectedItem?.type === 'criteria') {
      setFilteredControls(prevControls =>
        prevControls.map(control => {
          if (control.id === selectedItem.controlId) {
            return {
              ...control,
              categories: control.categories.map(cat => {
                if (cat.id === selectedItem.categoryId) {
                  return {
                    ...cat,
                    criteria: cat.criteria.map((crit, idx) => 
                      idx === selectedItem.criteriaIndex ? newCustomCriteria : crit
                    )
                  };
                }
                return cat;
              })
            };
          }
          return control;
        })
      );
    }
    // ... handle other types of edits ...
    
    setEditDialogOpen(false);
  };

  const handleMapCriteria = (foundationalCriteria, frameworkCriteria) => {
    // Update the mappings in foundational metadata
    setFoundationalMetadata(prev => ({
      ...prev,
      criteria: prev.criteria.map(crit => {
        if (crit.id === foundationalCriteria.id) {
          return {
            ...crit,
            mappings: {
              ...crit.mappings,
              [selectedFrameworkSlug]: [
                ...(crit.mappings[selectedFrameworkSlug] || []),
                frameworkCriteria.id
              ]
            }
          };
        }
        return crit;
      })
    }));
  };

  const handleFrameworkUpload = async (file) => {
    // Parse Excel/CSV file
    // Create new framework entry
    // Create framework-specific metadata collection
  };

  const handleAddNewFramework = async () => {
    try {
      const frameworkId = await addFramework(newFramework);
      
      setFrameworksData(prev => ({
        ...prev,
        [frameworkId]: {
          ...newFramework,
          firebaseId: frameworkId
        }
      }));
      
      // Reset form
      setNewFramework({
        slug: '',
        name: '',
        description: '',
        issuer: '',
        version: ''
      });
    } catch (error) {
      console.error('Error adding framework:', error);
    }
  };

  const handleFrameworkImport = (slug) => {
    // Will implement file upload/import logic
    console.log('Import framework data for:', slug);
  };

  const handleEditFramework = async (frameworkId) => {
    try {
      const framework = frameworksDataState[frameworkId];
      await updateFramework(framework.firebaseId, framework);
    } catch (error) {
      console.error('Error updating framework:', error);
    }
  };

  const handleDeleteFramework = async (frameworkId) => {
    try {
      await deleteFramework(frameworkId);
      setFrameworksData(prev => {
        const newState = { ...prev };
        delete newState[frameworkId];
        return newState;
      });
    } catch (error) {
      console.error('Error deleting framework:', error);
    }
  };

  const handleImportData = async () => {
    try {
      setImportStatus('loading');
      
      // Handle PDF import logic here
      
      setImportStatus('success');
    } catch (error) {
      console.error('Import error:', error);
      setImportStatus('error');
    }
  };

  const handleApproveMapping = async () => {
    try {
      // Save approved mapping to Firebase
      await saveToFirebase(currentMapping);
      
      // Move to next mapping
      const currentIndex = mappings.indexOf(currentMapping);
      if (currentIndex < mappings.length - 1) {
        setCurrentMapping(mappings[currentIndex + 1]);
      } else {
        // Finished all mappings
        setMappingDialogOpen(false);
        setMappings([]);
        setCurrentMapping(null);
      }
    } catch (error) {
      console.error('Error saving mapping:', error);
    }
  };

  const handleRejectMapping = () => {
    // Skip to next mapping
    const currentIndex = mappings.indexOf(currentMapping);
    if (currentIndex < mappings.length - 1) {
      setCurrentMapping(mappings[currentIndex + 1]);
    } else {
      // Finished all mappings
      setMappingDialogOpen(false);
      setMappings([]);
      setCurrentMapping(null);
    }
  };

  const handleCriteriaMapping = (index, type, value) => {
    setCriteriaKeyValuePairs(prev => {
      const newPairs = [...prev];
      newPairs[index] = {
        ...newPairs[index],
        [type]: value
      };
      return newPairs;
    });
  };

  const addCriteriaMapping = () => {
    setCriteriaKeyValuePairs(prev => [...prev, { key: '', value: '' }]);
  };

  const removeCriteriaMapping = (index) => {
    setCriteriaKeyValuePairs(prev => prev.filter((_, i) => i !== index));
  };

  const ImportDialog = () => {
    const [pdfFile, setPdfFile] = useState(null);
    const [analyzing, setAnalyzing] = useState(false);
    const [error, setError] = useState('');

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file && file.type === 'application/pdf') {
        setPdfFile(file);
        setError('');
      } else {
        setError('Please select a valid PDF file');
      }
    };

    const handleAnalyze = async () => {
      try {
        setAnalyzing(true);
        setError('');
        
        // Extract text from PDF
        const pdfText = await extractTextFromPDF(pdfFile);
        
        // Upload PDF to Firebase Storage
        const pdfUrl = await uploadPDFToStorage(pdfFile);
        
        console.log('PDF uploaded:', pdfUrl);
        setImportDialogOpen(false);
      } catch (error) {
        setError('Failed to process PDF: ' + error.message);
      } finally {
        setAnalyzing(false);
      }
    };

    return (
      <Dialog
        open={importDialogOpen}
        onClose={() => setImportDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Import Framework from PDF</DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <Button
              variant="outlined"
              component="label"
              startIcon={<UploadIcon />}
            >
              Select PDF File
              <input
                type="file"
                hidden
                accept="application/pdf"
                onChange={handleFileChange}
              />
            </Button>
            
            {pdfFile && (
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Selected file: {pdfFile.name}
                </Typography>
              </Box>
            )}

            {error && (
              <Alert severity="error">
                {error}
              </Alert>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setImportDialogOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleAnalyze}
            disabled={!pdfFile || analyzing}
            startIcon={analyzing ? <CircularProgress size={20} /> : null}
          >
            {analyzing ? 'Analyzing...' : 'Analyze with Claude'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const MappingReviewDialog = () => (
    <Dialog
      open={mappingDialogOpen}
      onClose={() => setMappingDialogOpen(false)}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>Review Framework Mappings</DialogTitle>
      <DialogContent>
        {currentMapping && (
          <Stack spacing={3} sx={{ mt: 2 }}>
            <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
              <Typography variant="h6" gutterBottom>Framework Item</Typography>
              <pre style={{ whiteSpace: 'pre-wrap' }}>
                {JSON.stringify(currentMapping.framework, null, 2)}
              </pre>
            </Box>
            <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
              <Typography variant="h6" gutterBottom>Suggested Universal Mapping</Typography>
              <pre style={{ whiteSpace: 'pre-wrap' }}>
                {JSON.stringify(currentMapping.universal, null, 2)}
              </pre>
            </Box>
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button 
                variant="outlined" 
                color="error"
                onClick={handleRejectMapping}
              >
                Reject
              </Button>
              <Button 
                variant="contained"
                onClick={handleApproveMapping}
              >
                Approve
              </Button>
            </Stack>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );

  return (
    <div className="admin-data-warehouse">
      <Box sx={{ mb: 3 }}>
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={(e, newMode) => setViewMode(newMode)}
        >
          <ToggleButton value="framework">
            Framework-Specific View
          </ToggleButton>
          <ToggleButton value="foundational">
            Universal Controls View
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <div style={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: '24px',
        marginBottom: '24px'
      }}>
        <div style={{ display: 'flex', gap: '12px', alignItems: 'center', width: '300px' }}>
          <FormControl fullWidth>
            <InputLabel id="framework-select-label">Select Framework</InputLabel>
            <Select
              labelId="framework-select-label"
              id="framework-select"
              value={selectedFramework}
              label="Select Framework"
              onChange={handleFrameworkChange}
            >
              {Object.entries(frameworksDataState).map(([slug, framework]) => (
                <MenuItem key={slug} value={slug}>
                  {framework.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton 
            onClick={() => setFrameworkManagementOpen(true)}
            title="Manage Frameworks"
          >
            <SettingsIcon />
          </IconButton>
        </div>

        {selectedFramework && (
          <Autocomplete
            options={searchControls(filteredControls, searchInput)}
            inputValue={searchInput}
            onInputChange={(event, newValue) => {
              setSearchInput(newValue);
            }}
            onChange={(event, option) => {
              if (option) {
                // Set which control to display
                setDisplayedControlId(option.control.id);
                
                // Expand the selected control
                setExpandedControls(prev => ({
                  ...prev,
                  [option.control.id]: true
                }));
                
                // Scroll to the control
                const element = document.getElementById(option.control.id);
                if (element) {
                  element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
              } else {
                // Clear the filter when selection is cleared
                setDisplayedControlId(null);
              }
            }}
            getOptionLabel={(option) => option.label || ''}
            renderOption={(props, option) => (
              <Box
                component="li"
                {...props}
                sx={{
                  borderLeft: `4px solid ${
                    option.matchType === 'control' ? colors.control :
                    option.matchType === 'category' ? colors.category :
                    colors.criteria
                  }`,
                  pl: 2
                }}
              >
                <Stack>
                  <Typography variant="subtitle2">
                    {option.label}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{ fontSize: '0.85rem' }}
                  >
                    Matched in {option.matchType}: {option.matchText}
                  </Typography>
                </Stack>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search controls, categories, or criteria"
                variant="outlined"
                fullWidth
                sx={{ minWidth: '400px' }}
              />
            )}
            freeSolo
            clearOnBlur={false}
          />
        )}

        {selectedFramework && (
          <Box 
            sx={{ 
              display: 'flex', 
              gap: 3, 
              alignItems: 'center',
              p: 2,
              backgroundColor: 'rgba(0, 0, 0, 0.02)',
              borderRadius: 1,
              flex: 1
            }}
          >
            <Typography variant="subtitle2">Legend:</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box sx={{ width: 16, height: 16, backgroundColor: colors.control, borderRadius: 0.5 }} />
              <Typography variant="body2">Universal Control</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box sx={{ width: 16, height: 16, backgroundColor: colors.category, borderRadius: 0.5 }} />
              <Typography variant="body2">Category</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box sx={{ width: 16, height: 16, backgroundColor: colors.criteria, borderRadius: 0.5 }} />
              <Typography variant="body2">Criteria</Typography>
            </Box>
          </Box>
        )}
      </div>

      {selectedFramework && (
        <div className="controls-container">
          {filteredControls
            .filter(control => !displayedControlId || control.id === displayedControlId)
            .map((control) => (
              <div 
                key={control.id}
                id={control.id} 
                className="control-row"
                style={{ 
                  borderLeft: `4px solid ${colors.control}`
                }}
              >
                <div 
                  className="control-header"
                  onClick={() => toggleExpand(control.id)}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="control-title">
                    <div>
                      <Typography variant="subtitle1" style={{ marginBottom: '4px' }}>
                        <strong>{control.id}</strong> - {control.friendly_name}
                      </Typography>
                      <Typography 
                        variant="body2" 
                        color="text.secondary"
                        style={{ fontSize: '0.85rem' }}
                      >
                        {control.control_description}
                      </Typography>
                    </div>
                    <IconButton 
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {expandedControls[control.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </div>
                  <div style={{ display: 'flex', gap: '8px' }}>
                    <IconButton 
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddCategory(control);
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                    <IconButton 
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEdit(control, 'control');
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                </div>

                <Collapse in={expandedControls[control.id]}>
                  <div className="control-content">
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddCategory(control);
                      }}
                      size="small"
                      sx={{ mb: 2 }}
                    >
                      Add Category
                    </Button>
                    
                    {control.categories?.map((category) => (
                      <div 
                        key={category.id} 
                        className="category-section"
                        style={{ 
                          borderLeft: `4px solid ${colors.category}`,
                          marginLeft: '16px',
                          paddingLeft: '16px',
                          marginBottom: '16px'
                        }}
                      >
                        <div 
                          className="category-header"
                          onClick={() => toggleExpandCategory(`${control.id}-${category.id}`)}
                          style={{ cursor: 'pointer' }}
                        >
                          <div className="category-title">
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                              <Typography variant="subtitle2">
                                <strong>{category.id}:</strong> {category.name}
                                <Typography 
                                  component="span" 
                                  variant="body2" 
                                  color="text.secondary" 
                                  sx={{ ml: 1 }}
                                >
                                  ({category.criteria?.length || 0} {(category.criteria?.length || 0) === 1 ? 'criteria' : 'criteria'}
                                  {category.criteria?.length > 0 ? ', click to expand' : ', click + to add criteria'})
                                </Typography>
                              </Typography>
                              <IconButton 
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                {expandedCategories[`${control.id}-${category.id}`] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                              </IconButton>
                            </div>
                            <div className="category-actions">
                              <IconButton 
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEdit(category, 'category');
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton 
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleAddCriteria(category);
                                }}
                              >
                                <AddIcon />
                              </IconButton>
                              <IconButton 
                                size="small"
                                color="error"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteCategory(control.id, category.id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </div>
                        </div>

                        <Collapse in={expandedCategories[`${control.id}-${category.id}`]}>
                          <div className="criteria-list">
                            {category.criteria?.map((criteria, criteriaIndex) => (
                              <div 
                                key={criteriaIndex} 
                                className="criteria-item" 
                                style={{ 
                                  display: 'flex', 
                                  justifyContent: 'space-between', 
                                  alignItems: 'flex-start',
                                  borderLeft: `4px solid ${colors.criteria}`,
                                  marginLeft: '16px',
                                  paddingLeft: '16px',
                                  marginBottom: '8px'
                                }}
                              >
                                <div className="criteria-content" style={{ flex: 1 }}>
                                  {Object.entries(criteria.keyValues).map(([key, value]) => (
                                    <Typography key={key} variant="body2">
                                      <strong>{key}:</strong> {value}
                                    </Typography>
                                  ))}
                                  <div className="criteria-domains">
                                    {criteria.domains.map((domain, idx) => (
                                      <Chip 
                                        key={idx} 
                                        label={domain} 
                                        size="small" 
                                        sx={{ mr: 1, mb: 1 }}
                                      />
                                    ))}
                                  </div>
                                </div>
                                <div className="criteria-actions" style={{ display: 'flex', gap: '4px' }}>
                                  <IconButton
                                    size="small"
                                    onClick={() => handleEditCriteria(control, category, criteria, criteriaIndex)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() => handleDeleteCriteria(control.id, category.id, criteriaIndex)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Collapse>
                      </div>
                    ))}
                  </div>
                </Collapse>
              </div>
            ))}
        </div>
      )}

      <Dialog 
        open={editDialogOpen} 
        onClose={() => setEditDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Edit {selectedItem?.type === 'control' ? 'Control' : 
               selectedItem?.type === 'category' ? 'Category' : 'Criteria'}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 2 }}>
            {selectedItem?.type === 'control' ? (
              <>
                <TextField
                  label="Control ID"
                  value={selectedItem?.id || ''}
                  fullWidth
                />
                <TextField
                  label="Friendly Name"
                  value={selectedItem?.friendly_name || ''}
                  fullWidth
                />
                <TextField
                  label="Description"
                  value={selectedItem?.control_description || ''}
                  fullWidth
                  multiline
                  rows={4}
                />
              </>
            ) : selectedItem?.type === 'category' ? (
              <>
                <TextField
                  label="Category ID"
                  value={selectedItem?.id || ''}
                  fullWidth
                />
                <TextField
                  label="Category Name"
                  value={selectedItem?.name || ''}
                  fullWidth
                />
              </>
            ) : (
              // Criteria editing form
              <Stack spacing={2}>
                <Typography variant="subtitle2">Key-Value Pairs</Typography>
                {Object.entries(newCustomCriteria.keyValues).map(([key, value], index) => (
                  <Box key={index} sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                      label="Key"
                      value={key}
                      onChange={(e) => handleCustomKeyValueChange(key, e.target.value, value)}
                      size="small"
                    />
                    <TextField
                      label="Value"
                      value={value}
                      onChange={(e) => handleCustomKeyValueChange(key, key, e.target.value)}
                      fullWidth
                      size="small"
                    />
                    <IconButton 
                      color="error" 
                      size="small"
                      onClick={() => {
                        const newKeyValues = { ...newCustomCriteria.keyValues };
                        delete newKeyValues[key];
                        setNewCustomCriteria(prev => ({
                          ...prev,
                          keyValues: newKeyValues
                        }));
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleAddCustomKeyValue}
                  variant="outlined"
                  size="small"
                >
                  Add Key-Value Pair
                </Button>

                <Divider />

                <Typography variant="subtitle2">Domains</Typography>
                <Stack direction="row" spacing={1}>
                  {newCustomCriteria.domains.map((domain, idx) => (
                    <Chip
                      key={idx}
                      label={domain}
                      onDelete={() => handleRemoveCustomDomain(domain)}
                    />
                  ))}
                  <Autocomplete
                    options={['Security', 'Privacy', 'Confidentiality', 'Availability', 'Processing Integrity']}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        {...props}
                        sx={{ 
                          display: 'flex', 
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        {option}
                        {newCustomCriteria.domains.includes(option) && (
                          <Typography color="success.main" sx={{ ml: 1 }}>✓</Typography>
                        )}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Add Domain" size="small" />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        handleAddCustomDomain(newValue);
                      }
                    }}
                    value={null}
                    inputValue=""
                    size="small"
                    style={{ width: 200 }}
                    clearOnBlur
                    selectOnFocus
                    key={newCustomCriteria.domains.length}
                  />
                </Stack>
              </Stack>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleSaveEdit}>Save Changes</Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={addCriteriaDialogOpen} 
        onClose={() => {
          setAddCriteriaDialogOpen(false);
          setCriteriaError('');
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Add Criteria to {selectedCategory?.name}</DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <div>
              <Typography variant="subtitle2" gutterBottom>
                Select existing criteria or create custom
              </Typography>
              <Stack direction="row" spacing={2}>
                <Button
                  variant={!isCustomCriteria ? "contained" : "outlined"}
                  onClick={() => setIsCustomCriteria(false)}
                >
                  Use Existing
                </Button>
                <Button
                  variant={isCustomCriteria ? "contained" : "outlined"}
                  onClick={() => setIsCustomCriteria(true)}
                >
                  Create Custom
                </Button>
              </Stack>
            </div>

            {!isCustomCriteria ? (
              <Autocomplete
                options={existingCriteria}
                getOptionLabel={(option) => {
                  return option.keyValues['TSC_ref_#'] || '';
                }}
                renderOption={(props, option) => (
                  <Box 
                    component="li" 
                    {...props}
                    sx={{
                      fontFamily: 'monospace',
                      whiteSpace: 'pre-wrap',
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      borderRadius: 1,
                      my: 0.5,
                      p: 2,
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                      },
                      position: 'relative'
                    }}
                  >
                    {/* Add checkmark if criteria is already selected */}
                    {selectedCategory?.criteria?.some(crit => 
                      crit.keyValues['TSC_ref_#'] === option.keyValues['TSC_ref_#']
                    ) && (
                      <Typography 
                        color="success.main" 
                        sx={{ 
                          position: 'absolute',
                          right: 16,
                          top: 16
                        }}
                      >
                        ✓
                      </Typography>
                    )}
                    <Stack spacing={1} width="100%">
                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <Typography 
                          component="span" 
                          sx={{ 
                            color: 'primary.main',
                            fontWeight: 'bold',
                            minWidth: '80px'
                          }}
                        >
                          TSC_ref_#:
                        </Typography>
                        <Typography>{option.keyValues['TSC_ref_#']}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <Typography 
                          component="span" 
                          sx={{ 
                            color: 'primary.main',
                            fontWeight: 'bold',
                            minWidth: '80px'
                          }}
                        >
                          criteria:
                        </Typography>
                        <Typography style={{ flex: 1 }}>
                          {option.keyValues['criteria']}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <Typography 
                          component="span" 
                          sx={{ 
                            color: 'primary.main',
                            fontWeight: 'bold',
                            minWidth: '80px'
                          }}
                        >
                          domains:
                        </Typography>
                        <Box>
                          {option.domains.map(domain => (
                            <Chip
                              key={domain}
                              label={domain}
                              size="small"
                              sx={{ mr: 0.5 }}
                            />
                          ))}
                        </Box>
                      </Box>
                    </Stack>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Existing Criteria"
                    variant="outlined"
                    placeholder="Search by TSC reference or criteria text..."
                    error={!!criteriaError}
                    helperText={criteriaError}
                  />
                )}
                onChange={(event, newValue) => {
                  if (newValue && !isCustomCriteria) {
                    const isDuplicate = selectedCategory.criteria?.some(
                      existingCriteria => existingCriteria.keyValues['TSC_ref_#'] === newValue.keyValues['TSC_ref_#']
                    );

                    if (isDuplicate) {
                      setCriteriaError('This criteria is already selected for this category');
                      return;
                    }

                    setFilteredControls(prevControls => 
                      prevControls.map(control => ({
                        ...control,
                        categories: control.categories.map(cat => {
                          if (cat.id === selectedCategory.id) {
                            return {
                              ...cat,
                              criteria: [...cat.criteria, newValue]
                            };
                          }
                          return cat;
                        })
                      }))
                    );
                    setAddCriteriaDialogOpen(false);
                    setCriteriaError('');
                  }
                }}
                isOptionEqualToValue={(option, value) => 
                  option.keyValues['TSC_ref_#'] === value.keyValues['TSC_ref_#']
                }
              />
            ) : (
              <Stack spacing={2}>
                <Typography variant="subtitle2">Key-Value Pairs</Typography>
                {Object.entries(newCustomCriteria.keyValues).map(([key, value], index) => (
                  <Box key={index} sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                      label="Key"
                      value={key}
                      onChange={(e) => handleCustomKeyValueChange(key, e.target.value, value)}
                      size="small"
                    />
                    <TextField
                      label="Value"
                      value={value}
                      onChange={(e) => handleCustomKeyValueChange(key, key, e.target.value)}
                      fullWidth
                      size="small"
                    />
                    <IconButton 
                      color="error" 
                      size="small"
                      onClick={() => {
                        const newKeyValues = { ...newCustomCriteria.keyValues };
                        delete newKeyValues[key];
                        setNewCustomCriteria(prev => ({
                          ...prev,
                          keyValues: newKeyValues
                        }));
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleAddCustomKeyValue}
                  variant="outlined"
                  size="small"
                >
                  Add Key-Value Pair
                </Button>

                <Divider />

                <Typography variant="subtitle2">Domains</Typography>
                <Stack direction="row" spacing={1}>
                  {newCustomCriteria.domains.map((domain, idx) => (
                    <Chip
                      key={idx}
                      label={domain}
                      onDelete={() => handleRemoveCustomDomain(domain)}
                    />
                  ))}
                  <Autocomplete
                    options={['Security', 'Privacy', 'Confidentiality', 'Availability', 'Processing Integrity']}
                    renderInput={(params) => (
                      <TextField {...params} label="Add Domain" size="small" />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        handleAddCustomDomain(newValue);
                      }
                    }}
                    value={null}
                    inputValue=""
                    size="small"
                    style={{ width: 200 }}
                    clearOnBlur
                    selectOnFocus
                    key={newCustomCriteria.domains.length}
                  />
                </Stack>
              </Stack>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddCriteriaDialogOpen(false)}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleSaveNewCriteria}
            disabled={isCustomCriteria ? 
              Object.keys(newCustomCriteria.keyValues).length === 0 : 
              !selectedItem
            }
          >
            Add Criteria
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={addCategoryDialogOpen} 
        onClose={() => setAddCategoryDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add Category to Control {selectedControl?.id}</DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <div>
              <Typography variant="subtitle2" gutterBottom>
                Select existing category or create custom
              </Typography>
              <Stack direction="row" spacing={2}>
                <Button
                  variant={!isCustomCriteria ? "contained" : "outlined"}
                  onClick={() => setIsCustomCriteria(false)}
                >
                  Use Existing
                </Button>
                <Button
                  variant={isCustomCriteria ? "contained" : "outlined"}
                  onClick={() => setIsCustomCriteria(true)}
                >
                  Create Custom
                </Button>
              </Stack>
            </div>

            {!isCustomCriteria ? (
              <Autocomplete
                options={existingCategories}
                getOptionLabel={(option) => `${option.id} - ${option.name}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Existing Categories"
                    variant="outlined"
                  />
                )}
                onChange={(event, newValue) => {
                  if (newValue && !isCustomCriteria) {
                    // Immediately save and close when selecting existing category
                    setFilteredControls(prevControls =>
                      prevControls.map(control => {
                        if (control.id === selectedControl.id) {
                          return {
                            ...control,
                            categories: [...control.categories, { 
                              ...newValue,
                              criteria: []
                            }]
                          };
                        }
                        return control;
                      })
                    );
                    setAddCategoryDialogOpen(false);
                  }
                }}
                isOptionEqualToValue={(option, value) => 
                  option.id === value.id
                }
              />
            ) : (
              <>
                <TextField
                  label="Category ID"
                  value={newCustomCategory.id}
                  onChange={(e) => setNewCustomCategory(prev => ({
                    ...prev,
                    id: e.target.value
                  }))}
                  fullWidth
                  required
                />
                <TextField
                  label="Category Name"
                  value={newCustomCategory.name}
                  onChange={(e) => setNewCustomCategory(prev => ({
                    ...prev,
                    name: e.target.value
                  }))}
                  fullWidth
                  required
                />
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddCategoryDialogOpen(false)}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleSaveNewCategory}
            disabled={!newCustomCategory.id || !newCustomCategory.name}
          >
            Add Category
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={mappingMode} 
        onClose={() => setMappingMode(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Map Controls & Criteria</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Foundational controls/criteria on left */}
            <Grid item xs={6}>
              <Typography variant="h6">Universal Controls</Typography>
              {/* List foundational items */}
            </Grid>
            
            {/* Framework-specific controls/criteria on right */}
            <Grid item xs={6}>
              <Typography variant="h6">Framework Controls</Typography>
              {/* List framework items */}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog 
        open={frameworkManagementOpen} 
        onClose={() => setFrameworkManagementOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Manage Frameworks</DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            {/* Add New Framework Section */}
            <Box sx={{ 
              p: 2, 
              bgcolor: 'background.paper', 
              borderRadius: 1,
              border: '1px solid',
              borderColor: 'divider'
            }}>
              <Typography variant="h6" gutterBottom>Add New Framework</Typography>
              <Stack spacing={2}>
                <TextField
                  label="Framework Slug (e.g., soc2, iso27001)"
                  value={newFramework.slug}
                  onChange={(e) => setNewFramework(prev => ({
                    ...prev,
                    slug: e.target.value.toLowerCase().replace(/[^a-z0-9]/g, '')
                  }))}
                  helperText="Unique identifier, lowercase letters and numbers only"
                  fullWidth
                />
                <TextField
                  label="Framework Name"
                  value={newFramework.name}
                  onChange={(e) => setNewFramework(prev => ({
                    ...prev,
                    name: e.target.value
                  }))}
                  fullWidth
                />
                <TextField
                  label="Description"
                  value={newFramework.description}
                  onChange={(e) => setNewFramework(prev => ({
                    ...prev,
                    description: e.target.value
                  }))}
                  multiline
                  rows={2}
                  fullWidth
                />
                <TextField
                  label="Issuer"
                  value={newFramework.issuer}
                  onChange={(e) => setNewFramework(prev => ({
                    ...prev,
                    issuer: e.target.value
                  }))}
                  fullWidth
                />
                <TextField
                  label="Version"
                  value={newFramework.version}
                  onChange={(e) => setNewFramework(prev => ({
                    ...prev,
                    version: e.target.value
                  }))}
                  fullWidth
                />
                <Button 
                  variant="contained" 
                  onClick={handleAddNewFramework}
                  disabled={!newFramework.slug || !newFramework.name}
                >
                  Add Framework
                </Button>
              </Stack>
            </Box>

            <Divider />

            {/* Existing Frameworks Section */}
            <Typography variant="h6">Existing Frameworks</Typography>
            <List>
              {Object.entries(frameworksDataState).map(([slug, framework]) => (
                <ListItem 
                  key={slug}
                  secondaryAction={
                    <Stack direction="row" spacing={1}>
                      <Button
                        size="small"
                        startIcon={<UploadIcon />}
                        onClick={() => setImportDialogOpen(true)}
                      >
                        Import Data
                      </Button>
                      <IconButton 
                        edge="end" 
                        onClick={() => handleEditFramework(slug)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton 
                        edge="end"
                        color="error"
                        onClick={() => handleDeleteFramework(slug)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  }
                >
                  <ListItemText
                    primary={framework.name}
                    secondary={
                      <React.Fragment>
                        <Typography component="span" variant="body2" color="text.primary">
                          {framework.description}
                        </Typography>
                        <br />
                        Version: {framework.version} | Issuer: {framework.issuer}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFrameworkManagementOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <ImportDialog />
      <MappingReviewDialog />
    </div>
  );
};

export default AdminDataWarehouse;
