import React, { useState, useEffect, useCallback } from 'react';
import { AiFillEdit, AiFillDelete } from 'react-icons/ai';
import { Menu, MenuItem, IconButton } from '@mui/material';
import ControlDetailsSidebar from './ControlDetailsSidebar'; // Import sidebar component
import Loader from './Loader'; // Import loader for loading state
import './ControlsTable.css'; // Reuse the styles
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

const ControlLibrary = () => {
  const [controls, setControls] = useState([]);
  const [filteredControls, setFilteredControls] = useState([]);
  const [selectedControl, setSelectedControl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const fetchControls = useCallback(async () => {
    setLoading(true);
    try {
      const controlsSnapshot = await getDocs(collection(db, 'controls'));
      const controlsData = controlsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setControls(controlsData);
      setFilteredControls(controlsData);
    } catch (error) {
      console.error('Error fetching controls:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchControls();
  }, [fetchControls]);

  const handleActionClick = (event, control) => {
    setAnchorEl(event.currentTarget);
    setSelectedControl(control);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedControl(null);
  };

  const handleRowClick = (control) => {
    setSelectedControl(control);
    setSidebarOpen(true);
  };

  const handleSearch = useCallback((e) => {
    setSearchTerm(e.target.value);
    const lowerCaseSearchTerm = e.target.value.toLowerCase();
    const filtered = controls.filter(control =>
      control.friendly_control_name.toLowerCase().includes(lowerCaseSearchTerm) ||
      control.control_description.toLowerCase().includes(lowerCaseSearchTerm) ||
      (control.frameworks && control.frameworks.toLowerCase().includes(lowerCaseSearchTerm))
    );
    setFilteredControls(filtered);
  }, [controls]);

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
    setSelectedControl(null);
  };

  return (
    <div className="controls-table-container">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search controls..."
              value={searchTerm}
              onChange={handleSearch}
              className="search-input"
            />
          </div>

          <table className="controls-table">
            <thead>
              <tr>
                <th>Control Friendly Name</th>
                <th>Control Description</th>
                <th>Linked Frameworks</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredControls.map((control, index) => (
                <tr key={index} onClick={() => handleRowClick(control)}>
                  <td>{control.friendly_control_name}</td>
                  <td>{control.control_description}</td>
                  <td>{control.frameworks ? control.frameworks.join(', ') : 'N/A'}</td>
                  <td>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(event) => handleActionClick(event, control)}
                    >
                      ⋮
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => { /* Handle edit action */ }}>Edit</MenuItem>
                      <MenuItem onClick={() => { /* Handle delete action */ }}>Delete</MenuItem>
                    </Menu>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <ControlDetailsSidebar
            isOpen={sidebarOpen}
            onClose={handleCloseSidebar}
            controlDetails={selectedControl}
            onControlUpdate={(updatedControl) => {
              setControls(prevControls =>
                prevControls.map(control =>
                  control.friendly_control_name === updatedControl.friendly_control_name ? updatedControl : control
                )
              );
              setFilteredControls(prevControls =>
                prevControls.map(control =>
                  control.friendly_control_name === updatedControl.friendly_control_name ? updatedControl : control
                )
              );
              setSidebarOpen(false);
            }}
          />
        </>
      )}
    </div>
  );
};

export default ControlLibrary; 
